class LRBCodeInput {
    constructor() {
        this.intRegex = /^\d+$/;
    }

    setup() {

        if($('form.access-code').length > 0){
            console.log('%cLRBCodeInput Setup', `background-color: ${global.infoBG};`);

            try {
                let scope = this;
    
                $('form.access-code').each(function(){
                    scope.setupInputsFocussing($(this));
                    scope.setupInputs($(this));
                });
    
                this.processURLHashCode();
            } catch (error) {
                global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Code Input',error);
            }
        }
    }

    setupInputs($form){
        var $inputs = $form.find('input.access-code-input');
        let scope = this;

        if($inputs){
            // Prevents user from manually entering non-digits.
            $inputs.on("input.fromManual", function(){

            });

            // Prevents pasting non-digits and if value is 6 characters long will parse each character into inputs
            $inputs.on("paste", function() {
                var $this = $(this);
                var originalValue = $this.val();
                $this.val("");

                $this.one("input.fromPaste", function(){
                    var $currentInputBox = $(this);
                    if($currentInputBox){
                        var pastedValue = $currentInputBox.val();

                        if(pastedValue.length == 6 && scope.intRegex.test(pastedValue)){
                            scope.pasteValues($form, pastedValue);
                        }else{
                            $this.val(originalValue);
                        }
                    }

                    $inputs.attr("maxlength", 1);
                });
                $inputs.attr("maxlength", 6);
            });
        }else{
            console.error('Cannot setup Code Input - missing inputs');
        }
    }

    setupInputsFocussing($form){
        var $inputs = $form.find('input.access-code-input');

        if($inputs && $inputs.length){
            for (let i = 0; i < $inputs.length; i++) {
                $inputs[i].addEventListener('keydown', function(e) {
                    if(e.key === "Backspace"){

                        $inputs[i].value = '';
                        if (i !== 0)$inputs[i - 1].focus();

                    }else{

                        if(i === $inputs.length - 1 && $inputs[i].value !== ''){
                            return true;
                        }else if(e.keyCode > 47 && e.keyCode < 58){
                            $inputs[i].value = e.key;
                            if (i !== $inputs.length - 1)$inputs[i + 1].focus();
                            e.preventDefault();
                        }else if(e.keyCode > 64 && e.keyCode < 91){
                            /*$inputs[i].value = String.fromCharCode(e.keyCode);
                            if(i !== $inputs.length - 1)$inputs[i + 1].focus();*/
                            //e.preventDefault();
                        }

                    }
                });
            }
        }else{
            console.error('Cannot setup Code Inputs Focussing - missing inputs');
        }
    }

    pasteValues($form, code){
        var values = code.split("");
        $(values).each(function(index){
            var $inputBox = $form.find('.access-code-input[name="access_code['+ index +']"]');
            $inputBox.val(values[index]);
            $inputBox.focus();
        });
    };

    processURLHashCode(){
        var code = global.utils.getURLHash().replace('#','');
        var $form = $('form.access-code').eq(0);
        // If code from hash is 6 chars long and int only and access code form exists
        if(code.length == 6 && this.intRegex.test(code)){
            // paste into first access code form found
            this.pasteValues($form, code);
        }
    }

    destroy(){
    }

}

module.exports = { LRBCodeInput };
