require('velocity-animate');
var Intercooler = require('intercooler');

const BASE_SEARCH_URL = "/search-results";
class LRBSearch {
    constructor() {
        this.disableSearchNav = false;
        this.disableAdvancedSearch = false;
        this.ajaxSearch = true;
    }

    setup() {

        try {
            if($('body').hasClass('section-search-results')){
    
                if($('#lrb-search-form').length > 0){
                    console.log('%cLRBSearch Setup', `background-color: ${global.infoBG};`);
    
                    //Remove flag to include blog posts when not in the blog_post
                    if(window.location.href.indexOf('/blog/') == -1){
                        $('.search-blogContentId').remove();
                    }
    
                    if(this.disableSearchNav){
                        $('.advanced-search-navlink, .lrb-search--input input').addClass('disabled');
                        $('.lrb-search--input input').attr('disabled', true);
    
                        console.warn("Search Nav Disabled");
                    }
                    if(this.disableAdvancedSearch){
                        $('.advanced-search-navlink, .search-results--form .advanced-link').addClass('disabled');
    
                        console.warn("Advanced Search Disabled");
                    }
    
                    if($('.section-search-results').length > 0){
                        console.log('%cLRBSearch Setup', `background-color: ${global.infoBG};`);
    
                        this.setupLinks();
    
                        // Show Advanced search form if any of the fields have values, or the URL hash is present
                        var showAdvancedForm = false;
                        if(
                            $('#searchResults-and').val() != "" ||
                            $('#searchResults-or').val() != "" ||
                            $('#searchResults-not').val() != "" ||
                            $('#searchResults-phrase').val() != "" ||
                            $('#date-from').val() != "" ||
                            $('#date-to').val() != ""
                        ){
                            showAdvancedForm = true;
                        }
                        if(location.hash && location.hash == "#advanced"){
                            showAdvancedForm = true;
                        }
                        if(showAdvancedForm){
                            $('.search-results--advanced-form').addClass('shown');
                        }
                        this.updateAdvancedPaneDisplay(true);
    
                        if($('.search-results--list .results-list--item').length > 0){
                            global.utils.scrollTo($('.search-results--count'), 100);
                        }
    
                    }
    
                    if($('.search-results--list').length){
                        setInterval(this.cleanupAJAXNestedSearch, 500);
                    }
                }
            }
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Search',error);
        }

    }

    setupLinks(){

        let scope = this;

        $('.advanced-link').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            scope.toggleAdvancedSearch();

            return false;
        });

        $('.advanced-search-close').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            scope.hideAdvancedSearch();

            return false;
        });

        $('#lrb-search-form').on('submit', function(e){
            e.preventDefault();
            e.stopPropagation();

            scope.triggerSearch(BASE_SEARCH_URL, $("#lrb-search-form"));
            global.utils.scrollTo($('.search-results--count'), 100);

            return false;
        });

        $('#lrb-search-filter-form').on('submit', function(e){
            e.preventDefault();
            e.stopPropagation();

            scope.triggerSearch(BASE_SEARCH_URL, $("#lrb-search-filter-form"));

            return false;
        });

        this.setupAutoSubmitLinks();

    }

    setupAutoSubmitLinks(){

        let scope = this;

        // Auto submits the form when clicked
        $('.js-autoSubmit').each(function(){
            if($(this).data('auto-submit-setup') == 1){
                return true;
            }else{
                $(this).data('auto-submit-setup', 1);
            }

            $(this).on('change', function(e){
                scope.triggerSearch(BASE_SEARCH_URL, $("#lrb-search-form"));
            });
        });

        // Auto submits the filter form when clicked
        $('.js-autoSubmitFilter').each(function(){
            if($(this).data('auto-submit-setup') == 1){
                return true;
            }else{
                $(this).data('auto-submit-setup', 1);
            }

            $(this).on('change', function(e){
                scope.triggerSearch(BASE_SEARCH_URL, $("#lrb-search-filter-form"));
            });
        });

        // Auto submits the URL from value when clicked
        /*$('.js-autoLoadValue').each(function(){
            if($(this).data('auto-loadvalue-setup') == 1){
                return false;
            }else{
                $(this).data('auto-loadvalue-setup', 1);
            }

            $(this).on('change', function(e){
                $("#lrb-search-filter-form").submit();
            });
        });*/

        // Auto submits the URL from value when clicked
        $('.js-autoLoadURL').each(function(){
            if($(this).data('auto-loadurl-setup') == 1){
                return true;
            }else{
                $(this).data('auto-loadurl-setup', 1);
            }

            $(this).on('click', function(e){
                e.preventDefault();
                e.stopPropagation();

                scope.triggerSearch($(this).attr('href'));

                return false;
            });
        });

        scope.filterTagCleanup();

    }

    //TODO remove this when Joe has completed changes to articletypes in filters
    filterTagCleanup(){
        $('.filter-tag').each(function(){
            $(this).html($(this).html().replace(/Content Type: 76/g, 'Letters'));
            $(this).html($(this).html().replace(/Content Type: 75/g, 'Blog Posts'));
        });
    }

    toggleAdvancedSearch(instant = false){
        $('.search-results--advanced-form').toggleClass('shown');
        this.updateAdvancedPaneDisplay(instant);
    }
    showAdvancedSearch(instant = false){
        $('.search-results--advanced-form').addClass('shown');
        this.updateAdvancedPaneDisplay(instant);
    }
    hideAdvancedSearch(instant = false){
        $('.search-results--advanced-form').removeClass('shown');
        this.updateAdvancedPaneDisplay(instant);
    }

    updateAdvancedPaneDisplay(instant = false){
        var t1 = 300, t2 = 400;

        if($('.search-results--advanced-form').hasClass('shown')){
            // Show Advanced Search
            if(instant){
                $('.search-results--form').hide();
                $('.search-results--advanced-form').show();
            }else{
                $('.search-results--form').hide();
                //$('.search-results--form').velocity('stop').velocity("slideUp", {duration: t1}).velocity("fadeOut", {duration: t1+1, queue: false});
                $('.search-results--advanced-form').velocity('stop').velocity("slideDown", {duration: t2}).velocity("fadeIn", {duration: t2*2, queue: false});
                $('.search-results--advanced-form .lrb-datepicker').removeClass('open');
            }

            setTimeout(function(){
                $('#searchResults-searchTerm').val("");
                $('#searchResults-and').eq(0).focus();
            },100);
        }else{
            // Hide Advanced Search
            location.hash = "";
            if(instant){
                $('.search-results--form').show();
                $('.search-results--advanced-form').hide();
            }else{
                $('.search-results--form').show();
                //$('.search-results--form').velocity('stop').velocity("slideDown", {duration: t2}).velocity("fadeIn", {duration: t2*2, queue: false});
                $('.search-results--advanced-form').velocity('stop').velocity("slideUp", {duration: t1}).velocity("fadeOut", {duration: t1+1, queue: false});
            }
            $('.search-results--advanced-form input').each(function(){
                $(this).val(null).trigger('change');
            });
            $('.search-results--advanced-form .lrb-datepicker .result span').html(global.LRBDatePicker.datePlaceholder);

            setTimeout(function(){
                $('#searchResults-searchTerm').eq(0).focus();
            },100);
        }
    }

    triggerSearch(url = BASE_SEARCH_URL, formToSend = ""){
        global.LRBTracking.trackSearch();

        let scope = this;

        // Work out search URL, if no formData is passed then just load URL directly (used to remove filters)
        var searchURL = url;
        var formData = "";
        if(formToSend != ""){
            formData = scope.sanitiseFormData(formToSend.serialize());
            searchURL += "?"+formData;
            searchURL = searchURL.replace("&sort","&oldsort");
            searchURL = searchURL.replace("?sort","?oldsort");
            formData = formData.replace("&sort","&oldsort");
            formData = formData.replace("?sort","?oldsort");

            var newSort = '&sort=' + $('.search-sort select').val();
            formData += newSort;
            searchURL += newSort;
        }

        console.log("Search - Form Data: ", formData);
        console.log("Search - Search URL: ", searchURL);

        if(!this.ajaxSearch){
            window.location.href = searchURL;
            return false;
        }

        scope.showSearchLoading();

        $.get({
            url: url,
            cache: false
        }, formData)
        .done(function(data){

            var statusCol = global.errorBG;
            if(data){
                statusCol = global.successBG;
            };


            global.utils.updateURL(searchURL);
            console.log("Search Loading:: "+searchURL);

            // slight render delay to allow for DOM to catchup on ajax change
            var searchRenderDelay = 50;

            if(data){

                $(".search-results-holder").hide();
                $(".js-searchDynamicLoadHolder").html(data);
                $(".search-results-holder").hide();
                $(".search-results-holder").velocity('stop').velocity("fadeIn", {duration: 800, delay: searchRenderDelay});
                console.log("%cSearch Success", `background-color: ${statusCol};`);

                scope.updateSearchCount();

            }else{
                scope.showSearchError(data);
            }

            scope.cleanupAJAXNestedSearch();
            scope.filterTagCleanup();
            scope.hideSearchLoading();
            scope.updateSearchCount();


            setTimeout(function(){
                global.setupPage.setup(true);
                global.LRBTypography.cleanupEllipses();
                global.LRBTypography.cleanUpTypography();

                scope.setupAutoSubmitLinks();
                Intercooler.processNodes($(".js-searchDynamicLoadHolder")[0]);

                scope.updateSearchCount();
            }, searchRenderDelay + 10);

        })
        .fail(function(e){
            scope.showSearchError(e);
        });

    }

    sanitiseFormData(data){
        // Replace quotes with URLencoded versions, this is required for iOS Safari as the text field auto formats quotes to curly quotes, and then includes those unformatted in the URL params
        return data.replace(/[\u2018\u2019]/g, "%27").replace(/[\u201C\u201D]/g, '%22').replace('%E2%80%9C', '%22').replace('%E2%80%9D', '%22');
    }

    cleanupAJAXNestedSearch(){
        $(".search-results-holder .search-results--count, .search-results-holder .search-results--sort, .search-results-holder .search-results--aside, .search-results--list-wrapper .search-results--list-wrapper .active-filters").remove();
    }

    showSearchError(error = ""){

        let scope = this;

        console.error("Error on Search:", error);
        global.LRBErrorTracking.error(error);

        var errorMsg = '<div class="noresults-spacer"><p>There was an error with your search, please try again.</p></div>';
        $('.active-filters').html("");
        $('.search-results-holder, .noresults-spacer').html(errorMsg);
        $('.search-results--count').html("&nbsp;");
        scope.hideSearchLoading();

        $(".search-results-holder").velocity('stop').velocity("fadeIn", {duration: 800, delay: 50});
    }

    showSearchLoading(){
        $('html').addClass('lrb-searching');
        $(".search-results-holder").velocity('stop').velocity("fadeOut", {duration: 200});

        $('.lrb-accordion .lrb-accordion__content').velocity("stop").velocity("slideUp", { duration: 450, complete: function(){
        } });
        $('.lrb-accordion .show').removeClass('show');
    }

    hideSearchLoading(){
        $('html').removeClass('lrb-searching');
    }

    updateSearchCount(){
        $('.search-results--count').html($('.js-searchCount').html());
        $('.search-results--count .visibleResultsEnd').html($('.search-results--list .results-list--item').length);
    }

    destroy(){
    }

}

module.exports = { LRBSearch };
