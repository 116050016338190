const INST_LOGIN = "/librarian/login";
const INST_ADMIN_LOGIN = "/librarian/login?type=admin";

class LRBInstitutionForms {
    constructor() {
    }

    setup() {
        console.log('%cLRBInstitutionForms Setup', `background-color: ${global.infoBG};`);
        
        if($('body').hasClass('section-librarian')){
            this.setupInstitutionForm();
            this.setupInstitutionAdminForm();
            try {
                //scroll to the form on institution pages with notifications
                if($('#form-success-notification:visible').length == 0 || $('#form-error-notification:visible').length == 0){
                    setTimeout(function(){
                        global.utils.scrollTo($('#form-success-notification'), 120);
                    }, 1000);
                }
            } catch (error) {
                global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Institutional Forms',error);
            }
        }
    }

    //=================================================================
    // Create Institution form
    //=================================================================
    createInstitutionForm(){
        let scope = this;

        $(".modal-container .inner").html(global.loadingIcon);

        $(".modal-container .inner").load(INST_LOGIN,function(){
            global.resizing.modalResizeHandler();
            scope.setupInstitutionForm();
        });
        global.resizing.modalResizeHandler();
    }

    setupInstitutionForm(){
        global.LRBFormValidation.autoValidate();
        global.resizing.modalResizeHandler();
        $('#inst-login').on('submit', function(e) {
            global.LRBFormValidation.hideGeneralError();
        });
    }

    //=================================================================
    // Create Institution Admin form
    //=================================================================
    createInstitutionAdminForm(){
        let scope = this;

        $(".modal-container .inner").html(global.loadingIcon);

        $(".modal-container .inner").load(INST_ADMIN_LOGIN,function(){
            global.resizing.modalResizeHandler();
            scope.setupInstitutionAdminForm();
        });
        global.resizing.modalResizeHandler();
    }

    setupInstitutionAdminForm(){
        global.LRBFormValidation.autoValidate();
        global.resizing.modalResizeHandler();
        $('#inst-login').on('submit', function(e) {
            global.LRBFormValidation.hideGeneralError();
        });
    }

    destroy(){
    }

}

module.exports = { LRBInstitutionForms };
