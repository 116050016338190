class Scrolling {
    constructor() {
        global.prevScroll = 0;
        global.scrollDirection = 0;
    }

    setup() {
        console.log('Scrolling - Setup');

        this.scrollHandler();

        const sHandler = global.utils.throttled(100, this.scrollHandler);
        window.addEventListener("scroll", sHandler);
    }

    scrollHandler(e) {
        var scrollPos = window.pageYOffset || document.documentElement.scrollTop;
        if(scrollPos > global.prevScroll){
            global.scrollDirection = 1;
        }else{
            global.scrollDirection = -1;
        }
        global.prevScroll = scrollPos;
        if(scrollPos > 0 && global.scrollDirection > 0){
            $('html').addClass('scrollDown');
            $('html').removeClass('scrollUp');
        }else if(scrollPos > 0){
            $('html').removeClass('scrollDown');
            $('html').addClass('scrollUp');
        }else{
            $('html').removeClass('scrollDown');
            $('html').removeClass('scrollUp');
        }

        global.LRBNavigation.closeSearchNav();
    }

}

module.exports = { Scrolling };
