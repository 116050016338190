require('velocity-animate');

class LRBSubscriptions {
    constructor() {
        window._orders = [];
        window.lrbLoadSubOrder = this.loadOrder;

        window.setupEditAddressPage = this.setupEditAddressPage;
        window.slideShow = this.slideShow;
        window.slideHide = this.slideHide;
    }

    setup(){
        console.log('%cLRBSubscriptions Setup', `background-color: ${global.infoBG};`);

        try {
            let scope = this;
    
            //==================================
            // Load order/subscription on change
            //==================================
            if($('.subcriptions-content ').length > 0){
                $(".subcriptions-content #form_order_number").change(function () {
                    scope.loadOrder($(this).val());
                });
            }
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Subscriptions',error);
        }
    }

    //==================================
    // Load initial order/subscription
    //==================================

    loadOrder(order_number, orders_obj = null, hasExpiredSub = false, hasValidSub = false){
        if(orders_obj){
            window._orders = orders_obj;
        }
        var subscription = window._orders[order_number];
        if(!subscription){
            location.href="/account/subscriptions";
            return false;
        }

        var fullname = [];
        var address = [];

        //==================================
        // Format name
        //==================================

        if (subscription.title != "") {
            fullname.push(subscription.title);
        }
        if (subscription.first_name != "") {
            fullname.push(subscription.first_name);
        }
        if (subscription.last_name != "") {
            fullname.push(subscription.last_name);
        }

        //==================================
        // Format address
        //==================================

        if (fullname.length) {
            address.push(fullname.join(" "));
        }

        if (subscription.job_title != "") {
            address.push(subscription.job_title);
        }
        if (subscription.company1 != "") {
            address.push(subscription.company1);
        }
        if (subscription.company2 != "") {
            address.push(subscription.company2);
        }
        if (subscription.company3 != "") {
            address.push(subscription.company3);
        }

        if (subscription.po_box != "") {
            address.push(subscription.po_box);
        }
        if (subscription.po_box_city != "") {
            address.push(subscription.po_box_city);
        }
        if (subscription.po_box_zipcode != "") {
            address.push(subscription.po_box_zipcode);
        }

        if (subscription.address_1 != "") {
            address.push(subscription.address_1);
        }
        if (subscription.address_2 != "") {
            address.push(subscription.address_2);
        }
        if (subscription.address_3 != "") {
            address.push(subscription.address_3);
        }

        /*
        if (subscription.street_suffix != "") {
            address.push(subscription.street_suffix);
        }
        if (subscription.street_name != "") {
            address.push(subscription.street_name);
        }
        if (subscription.street_number != "") {
            address.push(subscription.street_number);
        }
        if (subscription.street_number_suffix != "") {
            address.push(subscription.street_number_suffix);
        }
         */

        if (subscription.street != "") {
            address.push(subscription.street);
        }

        if (subscription.town != "") {
            address.push(subscription.town);
        }
        if (subscription.county != "") {
            address.push(subscription.county);
        }
        if (subscription.postcode != "") {
            address.push(subscription.postcode);
        }
        if (subscription.country != "") {
            address.push(subscription.country);
        }

        var delivery_address = address.join("<br>");
        delivery_address += '<a class="editIconLink" href="/account/subscription/'+subscription.house+'/'+order_number+'/delivery-address/">Edit</a>'

        //==================================
        // Create links
        //==================================

        var credit_card_payment = '<a class="lrb-sidenav--link lrb-arrowlink lrb-arrowlink-dark" href="http://www.lrb.me/update" target="_blank"><span>Update credit card details</span></a>';
        var suspend_subscription = '<a class="lrb-sidenav--link lrb-arrowlink lrb-arrowlink-dark" href="/account/subscription/'+subscription.house+'/'+order_number+'/suspend/"><span>Suspend your subscription</span></a>';
        var claim_missing_issue = '<a class="lrb-sidenav--link lrb-arrowlink lrb-arrowlink-dark"href="/account/subscription/'+subscription.house+'/'+order_number+'/claim-a-missing-issue/"><span>Claim a missing issue</span></a>';

        //==================================
        // Update UI
        //==================================

        var last_issue = "";
        var email = subscription.email;
        if(email == ""){
            email = "<em>No email address provided</em>";
        }

        $(".subcriptions-content #last_issue_title").show();

        if(subscription.payment_method=="CCC"){
            last_issue = "Your subscription will auto-renew each year";
            $(".subcriptions-content #last_issue_title").hide();

        }else{

            // If last_issue_expiry_string then use this for displaying last issue
            // Otherwise construct from the last_issue_text and last_issue_date as previously
            if(subscription.last_issue_expiry_string != ""){

                last_issue += subscription.last_issue_expiry_string;

            }else{
                last_issue += subscription.last_issue_text;
                if(subscription.last_issue_date!=""){
                    last_issue += " ("+subscription.last_issue_date+")";
                }
            }
            
        }

        // If subscription contains warning text then display this
        if(subscription.warning_message && subscription.warning_message !== ""){
            global.LRBAlerts.showFormWarning(subscription.warning_message);
        }else{
            global.LRBAlerts.clearFormWarnings();
        }

        $(".subcriptions-content #last_issue .accountdetails-block--content").html(last_issue);
        $(".subcriptions-content #customer_number .accountdetails-block--content").text(subscription.order_number);
        $(".subcriptions-content #delivery_address .accountdetails-block--content").html(delivery_address);
        $(".subcriptions-content #email .accountdetails-block--content").html(global.utils.escapeHTML(email)+'<a class="editIconLink" href="/account/subscription/'+subscription.house+'/'+order_number+'/edit-email-address/">Edit</a>');

        $(".subcriptions-content #credit_card_payment").parent().hide();

        $(".subcriptions-content #suspend_subscription").parent().show();
        $(".subcriptions-content #claim_missing_issue").parent().show();
        $(".subcriptions-content .editIconLink").show();

        if(
            subscription.order_status=="I" ||
            subscription.order_status=="expired" ||
            subscription.order_status=="cancelled (refund)" ||
            subscription.order_status=="cancelled (duplicate)" ||
            subscription.order_status=="cancelled"
        ){
            $(".subcriptions-content #credit_card_payment").parent().hide();
            $(".subcriptions-content #suspend_subscription").parent().hide();
            $(".subcriptions-content #claim_missing_issue").parent().hide();
            $(".subcriptions-content #last_issue_title").show().text('Your final issue was');
            $(".subcriptions-content .editIconLink").hide();

            var contact_email = "";

            if(subscription.house=="DSB"){
                contact_email = "help@mylrb.co.uk";
            }else if(subscription.house=="PCD"){
                contact_email = "nasubs@mylrb.co.uk";
            }
        }

        $(".subcriptions-content #suspend_subscription").html(suspend_subscription);
        $(".subcriptions-content #claim_missing_issue").html(claim_missing_issue);

        global.utils.updateURL("/account/subscriptions/"+order_number);
    }

    slideShow($target){
        $target.velocity('stop').velocity("slideDown", {duration: 400}).velocity("fadeIn", {duration: 800, queue: false});
        $('html').addClass('formAnimating');
        setTimeout(function(){
            $('html').removeClass('formAnimating');
        },500);
    }
    slideHide($target){
        $target.velocity('stop').velocity("slideUp", {duration: 400}).velocity("fadeOut", {duration: 400, queue: false});
        $('html').addClass('formAnimating');
        setTimeout(function(){
            $('html').removeClass('formAnimating');
        },500);
    }

    setupEditAddressPage(company, po_box, country){

        console.log("Setup Edit Address Form: company - "+company+", po_box - "+po_box+", country - "+country);

        //init
        toggleCompanyFields(company);
        togglePoBoxFields(po_box);
        toggleAddressFields(country);

        //company switch
        $('input[name=company_switch]').change(function(){
            toggleCompanyFields($('input[name=company_switch]:checked').val());
        });

        function toggleCompanyFields(toggle){
            if(toggle!=""){
                window.slideShow($("#company_fields"));
            }else{
                window.slideHide($("#company_fields"));
            }
        }

        //PO box switch
        $('input[name=po_box_switch]').change(function(){
            togglePoBoxFields($('input[name=po_box_switch]:checked').val());
        });

        var poBoxFieldsOpen = false;
        function togglePoBoxFields(toggle){
            if(toggle!=""){
                poBoxFieldsOpen = true;
                window.slideShow($("#po_box_fields"));
                window.slideHide($("#uk_fields"));
                window.slideHide($("#row_fields"));
                window.slideHide($("#shared_fields"));
                window.slideHide($("#addressSearch"));
            }else{
                poBoxFieldsOpen = false;
                window.slideHide($("#po_box_fields"));
                toggleAddressFields($('#form_country').val());
                window.slideShow($("#shared_fields"));
                window.slideShow($("#addressSearch"));
            }
        }

        //address switch
        $('#form_country').change(function(){
            toggleAddressFields($(this).val());
        });

        function toggleAddressFields(toggle){
            if(poBoxFieldsOpen){
                window.slideHide($("#uk_fields"));
                window.slideHide($("#row_fields"));
                return;
            }
            if(toggle=="GB"){
                window.slideShow($("#uk_fields"));
                window.slideHide($("#row_fields"));
            }else{
                window.slideHide($("#uk_fields"));
                window.slideShow($("#row_fields"));
            }
        }

    }

    destroy(){
    }

}

module.exports = { LRBSubscriptions };
