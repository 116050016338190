var Intercooler = require('intercooler');

class LRBPagination {
    constructor() {
    }

    setup() {
        console.log('%cLRBPagination Setup', `background-color: ${global.infoBG};`);

        try {
            $('body').on('complete.ic', function(){
                setTimeout(function(){
                    window.pageReady.updateScrollReveal();
                    global.LRBTypography.cleanUpTypography();
                    global.LRBContributors.loadCovers();
                }, 50);
            });
    
            $('body').on('elementAdded.ic', function(){
                setTimeout(function(){
                    window.pageReady.updateScrollReveal();
                    global.LRBTypography.cleanUpTypography();
                }, 50);
    
                global.LRBSearch.cleanupAJAXNestedSearch();
                global.LRBSearch.updateSearchCount();
            });
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up IC Pagination',error);
        }

    }

    destroy(){
    }

}

module.exports = { LRBPagination };
