class LRBPaywall {
    constructor() {
        window.lrbPWMarkup1 = '<div id="lrb-pw-block" class="lrb-pw-block"><div class="inner"><h1>';
        window.lrbPWDefaultHeader1 = "You need to log in or subscribe to read more articles";
        window.lrbPWMarkup2 = '</h1><h2>';
        window.lrbPWDefaultHeader2 = "Subscribe and get unlimited access to our complete archive";
        window.lrbPWMarkup3 = '</h2><p> <a href="';
        window.lrbPWDefaultHeader3 = 'http://www.mylrb.co.uk/';
        window.lrbPWMarkup4 = '" class="btn btn-primary paywall-sub-link" title="Subscribe">Subscribe</a></p><p class="pwSigninLink lrbtp-login-link">Already registered? <a href="#Login" class="paywall-login-link js-login-link" title="Login">Log in</a></p></div></div>';

        window.lrbPW = this.showPaywall;
        window.lrbHA = this.hideArticle;
        window.lrbTA = this.truncateArticle;
        window.lrbCFP = this.checkFallbackPaywall;
    }

    setup() {
        console.log('%cLRBPaywall Setup', `background-color: ${global.infoBG};`);

        global.LRBPaywall.checkForcedPaywallDisplay();
    }

    checkForcedPaywallDisplay(){
        if(global.LRBSessionData.subXData.hasOwnProperty('f_pw')){
            if(global.LRBSessionData.subXData.f_pw){

                const pageGroup = global.LRBTracking.getPageGroup();
                let header = "";
                if(pageGroup == global.LRBTracking.contentGroups.PODCASTS){
                    header = "You need to log in or subscribe to listen to this podcast";
                }
                if(pageGroup == global.LRBTracking.contentGroups.VIDEO){
                    header = "You need to log in or subscribe to watch this video";
                }
                global.LRBPaywall.showPaywall(header);

            }
        }
    }

    checkFallbackPaywall(){
        if(global.LRBSessionData.subXData.s_pw){

            // If fallback paywall variable is set and true then show the paywall
            // This variable is set from the envvar ENABLE_FALLBACK_PAYWALL
            // This cannot check if the SubX paywall is active and functional
            // so there are potentially cases where a double paywall is displayed.
            // If this occurs then ENABLE_FALLBACK_PAYWALL should be set to false

            if (typeof window.lrbPWFB !== 'undefined') {
                if(window.lrbPWFB){
                    global.LRBPaywall.showPaywall();
                }
            }
            
        }
    }

    showPaywall(header1 = "", header2 = "", ctaUrl = "", ctaCopy = "", silent = false){
        if(window.isIntranet){
            return false;
        }
        if($('#lrb-articleCopy').length > 0 && global.LRBLoaded){
            if(!silent){
                console.warn('Showing Paywall:: '+ header1 + " :: "+ header2 + " :: "+ ctaUrl );
            }

            if(header1 != ""){
                window.lrbPWDefaultHeader1 = header1;
            }
            if(header2 != ""){
                window.lrbPWDefaultHeader2 = header2;
            }
            if(ctaUrl != ""){
                window.lrbPWDefaultHeader3 = ctaUrl;
            }
            if(ctaCopy != ""){
                window.lrbPWMarkup4 = window.lrbPWMarkup4.replace(/Subscribe/g,ctaCopy);
            }

            //This triggers hideArticle()
            window.lrbHA();

            // Add the paywall block
            if($('.lrb-pw-block').length == 0){

                if($('#lrb-articleCopy .article-content').length){

                    // Add paywall block to intended area
                    $('#lrb-articleCopy .article-content').prepend($(''+ window.lrbPWMarkup1 + window.lrbPWDefaultHeader1 + window.lrbPWMarkup2 + window.lrbPWDefaultHeader2 + window.lrbPWMarkup3 + window.lrbPWDefaultHeader3 + window.lrbPWMarkup4 ));
                    global.utils.scrollTo($('#lrb-articleCopy'), 100, 800);

                    global.LRBTracking.trackPaywall();

                }else{

                    // Markup has been edited by user to remove paywall, add paywall to top of body over all content
                    $('body').prepend($(''+ window.lrbPWMarkup1 + window.lrbPWDefaultHeader1 + window.lrbPWMarkup2 + window.lrbPWDefaultHeader2 + window.lrbPWMarkup3 + window.lrbPWDefaultHeader3 + window.lrbPWMarkup4 ));
                    $('html').addClass('lrb-fpw');
                    global.utils.scrollToTop();

                    global.LRBTracking.trackPaywallForceReload();

                }

                $('.lrb-pw-block').show();
                setTimeout(function(){
                    global.LRBModal.setupAccountLinks();
                },10);

                //Add conversion tracking to links in paywall box
                $('.paywall-sub-link').on('click', function(){
                    //window.lrbPLogMC("Paywall Subscribe Clicked", {"Paywall Action":"Subscribe"});
                });
                $('.paywall-login-link').on('click', function(){
                    //window.lrbPLogMC("Paywall Login Clicked", {"Paywall Action":"Login"});
                });

                global.LRBTracking.trackPaywall();
            }else{
                $('.lrb-pw-block').show().css('opacity', 1);
            }
        }
        // Recheck the paywall exists every 0.5 seconds
        setTimeout(function(){
            window.lrbPW(header1,header2,ctaUrl,ctaCopy,true);
        },500);
    }

    hideArticle(){
        // Fade out copy, add paywall class (only used for minor aesthetics)
        $('html').addClass('lrb-paywall');
        $('#lrb-articleCopy .article-content main').css('overflow-y','hidden').css('pointer-events','none !important');
        $('#lrb-articleCopy *, #lrb-articleCopy .article-content *').css('pointer-events','none !important').css('user-select','none');
        $('#lrb-articleCopy .article-content main').velocity({ height: 400 }, { duration: 800 });

        // Remove some article content after article is hidden
        setTimeout(function(){
            //This triggers truncateArticle()
            window.lrbTA();
        },800);
    }

    truncateArticle(){
        var $articleEls = $('#lrb-articleCopy .article-content .article-copy>*');
        var articleTruncated = false;
        if($articleEls.length > 3){
            $articleEls.each(function(i){
                if(i > 3){
                    $(this).remove();
                    articleTruncated = true;
                }
            });
        }
        if(articleTruncated && $('.lrb-truncated-article-copy').length == 0){
            $('#lrb-articleCopy .article-content .article-copy').append(
                $('<div class="lrb-truncated-article-copy"><p>Please login or <a href="/subscribe" title="Subscribe">subscribe</a> to read the full article.</p><p><a href="/login" title="Login" class="btn btn-primary">Login</a></p></div>')
            );
        }
    }
}

module.exports = { LRBPaywall };
