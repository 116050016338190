class LRBCourses {
    constructor() {
    }

    setup() {

        try {
            if($('.product-page').length > 0){
                console.log('%cLRBCourses Setup', `background-color: ${global.infoBG};`);
    
                let scope = this;
    
                $('.js-courseDownloads').on('click', function(e){
                    e.preventDefault();
                    e.stopPropagation();
    
                    scope.openCourseDownloadsModal();
    
                    return false;
                });
            }
    
            if($('.spc-inner').length > 0){
                this.setupResourceTabs();
            }
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Courses',error);
        }
    }

    handleHashChange(hash){
        console.log('handleHashChange',hash);
        let scope = this;

        $('.lrb-listnav--list-item a').each(function(){
            console.log($(this).text().toLowerCase().replace(' ','-'));
            if($(this).text().toLowerCase().replace(' ','-') == hash.replace('#','').toLowerCase()){
                var id = $(this).data('id');
                scope.openResourceTab(id);
            }
        });
    }

    setupResourceTabs(){
        let scope = this;

        $('.lrb-listnav--link').on('click', function(){
            var id = $(this).data('id');
            //scope.openResourceTab(id);
        });
    }

    openResourceTab(id){
        console.log('openResourceTab',id);
        if(id != null){
            $('.lrb-listnav--link').removeClass('selected');
            $(".lrb-listnav--link[data-id='"+id+"']").addClass('selected');

            $('.spc-inner').removeClass('shown');
            $('.spc-'+id).addClass('shown');

            global.pageReady.updateScrollReveal();
            global.documentReady.updateLazyLoadingImages();
        }
    }

    openCourseDownloadsModal(){
        let scope = this;
        var downloadsHTML = $('.course-downloads-content').html();

        global.LRBAlerts.clearModalNotifications();
        $(".modal-container .inner").html(downloadsHTML);
        global.resizing.modalResizeHandler();

        global.LRBModal.openMainModal();
    }

    destroy(){
    }

}

module.exports = { LRBCourses };
