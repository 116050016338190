require("jquery-ui/ui/widgets/datepicker");

class LRBDatePicker {
    constructor() {
        this.elCount = 0;
        this.datePlaceholder = "dd/mm/yyyy";
    }

    setup() {
        console.log('%cLRBDatePicker Setup', `background-color: ${global.infoBG};`);

        try {
            let scope = this;
    
            //Add datepicker markup
            $('.lrb-date').each(function(){
    
                if($(this).data('date-dd-setup') == 1){
                    return true;
                }else{
                    $(this).data('date-dd-setup', 1);
                }
    
                scope.elCount++;
    
                var elID = "lrbDate-"+scope.elCount;
                $(this).addClass(elID);
                $(this).data('datefield-for', 'date-picker-'+elID);
                $(this).after(scope.datepickerUIString(elID));
            });
    
            //Format datepicker UI
            $(".lrb-datepicker .calendar").each(function(){
    
                if($(this).data('date-dp-setup') == 1){
                    return true;
                }else{
                    $(this).data('date-dp-setup', 1);
                }
    
                var $parent = $(this).parents('.date-picker');
                var $dateInput = $("."+$(this).parents('.date-picker').data('datepicker-for'));
    
                var minDate = null;
                if($dateInput.data('min-date')){
                    minDate = ""+$dateInput.data('min-date');
                }
                var maxDate = null;
                if($dateInput.data('max-date')){
                    maxDate = ""+$dateInput.data('max-date');
                }
    
                console.log('setting up datepicker with: minDate['+minDate+'] maxDate['+maxDate+']');
    
                // Setup calendar datepicker
                var $datepicker = $(this).datepicker({
                    dateFormat: 'dd/mm/yy',
                    firstDay: 1,
                    minDate: minDate,
                    maxDate: maxDate,
                    currentText: "test"
                });
    
                // Disable fields that are secondary to another
                if($dateInput.data('date-offset-from') || $dateInput.data('date-shortoffset-from')){
                    $parent.addClass('disabled');
                }
    
                if($(this).val() != ""){
                    // Enable disabled fields if they are loaded with a value set
                    $parent.removeClass('disabled');
    
                    // Update calendar from set value
                    //$(this).datepicker("setDate", new Date($(this).val()));
                    var dateVal = $(this).val().split('/');
                    var setDate = new Date(dateVal[2], dateVal[1], dateVal[0]);
                    $datepicker.datepicker('setDate', setDate);
                    $datepicker.datepicker("refresh");
    
                }
    
    
            });
    
            $(".lrb-datepicker .input").blur(function(e){
                e.stopPropagation();
            });
    
            $('body').click(function(e){
                if($(e.target).hasClass('lrb-datepicker') || $(e.target).hasClass('ui-corner-all') || $(e.target).parents('.lrb-datepicker').length > 0){
                }else{
                    scope.closeAllDatepickers();
                }
            });
    
    
            $(".lrb-datepicker .input").each(function(){
                if($(this).data('date-dpi-setup') == 1){
                    return false;
                }else{
                    $(this).data('date-dpi-setup', 1);
                }
    
                $(this).on('click', function(e){
                    e.preventDefault();
                    e.stopPropagation();
    
                    var $parent = $(this).parents('.date-picker');
    
                    if($parent.hasClass('open')){
                        $(".lrb-datepicker").removeClass('open');
                    }else{
                        $(".lrb-datepicker").removeClass('open');
                        $parent.addClass('open');
                    }
    
                    return false;
                });
            });
    
    
            $(".lrb-datepicker .val-holder").on("change",function(){
                // Val Holders Input on generated date picker manually updated, update actual field and display
                var $selected = $(this).val(),
                $parent = $(this).parents('.date-picker');
                var date = new Date($selected.split('/').reverse().join('-'));
                $parent.find('.calendar-icon .date-svg-copy').html(date.getDate());
                var $dateInput = $("."+$(this).parents('.date-picker').data('datepicker-for'));
    
                scope.dateFieldChanged($parent, $dateInput);
    
                var reversedDate = $selected.split('/').reverse().join('-');
                $dateInput.val(reversedDate);
            });
            $(".lrb-datepicker .calendar").on("change",function(){
                // Calendar picker updated, update actual field and display
                var $selected = $(this).val(),
                $parent = $(this).parents('.date-picker');
                $parent.find('.result').children('span').html($selected);
                $parent.find('.result').children('input').val($selected);
                var date = new Date($selected.split('/').reverse().join('-'));
                $parent.find('.calendar-icon .date-svg-copy').html(date.getDate());
                var $dateInput = $("."+$(this).parents('.date-picker').data('datepicker-for'));
    
                scope.dateFieldChanged($parent, $dateInput);
    
                var reversedDate = $selected.split('/').reverse().join('-');
                $dateInput.val(reversedDate);
            });

        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Date Picker',error);
        }
    }

    dateFieldChanged($parent, $dateInput){
        if($parent && $dateInput){
            //When date changes check for fields that are flagged to be
            //offset from this and enable them, and update the vals
            $(".date-picker").each(function(){
                var $input = $("."+$(this).data('datepicker-for'));

                // Used for setting subscription suspensions
                if($input.data('date-offset-from')){
                    if("#"+$dateInput.attr('id') == ""+$input.data('date-offset-from')){
                        $(this).removeClass('disabled');
                        $(this).addClass('open');
                        $parent.removeClass('open');

                        //grab the date just set from first calendar...
                        var dateSet = $parent.find('.calendar').val();
                        var reversedDate = new Date(dateSet.split('/').reverse().join('-'));
                        var $calendar = $(this).find('.calendar');

                        //...and update the next calendar to two weeks from this date, and 3 months to the max from this date
                        $calendar.datepicker( "option", "minDate", new Date(+reversedDate + 1209600000) );
                        var maxDate = reversedDate;//""+$input.data('max-date');
                        //maxDate.setFullYear(maxDate.getFullYear() + 1);
                        maxDate.setMonth(maxDate.getMonth() + 3);
                        $calendar.datepicker( "option", "maxDate", maxDate );
                    }
                }

                // Used for search date range
                if($input.data('date-shortoffset-from')){
                    if("#"+$dateInput.attr('id') == ""+$input.data('date-shortoffset-from')){
                        $(this).removeClass('disabled');
                        $(this).addClass('open');
                        $parent.removeClass('open');

                        //grab the date just set from first calendar...
                        var dateSet = $parent.find('.calendar').val();
                        var reversedDate = new Date(dateSet.split('/').reverse().join('-'));
                        var $calendar = $(this).find('.calendar');

                        //...and update the next calendar to from this date, and set max to current Date
                        $calendar.datepicker( "option", "minDate", reversedDate );
                        //var maxDate = new Date();
                        //$calendar.datepicker( "option", "maxDate", maxDate );
                    }
                }

            });

            if($dateInput.data('date-offset-from')){
                $parent.removeClass('open');
            }
        }else{
            console.error('No params passed for dateFieldChanged');
        }
    }

    datepickerUIString(inputID){
        if(inputID){
            var valHolder = '<span>'+this.datePlaceholder+'</span>'
            if($('body').hasClass('section-search-results')){
                valHolder = '<input class="val-holder" type="text" placeholder="'+this.datePlaceholder+'"/>';
            }
            return '<div class="date-picker lrb-datepicker date-picker-'+inputID+'" data-datepicker-for="'+inputID+'"><div class="input"><div class="result">'+valHolder+'</div><button><span class="calendar-icon"><svg version="1.1" class="calendar-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 18" xml:space="preserve"><g class="calendar-group"><path d="M0,2v3.2V18h16V5.2V2H0z M2,16V6h12v10H2z"/><path d="M2,4V0h2v4H2z M12,4V0h2v4H12z"/></g><text class="date-svg-copy" x="50%" y="66%" dominant-baseline="middle" text-anchor="middle" style="font-family:"Ideal Sans";font-size:10px;font-weight:600;letter-spacing:-0.5px;">1</text></svg></span></button></div><div class="calendar"></div></div>';
        }else{
            console.warn('No inputID set for datepickerUIString');
        }
    }

    closeAllDatepickers(){
        $(".lrb-datepicker").removeClass('open');
    }

    destroy(){
    }

}

module.exports = { LRBDatePicker };
