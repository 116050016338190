require('jquery-touchswipe/jquery.touchSwipe');

class LRBToc {
    constructor() {
    }

    setup() {

		try {
			let scope = this;
	
			if($('.toc-cover-holder').length > 0){
				console.log('%cLRBToc Setup', `background-color: ${global.infoBG};`);
	
				$('.toc-cover-holder .inner').swipe( {
	
					swipeLeft:function(){
						//Next / Swiped left
						console.log("Cover Swipe: Next");
						if(!$('.toc-cover-holder .prev-next-buttons .next').hasClass('disabled')){
							console.log("Cover Swipe: Trigger Next");
							$('.toc-cover-holder .prev-next-buttons .next')[0].click();
							$('html').addClass('lrb-processing');
						}
					},
					swipeRight:function(){
						//Previous / Swiped right
						console.log("Cover Swipe: Previous");
						if(!$('.toc-cover-holder .prev-next-buttons .prev').hasClass('disabled')){
							console.log("Cover Swipe: Trigger Previous");
							$('.toc-cover-holder .prev-next-buttons .prev')[0].click();
							$('html').addClass('lrb-processing');
						}
					},
					 threshold:25
				 });
	
			}
		} catch (error) {
			global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up TOC',error);
		}

    }

    destroy(){
    }

}

module.exports = { LRBToc };
