class Shame {
    constructor() {
    }

    setup() {
        console.log('%cShame Setup', `background-color: ${global.warnBG};`);

        try{
            this.loadShameJS();
        }catch(error){
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Failed running Shame.js',error);
        }

    }

    loadShameJS(){

        /* ==========================================================================

        Shame JS Styles

        To avoid the growing codebase becoming littered throughout with quick-fixes, JS can be added to this file as required in the function loadShameJS(), including detailed notes on what the fix was for, with a note and name of who made this fix (in order to quickly follow up in future if required).

        e.g:

        ========================================================================== */

        /**
         * Add a button tracking click
         *
         * Needed to quickly add tracking to a button click
         *
         * author: Bill Murray (bill@whoyougannacall.ghost)
         */

        // $('.slimer').on('click',function(){
        //      global.GoogleGTag.trackEvent('Click','Slimer',"slime time");
        // });

        /* ==========================================================================

        Periodically these should be replaced and moved to the correct module, and refactored where necessary.

        Ideally, the loadShameJS() function is empty other than this comment.

        ========================================================================== */

    }

}

module.exports = { Shame };
