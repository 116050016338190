class LRBArchive {
    constructor() {
    }

    setup() {

        try {
            if($('.section-archive').length > 0){
                console.log('%cLRBArchive Setup', `background-color: ${global.infoBG};`);
    
                $('.newest-sort, .oldest-sort').on('click', function(e){
                    e.preventDefault();
                    e.stopPropagation();
    
                    if(!$(this).hasClass('selected')){
                        $('.newest-sort, .oldest-sort').removeClass('selected');
                        $(this).addClass('selected');
    
                        // Flip Order
                        var list = $('.archive-results--grid');
                        var listItems = list.children('.archive-results--grid-item');
                        list.append(listItems.get().reverse());
                        window.pageReady.updateScrollReveal();
    
                        global.utils.updateURL($(this).attr('href'));
                    }
    
                    return false;
                });
    
                if($('.archive-results--cover-image').length < 2){
                    $('.archive-nav--sort').css('opacity',0).css('pointer-events','none');
                }
    
                $('.archive-nav--prevnext a').on('click',function(e){
                    e.preventDefault();
                    e.stopPropagation();
    
                    var sortStr = '';
                    var sort = global.utils.getURLParam('sort');
                    if(sort && sort != ''){
                        sortStr = '?sort='+sort;
                    }
    
                    window.location = $(this).attr('href')+sortStr;
    
                    return false;
                });
            }
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Archive',error);
        }
    }

    destroy(){
    }

}

module.exports = { LRBArchive };
