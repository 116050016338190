require('LRB/magnify/jquery.magnify.js');
const Autolinker = require('autolinker/dist/Autolinker');
//require('LRB/magnify/jquery.magnify-mobile.js');
//require('LRB/flexslider/jquery.flexslider.js');

class LRBBlog {
    constructor() {
    }

    setup(){

        try {
            if($('html').hasClass('lrb-blog')){
                console.log('%cLRBBlog Setup', `background-color: ${global.infoBG};`);
    
                this.setupBlogHomepage();
                this.setupBlogSearch();
                this.setupArchive();
                this.setupCommentsReplies();
                this.setupOldBlogGallery();
                this.resizeBlogImages();
                this.setupMagnifyImages();
                this.autoLinkComments();
    
            }else{
                $('.magnify-mobile').remove();
            }
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Blog',error);
        }
    }

    //================================
    // Magnify / Zoom image functionality
    //================================
    setupMagnifyImages(){
        console.log('Setup Magnify images');
        if($('.lrb-zoom').length > 0){
            $('.lrb-zoom').magnify();
        }else{
            $('.magnify-mobile').remove();
        }
    }

    //================================
    // Blog homepage functionality
    //================================
    setupBlogHomepage(){
        console.log('Setup Blog Homepage');
        let scope = this;

        //================================
        // Homepage: paged posts
        //================================

        var page = 2;
        var previous_page;

        if (typeof n_posts !== 'undefined') {

            var page = 2;
            var previous_page;
            var pages = Math.ceil(n_posts / pp);

            if(page>pages){
                $(".lrb-blog .load-more-blog").hide();
            }
        }

        //================================
        // Homepage: handle back button
        //================================

        if(location.hash && typeof(load_posts) !== 'undefined'){
            previous_page = parseInt(location.hash.replace("#"," "));
            scope.loadPreviousContent(page,pages,previous_page);
        }

        if(location.hash == '#comments'){
            global.utils.scrollTo($('#comments'), 120);
        }

        //================================
        // Homepage: Load more button
        //================================

        $(".lrb-blog .load-more-blog").not(".disabled").click(function(e){
            e.preventDefault();
            scope.loadContent(page, pages, previous_page, $(this));
        });

    }

    loadPreviousContent(page, pages, previous_page){
        console.log('Blog - Load Previous Content');
        $(".lrb-blog  .home-overlay").show();
        if(page>previous_page){
            this.scrollToAnchor((page-1));
            $(".lrb-blog  .home-overlay").hide();
            return false;
        }
        else{
            this.loadContent(page, pages, previous_page, $(".lrb-blog  .load-more-blog"),true);
        }
    }

    //================================
    // Scroll to anchor
    //================================
    scrollToAnchor(aid){
        var aTag = $("[data-id='"+ aid +"']");
        $('html,body').animate({scrollTop: aTag.offset().top-90},'slow',function(){
            //location.href="#"+aid;
        });
    }

    //================================
    // Load posts via ajax
    //================================
    loadContent(page, pages, previous_page, ob, bb){
        console.log('Blog - Load Content');

        let scope = this;

        if (typeof bb !== 'undefined') {
            //do nothing
        }else{
            var bb = false
        }

        var url = "?page="+page;

        // UI
        ob.text("Loading...");
        ob.addClass("disabled");

        // Ajax request
        $.get(url, function( data ) {

            // Load new posts
            var time = 1000;

            if(bb){
                time = 0;
            }else{
                time = 1000;
            }

            $('<div data-id="'+page+'" style="display:none">'+data+'</div>').appendTo("#posts").slideDown(time,function(){

                // UI
                ob.text("Read More");
                ob.removeClass("disabled");

                // use anchor so that content loads when hitting the page from back button
                location.href="#"+page;

                // ready next page
                page++;

                //hide more button if end of results
                if(page>pages){
                    ob.hide();
                }

                //if coming from back button, recursive function until all content is loaded as required
                if(bb){
                    scope.loadPreviousContent(page,pages,previous_page);
                }

                $(".lrb-blog  .load-more-blog").unbind( "click" );
                $(".lrb-blog  .load-more-blog").not(".disabled").click(function(e){
                    e.preventDefault();
                    scope.loadContent(page, pages, previous_page, $(this));
                });

                $(".lrb-blog  .share-link").click(function(e){

                    e.preventDefault();
                    var url = $(this).attr("href");

                    window.open(url,"_blank","directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=400")

                });

            });

        });

    }

    //================================
    // Search
    //================================
    setupBlogSearch(){
        console.log('Setup Blog Search');
        $(".lrb-blog #blog_search, .lrb-blog .search-close").click(function(e){
            e.preventDefault();

            $(".lrb-blog #blog_search_bar").slideToggle();
            $(".lrb-blog .search-input").focus();
            global.utils.scrollToTop();
        });
    }

    //================================
    // Archive
    //================================
    setupArchive(){
        console.log('Setup Bog Archive');

        let scope = this;

        $(".lrb-blog #year .menu li a, .lrb-blog #month .menu li a").click(function(){
            scope.runArchiveSearch();
        });
    }
    runArchiveSearch(){
        setTimeout(function(){
            var year = $(".lrb-blog #year .value").text();
            var month = $(".lrb-blog #month .display").text().trim().toLowerCase();

            location.href="/blog/archive/"+year+"/"+month;
        },111);
    }

    //================================
    // Comments and replies
    //================================
    setupCommentsReplies(){
        console.log('Setup Blog Comments and Replies');

        let scope = this;

        var reply_to_id;
        var reply_to_user_id;


        $(".lrb-blog .comments-link").click(function(e){
            e.preventDefault();

            global.utils.scrollTo($('#comments'), 120);
        });

        $(".lrb-blog .reply").click(function(e){

            e.preventDefault();

            var parent_id = $(this).data("parentid");
            reply_to_id = $(this).data("replyto");
            reply_to_user_id = $(this).data("replytouser")
            var replytoname = $(this).data("replytoname");

            $("#reply_"+parent_id+"_input").attr("placeholder","Replying to @"+replytoname+"...");

            $(".reply-input").slideUp(100);
            $("#reply_"+parent_id).slideDown(100,function(){
                global.utils.scrollTo($("#reply_"+parent_id), 120);
            });
        });


        //================================
        // Comments: add comment
        //================================

        $(".lrb-blog #post_comment").not("disabled").click(function(e){

            e.preventDefault();

            if($("#comment_input").val()==""){
                return false;
            }

            if(global.utils.isEmoji($("#comment_input").val())){

                $("#post_comment").text("No 'Emjois' allowed");
                setTimeout(function(){
                    $("#post_comment").text("Post Comment");
                },3000);

                return false;
            }

            var params = {
                type: 'comment',
                parent_id: null,
                reply_to_id: null,
                reply_to_user_id: null,
                user_id: $(this).data("userid"),
                content_id: $(this).data("contentid"),
                comment: $("#comment_input").val(),
                input:$("#comment_input"),
                button: $(this),
                button_text: "Post Comment"
            };

            addComment(params);

        });


        //================================
        // Comments: add reply
        //================================

        $(".lrb-blog .post-reply").not("disabled").click(function(e){

            e.preventDefault();

            var this_comment = "#reply_"+$(this).data("parentid")+"_input";

            if($(this_comment).val()==""){
                return false;
            }

            if(global.utils.isEmoji($(this_comment).val())){
                return false;
            }

            if(global.utils.isEmoji($(this_comment).val())){

                $(this).text("No 'Emjois' allowed");
                setTimeout(function(){
                    $(".post-reply").text("Post Comment");
                },3000);

                return false;
            }

            var params = {
                type: 'reply',
                parent_id: $(this).data("parentid"),
                reply_to_id: reply_to_id,
                reply_to_user_id: reply_to_user_id,
                user_id: $(this).data("userid"),
                content_id: $(this).data("contentid"),
                comment: $(this_comment).val(),
                input:$(this_comment),
                button: $(this),
                button_text: "Post Reply"
            };

            addComment(params);

        });

        //================================
        // Comments: save
        //================================

        function addComment(params){

            params.button.text("Posting...");
            params.button.addClass("disabled");

            var url = "/blog/ajax/add-comment";

            $.post(url, {

                parent_id: params.parent_id,
                reply_to_id: params.reply_to_id,
                reply_to_user_id: params.reply_to_user_id,
                user_id: params.user_id,
                content_id: params.content_id,
                comment: params.comment

            }, function( data ) {

                params.button.text(params.button_text);
                params.button.removeClass("disabled");
                params.input.val("");

                if(params.type=="comment"){

                    var html = data+$("#comments>ul").html();
                    $("#comments>ul").html(html);

                }

                if(params.type=="reply"){

                    $("#replies_"+params.parent_id).append(data);
                    $(".reply-input").slideUp();
                }
            });
        }

        //================================
        // Comments: page
        //================================

        var comment_start = 0;
        var comment_page = 5;
        var comment_finish = (comment_start+comment_page)-1;

        pageComments(0);

        function pageComments(slide){

            var show_load_more = 0;

            $('.lrb-blog li.comment-node').each(function (index, value) {
                if(index>=comment_start && index<=comment_finish){
                    if(slide){
                        $(this).slideDown();
                    }else{
                        $(this).show();
                    }
                }
                if(!$(this).is(":visible")){
                    show_load_more=1;
                }
            });

            comment_start = comment_finish+1;
            comment_finish = (comment_start+comment_page)-1;

            if(show_load_more==0){
                $(".load-more-comments").hide();
            }
        }

        $(".lrb-blog .load-more-comments").click(function(e){
            e.preventDefault();
            pageComments(1);
        });
    }

    //============================
    // Old Blog Image gallery
    //============================
    setupOldBlogGallery(){
        if($('html').hasClass('oLrbBlog') && $().flexslider){

            console.log('Setup Old Blog image galleries');

            if($('.lrb-blog .carousel').length > 0){
                $('.lrb-blog .carousel').flexslider({
                    animation: "slide",
                    controlNav: false,
                    animationLoop: false,
                    slideshow: false,
                    itemWidth: 210,
                    itemMargin: 5,
                    asNavFor: '.slider'
                });
            }

            if($('.lrb-blog .carousel').length > 0){
                $('.lrb-blog .slider').flexslider({
                    animation: "slide",
                    controlNav: false,
                    directionNav: true,
                    animationLoop: false,
                    slideshow: false,
                    sync: ".carousel"
                });
            }

        }
    }

    //==========================
    // Resize Blog Images
    //==========================
    resizeBlogImages(){
        $('.lrb-blog .resize').each(function(){
          var aspect = $(this).data("aspect");
          var width = $(this).width();
          var height = width * aspect;
          $(this).css("height",height+"px");
          console.log('Resize Blog Image: '+aspect);
        });
    }

    //==========================
    // Autolinks URLs, whether or not they start with the protocol, and not breaking any already formatted/linked in EZ
    //==========================
    autoLinkComments(){
        var autolinker = new Autolinker({
            newWindow:true,
            urls: true,
            email: true,
            phone: false,
            mention: false,
            hashtag: false,
            stripPrefix: true,
            stripTrailingSlash: true,
            truncate: 50,
            className: "commentLink"
        });

        $('.lrb-blog #comments .comment').each(function(){
            $(this).html(autolinker.link( $(this).html() ));
        });
    }

    destroy(){
    }

}

module.exports = { LRBBlog };
