class Resizing {
    constructor() {
        this.resizeTimer = null;
        this.resizeInterval = 50;
    }

    setup() {
        console.log('Resizing - Setup');

        const rHandler = global.utils.throttled(this.resizeInterval, this.resizeHandler);
        if(window.attachEvent) {
            window.attachEvent('onresize', rHandler);
        }else if(window.addEventListener) {
            window.addEventListener('resize', rHandler, true);
        }else {
            //The browser does not support Javascript event binding
            window.onresize = rHandler;
        }

        try {
            this.resizeHandler();
            global.utils.measureScrollBarWidth();
            this.setScrollbarOffset(global.scrollbarWidth);
        } catch (error) {
            console.error('Resising setup error', error);
            global.LRBErrorTracking.error(error);
        }
    }

    setScrollbarOffset(offset){
        if(offset > 1){
            console.log('[Scrollbar Detected] Adding Scrollbar Offset Classes');

            var css = '.lrb-overlayed body,.lrb-overlayed .header,.lrb-supernavOpen body,.lrb-supernavOpen .header,.lrb-searchNavOpen body,.lrb-searchNavOpen .header,.lrb-searchOverlayOpen body,.lrb-searchOverlayOpen .header,.lrb-modalOpen body,.lrb-modalOpen .header,.lrb-tacModalOpen body,.lrb-tacModalOpen .header{padding-right: '+offset+'px !important;}',
            head = document.head || document.getElementsByTagName('head')[0],
            style = document.createElement('style');

            head.appendChild(style);

            style.type = 'text/css';
            if(style.styleSheet){
                // This is required for IE8 and below.
                style.styleSheet.cssText = css;
            }else{
                style.appendChild(document.createTextNode(css));
            }
        }
    }

    onResize(c, t) {
        onresize = function() {
            clearTimeout(t);
            t = setTimeout(c, 100);
        };
        return c;
    }

    resizeHandler() {
        global.utils.updateSizeRefs();
        //console.log(global.viewportWidth + " x " + global.viewportHeight);

        if(global.viewportHeight <= 650){
            $('html').addClass('vp-short');
        }else{
            $('html').removeClass('vp-short');
        }

        global.LRBNavigation.updateHeadroomOnResize();

        //update header offset
        //$('.lrb-content-container').css('margin-top',$(".header-contents").outerHeight(true)+'px');

        //reposition error page copy
        var offset = Math.max(40,$('.error-page-content .error-hero-image').outerHeight(true) - $('.error-page-content .error-hero-content .ezrichtext-field').outerHeight(true));
        $('.error-page-content .error-hero-content').css('margin-top',offset/2+'px');

        //Resize image containers
        $('.resize').each(function (index, value) {
            var aspect = $(this).data("aspect");
            var width = $(this).width();
            var height = width * aspect;
            $(this).css("height",height+"px");
        });

        //ensure article-hero-image large enough to contain contents
        $('.article-hero-image').each(function(){
            var height = Math.max(550, $(this).find('.article-hero-content').outerHeight(true) + 40);
            height += - parseInt($('.hero-image').css('marginTop'));
            $(this).css('min-height', height+'px');
        });

        //ensure video audio blocks headers remain consistent when side by side
        var maxH = 0;
        $('.home-av-feature').each(function(i){
            $(this).find('.article-link-block').each(function(){
                maxH = Math.max(maxH, $(this).find('.copy-inner').outerHeight(true));
            });
            $(this).find('.article-link-block .copy').css('min-height', maxH+'px');
        });

        //Rearrange TOC title/authors when on mobile
        if(global.viewportWidth >= 768){
            if($('html').hasClass('toc-title-swapped')){
                $('html').removeClass('toc-title-swapped');
                $('.toc-item').each(function(){
                    $(this).find('h3').insertBefore($(this).find('h4'));
                });
            }
        }else{
            if(!$('html').hasClass('toc-title-swapped')){
                $('html').addClass('toc-title-swapped');
                $('.toc-item').each(function(){
                    $(this).find('h4').insertBefore($(this).find('h3'));
                });
            }
        }

        global.resizing.modalResizeHandler();
        global.resizing.homeGridOrphanCheck();
        //global.LRBTooltip.closeTooltips();
        global.LRBBlog.resizeBlogImages();

        if(global.setupComplete){
            //Enable / Disable mobile carousels according to viewport
            if(global.viewportWidth > 768){
                //Not mobile breakpoint
                global.LRBCarousel.cleanupMobileCarousels();
            }else{
                //Mobile breakpoint
                global.LRBCarousel.setupMobileCarousels();
            }

            //Enable / Disable tablet carousels according to viewport
            if(global.viewportWidth > 1024){
                //Not tablet breakpoint
                global.LRBCarousel.cleanupTabletCarousels();
            }else{
                //Tablet breakpoint
                global.LRBCarousel.setupTabletCarousels();
            }
        }

        global.LRBCarousel.updateImageCaptionOffsets();
        global.LRBCarousel.checkCaptionWidth();

        //Force close header search nav on mobiles
        if(global.viewportWidth <= 850){
            //close search header dropdown on mobile
            global.LRBNavigation.closeSearchNav();
        }else{
            //close search overlay on other
            $('html').removeClass('lrb-searchOverlayOpen');
        }
    }

    modalResizeHandler(){
        //add scrollable class to modals when needed
        $('.modal-container.scrollable').removeClass('scrollable');
        if($('.modal-container').outerHeight(true) > (global.viewportHeight - 40)){
            $('.modal-container').addClass('scrollable');
        }
        $('.tac-modal-container.scrollable').removeClass('scrollable');
        if($('.tac-modal-container').outerHeight(true) > (global.viewportHeight - 40)){
            $('.tac-modal-container').addClass('scrollable');
        }

        if(global.viewportWidth <= 768){
            $('.modal-container .modalcontent').height(global.viewportHeight - 50);
        }else{
            $('.modal-container .modalcontent').removeAttr('style');
        }
    }

    // Check for orphaned articles when scaling to tablet size on home grid
    // (articles that are left on their own due to odd number in the grid, resulting in a width stretched orphan)
    homeGridOrphanCheck(){
        if($('body').hasClass('section-home')){
            $('.landing-page__zone').each(function(){
                var $lastBlock = $(this).find('.landing-page__block').last();
                var blockWidth = $lastBlock.outerWidth(true);
                if(blockWidth > global.viewportWidth / 2){
                    $lastBlock.addClass('orphan-block');
                }else{
                    $lastBlock.removeClass('orphan-block');
                }
            });
        }
    }

}

module.exports = { Resizing };
