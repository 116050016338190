const IGNORE_URLS = [
    'safari-web-extension','webkit-masked-url',
    'file://','/session','12ft.io',
    '//www-lrb-co-uk','openathens.net',
    '/translated.turbopages.org','kproxy.com',
    'cdn.tinypass.com','user-script:'
];

const LEVEL = {
    FATAL: "fatal",
    CRITICAL: "critical",
    ERROR: "error",
    WARNING: "warning",
    LOG: "log",
    INFO: "info",
    DEBUG: "debug"
};

class LRBErrorTracking {
    constructor() {
        window.LRBErrorTrackingSetup = false;
        window.appDetails = {};
    }

    setup() {
        console.log('%cLRBErrorTracking Setup', `background-color: ${global.infoBG};`);
        window.appDetails = this.getAppDetails();
        this.intialiseErrorTracking();
    }

    getAppDetails(){
        return {
            app: global.appname,
            version: global.version,
            browserName: window.navigator.userAgent
        };
    }

    intialiseErrorTracking(){
        let scope = this;
        if(!window.LRBErrorTrackingSetup){

            // Only init if we're not skipping errors based on bot/crawler/UA etc
            if(!this.skipErrorSending()){
                console.info('%cLRBErrorTracking Initialised', `background-color: ${global.warnBG};`);
                window.LRBErrorTrackingSetup = true;

                global.LRBSentry.setup();

                // Process queued errors
                setTimeout(this.processErrorQueue,1000);
                setInterval(this.processErrorQueue,10000);
            }else{
                console.warn('Skipping LRBErrorTracking setup');
            }

        }
    }
    checkErrorTrackingEnabled(){
        // Ensure that error tracking is setup
        if(!window.LRBErrorTrackingSetup){
            this.intialiseErrorTracking();
        }
    }

    // Errors can be queued up from within template rendering for reporting to the frontend error reporting
    // used when not possible to report directly in symfony
    processErrorQueue(){
        console.info('%cProcessing LRBError Queue', `background-color: ${global.warnBG};`);
        if(window.lrbErrorQueue && Array.isArray(window.lrbErrorQueue)){
            for(let i=0;i < window.lrbErrorQueue.length;i++){
                global.LRBErrorTracking.captureMessage(window.lrbErrorQueue[i]);
            }
            window.lrbErrorQueue = [];
        }
    }

    /*
        To facilitate setting context to errors and grouping them together
        By default this is set for each event to window.location.pathname
        Here we override this with our own values to keep the number of
        individual bug reports tidier / more useful
    */
    // Set context automatically based on the page we are on
    setPageContext(){
        var context = window.location.pathname;

        switch(true){
            case $('body').hasClass('home'):
                context = 'Homepage';
                break;
            case $('body').hasClass('category'):
                context = 'Category';
                break;
            case $('body').hasClass('collection'):
                context = 'Booklist';
                break;
            case $('body').hasClass('events'):
                context = 'Events';
                break;
            case $('body').hasClass('blog-post'):
                context = 'Blog Post';
                break;
            case $('body').hasClass('blog'):
                context = 'Blog';
                break;
            case $('body').hasClass('book'):
                context = 'Book';
                break;
            case $('body').hasClass('basket'):
                context = 'Basket';
                break;
            case $('body').hasClass('profile'):
                context = 'Profile';
                break;
            case $('body').hasClass('cake-shop'):
                context = 'Cake Shop';
                break;
            case $('body').hasClass('about-us'):
                context = 'About Us';
                break;
            case $('body').hasClass('podcasts-video'):
                context = 'Podcasts & Video';
                break;
            case $('.podcasts-video-header .podcast-play').length > 0:
                context = 'Podcast';
                break;
            case $('.podcasts-video-header .video-play').length > 0:
                context = 'Video';
                break;
        }

        this.setContext(context);
    }

    // Set context manually when performing specific functionality
    // Should be reset to the page after functionality complete as this context persists once set
    setContext(context){
        // TODO set context here and pass to sentry
    }

    reportErrorWithFallbackMessage(message, error = null){
        if(!message){
            console.error('Cannot report error without message');
            return;
        }
        var err = null;
        if(error){err = error;}else{err = message;}
        console.error(message, err);
        global.LRBErrorTracking.error(err);
    }

    /*
        Send regardless of what ENV we are on by default, unless 'env' string is provided, in which case it will send only on that ENV
    */
    captureMessage(error, level = 'error', env = null, avoidIntranet = false){

        if(!error){
            console.error('Cannot track non-existant error');
            return false;
        }
        if(this.skipErrorSending(env)){
            return false;
        }

        if(typeof error === 'object' && error !== null && error.hasOwnProperty('statusText')){
            error = error.statusText;
        }

        // Determine whether to send based on ENV
        var send = false;
        if(env){
            if(env == process.env.NODE_ENV.toLowerCase()){
                send = true;
            }
        }else{
            send = true;
        }

        // Check whether to skip sending this error on intranet URLs
        if(avoidIntranet && window.isIntranet){
            send = false;
        }

        if(send){
            if(error && error != null && Sentry && !Array.isArray(error)){

                this.checkErrorTrackingEnabled();
                global.LRBSentry.captureMessage(error, level);

            }else{
                console.error('Could not report error', error);
            }
        }
    }

    error(error, env = null){
        if(this.skipErrorSending(env)){
            return false;
        }

        this.checkErrorTrackingEnabled();
        global.LRBSentry.error(error);
    }

    // Check if can send the message / error based on env and other data
    // Also make sure reporting endpoints are setup before using them
    skipErrorSending(env){
        var skipError = false;

        // Do not report errors from a list of URLs to ignore
        if(IGNORE_URLS.indexOf(window.location.href) > -1){
            skipError = true;
        }

        // Skip bot reporting
        // Skip headless browser / scraper reporting
        if(global.isBot || global.headless){
            skipError = true;
        }

        // Check if is a facebook crawler - checking IPV6 for "::face:b00c"
        if(global.LRBSessionData.facebookBot){
            skipError = true;
        }

        // Determine whether to send based on ENV
        var send = false;
        if(env){
            // if env is set then only send if it matches current active environment
            if(env == process.env.NODE_ENV.toLowerCase()){
                send = true;
            }
        }else{
            // always send if env is not set
            send = true;
        }
        if(!send){
            skipError = true;
        }

        return skipError;
    }

    // Called in the event of late Facebook bot detection
    destroy(){
        // TODO destroy sentry after initialised here
    }
}

module.exports = { LRBErrorTracking };
