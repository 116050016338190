// import jQBrowser from 'jquery.browser';
// https://github.com/webpack/webpack/issues/4039#issuecomment-273804003
const jQBrowser = require('jquery.browser');

require('lazysizes/plugins/object-fit/ls.object-fit');
const lazySizes = require("lazysizes");

require('lazysizes/plugins/parent-fit/ls.parent-fit');
require('lazysizes/plugins/bgset/ls.bgset');
require('lazysizes/plugins/unveilhooks/ls.unveilhooks');

require('lazysizes/plugins/respimg/ls.respimg');
if (!('object-fit' in document.createElement('a').style)) {
	require('lazysizes/plugins/object-fit/ls.object-fit');
}

require('velocity-animate');

// The offset of viewport to load images from (usually 300-500)
// Smaller results in images loading when they are more in the viewport, as we want
lazySizes.cfg.expand = 200;
// Do not auto-init lazysizes, this is called later once all Dom is loaded as part of PageReady
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.init = false;

/* Run once on site setup
* (for now we're not doing partial page loading, but best to keep things
* separated in case this needs to be added in in future) */

class DocumentReady {
    constructor() {
    }

    setup(){
        $('html').addClass('lrb-justloaded');
        // global.LRBSentry.captureMessage('TM:DocReady Start');

        console.log('%c[DocumentReady]', `background-color: ${global.infoBG};`);
        try{window.performance.mark('document_ready');}catch(e){}

        console.debug('Setting up DOM using jQuery '+window.$.fn.jquery);

        this.checkForSigninDisable();
        this.checkIfPrinting();

        // Disable caching ajax requests for all content types, not just script/json
        $.ajaxSetup({cache: false});

        (async() => {
            try {
                //this.initialiseJqueryMigrate();

                await global.setupPage.setupImageErrorReloading();

                //Scrolling & Resizing Setup
                await global.scrolling.setup();
                await global.resizing.setup();

                //Main Setup
                await this.removeResponsiveBackgrounds();
                await this.setupPageClasses();
                await this.setupLazyLoadingImages();

                try{window.performance.mark('document_ready_finished');}catch(e){}

            } catch (error) {
                global.LRBErrorTracking.reportErrorWithFallbackMessage('DocReady Error',error);
            }
        })();

        $('html').removeClass('lrb-justloaded');
        // global.LRBSentry.captureMessage('TM:DocReady Complete');

        // Remove loading overlay for covers listing page so can see covers as they load
        if($('body').hasClass('section-covers')){
            setTimeout(function(){
                global.setupPage.setupPageLoadedClasses();
            },5000);
        }

        // global.LRBSentry.captureMessage('TM:DocReady End');
    }

    // Check if sign-in should be disabled, and do so
    checkForSigninDisable(){
        if($('html').hasClass('noSignIn')){
            global.LRBallowSignIn = false;
        }

        if(!global.LRBallowSignIn){
            console.warn("Sign-in Disabled");

            $('#login_form').addClass('disabled');
            $('.js-login-link').addClass('disabled');
            $(".js-signup-link").addClass('disabled');
            $("#institutions_account_link, .institutions_account_link").addClass('disabled');
        }
    }

    // Check if the document load is from a print view
    checkIfPrinting(){

        let scope = this;
        global.isPrint = false;

        console.debug("Checking if the page is being printed");

        //To enable triggering JS before / after printing the page
        if (window.matchMedia) {
            var mediaQueryList = window.matchMedia('print');
            if(mediaQueryList.matches){
                scope.beforePrinting();
            }
            mediaQueryList.addListener(function(mql) {
                if (mql.matches) {
                    scope.beforePrinting();
                } else {
                    scope.afterPrinting();
                }
            });
        }
        window.onbeforeprint = scope.beforePrinting;
        window.onafterprint = scope.afterPrinting;
    }

    beforePrinting(){
        global.isPrint = true;
    }
    afterPrinting(){
        global.isPrint = false;
    }

    removeResponsiveBackgrounds(){
        if(!$('body').hasClass('lrb-holding-page')){
            if(global.viewportWidth < 600){
                $('.background-container').removeClass('lazyload').data('sizes','');
            }
        }
    }

    setupPageClasses() {
        if ("ontouchstart" in document.documentElement) {
            document.documentElement.className += ' touch';
            global.touch = true;
        } else {
            document.documentElement.className += ' no-touch';
            global.touch = false;
        }

        $('html').addClass(jQBrowser.name);
        $('html').addClass(jQBrowser.platform);
        if(jQBrowser.msie){
            $('html').addClass('ie');
            $('html').addClass('ie-'+parseInt(jQBrowser.version));
            $('img').css("-ms-interpolation-mode", "bicubic");

            //force loading of data-src on IE for lazyload images on printing
            if(global.isPrint){
                $('img.lazyload, img.lazyloaded, img.lazyloading, img.lazypreload').each(function(){
                    $(this).attr('src', $(this).data('src')).removeClass('lazyload');
                });
            }
        }else{
            $('html').addClass('no-ie');
        }

        //add base site class when organisation access notification shown, or in demo mode
        if($('.institutions-header').length > 0){
            $('html').addClass('organisation-header-active');
        }
        if($('.demo-strip').length > 0){
            $('html').addClass('demo-site');
        }

        $('html').addClass('page-initial-load');
    }

    setupLazyLoadingImages(){
        if(global.isPrint){
            $('.lazyload, .lazyloaded').addClass('lazypreload');
        }
        lazySizes.init();
        setTimeout(function(){
            global.documentReady.fixStalledLazyLoadImages();
        },5000);
    }

    updateLazyLoadingImages(){
        console.log("Updating Responsive Image Sizes");
        lazySizes.autoSizer.checkElems();
        setTimeout(function(){
            global.documentReady.fixStalledLazyLoadImages();
        },1000);
    }

    fixStalledLazyLoadImages(){
        $('.lazyloading').removeClass('lazyloading').addClass('lazyload');
    }

    initialiseJqueryMigrate(){
        // If this is in dev mode then initialise Jquery Migrate to flag any deprecations when going to upgrade JQuery - migrate URL below to be upgraded accordingly as needed per each upgrade / migration

        if(process.env.NODE_ENV === "development" || process.env.NODE_ENV === "dev"){
            console.log("initialiseJqueryMigrate - Checking for JQuery Deprecations");
            var JQMigrateURL = "https://code.jquery.com/jquery-migrate-3.3.1.js";

            var a = document.createElement("script");
            a.type = "text/javascript";
            a.text = "jQuery = $;";
            var b = document.getElementsByTagName("script")[0];
            b.parentNode.insertBefore(a, b);

            var migrateScript = document.createElement("script");
            migrateScript.type = "text/javascript";
            migrateScript.setAttribute("async", "true");
            migrateScript.setAttribute("src", JQMigrateURL);
            document.head.appendChild(migrateScript);
        }

    }
}

module.exports = { DocumentReady };
