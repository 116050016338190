try {
    window.performance.mark("js_parsed");
} catch (e) {}

// ** IEFix - Force use of babel-polyfill for Promises for all **//
if (window.Promise) {
    console.debug(
        `This browser provides a native Promise. Disabling it to force use of core-js to allow "onunhandledrejection".`
    );
    delete window.Promise;
}
/* Fix for Promises in IE, old Edge, old FF */
require("@babel/polyfill/dist/polyfill");
// babel -> core-js shims that aren't yet fully supported
//require('core-js/modules/es7.promise.finally');
//require('core-js/modules/es7.promise.try');
/* End IEFix */

//Jquery Setup
var $ = require("jquery");
window.$ = window.jQuery = $;

//Other top level requirements
require("jquery-ui");
require("jquery.browser");
require("fitvids");
require("flickity");

$("html").removeClass("no-js");
$("html").addClass("lrb-js");

/* Event Listeners */

// Images on homepage above the fold should preload, this needs to be added here prior to onload event listener
$('#home-grid-1 .landing-page__block:lt(3) .lazyload').addClass('lazypreload');

// Add app start listeners
window.onload = function () {
    try {
        window.performance.mark("window_loaded");
    } catch (e) {}

    // Start the app
    lrbAppStart();
}

// Add event listeners for both events
document.addEventListener('DOMContentLoaded', onDOMReady);


/* Utils */
import { Utilities } from "./scripts/1_setup/Utilities";

/* 1_Setup - Core Document Classes, Setup, and Loading */
import { DocumentReady } from "./scripts/1_setup/1DocumentReady";
import { PageReady } from "./scripts/1_setup/2PageReady";
import { SetupPage } from "./scripts/1_setup/3SetupPage";
import { LRBSessionData } from "./scripts/1_setup/LRBSessionData";
import { LRBAccessibility } from "./scripts/1_setup/LRBAccessibility";
import { LRBTypography } from "./scripts/1_setup/LRBTypography";

/* 2_Layout - Scrolling and Resizing Handlers */
import { Scrolling } from "./scripts/2_layout/Scrolling";
import { Resizing } from "./scripts/2_layout/Resizing";

/* 3_Objects - Objects */
/* Media */
import { LRBVideo } from "./scripts/3_objects/media/LRBVideo";
/* UI */
import { LRBAccordion } from "./scripts/3_objects/ui/LRBAccordion";
import { LRBAlerts } from "./scripts/3_objects/ui/LRBAlerts";
import { LRBModal } from "./scripts/3_objects/ui/LRBModal";
import { LRBNavigation } from "./scripts/3_objects/ui/LRBNavigation";
import { LRBPagination } from "./scripts/3_objects/ui/LRBPagination";
import { LRBReadMore } from "./scripts/3_objects/ui/LRBReadMore";
import { LRBTooltip } from "./scripts/3_objects/ui/LRBTooltip";
import { LRBUnlinkedDropdown } from "./scripts/3_objects/ui/LRBUnlinkedDropdown";
/* Forms */
import { LRBAccountForms } from "./scripts/3_objects/form/LRBAccountForms";
import { LRBInstitutionForms } from "./scripts/3_objects/form/LRBInstitutionForms";
import { LRBAddressAutoComplete } from "./scripts/3_objects/form/LRBAddressAutoComplete";
import { LRBForms } from "./scripts/3_objects/form/LRBForms";
import { LRBFormValidation } from "./scripts/3_objects/form/LRBFormValidation";
import { LRBSubscriptions } from "./scripts/3_objects/form/LRBSubscriptions";
import { LRBDatePicker } from "./scripts/3_objects/form/LRBDatePicker";
import { LRBDropdown } from "./scripts/3_objects/form/LRBDropdown";
import { LRBCodeInput } from "./scripts/3_objects/form/LRBCodeInput";
import { LRBRecaptcha } from "./scripts/3_objects/form/LRBRecaptcha";

/* 4_Components */
import { LRBEventsLoader } from "./scripts/4_components/LRBEventsLoader";
import { LRBCarousel } from "./scripts/4_components/LRBCarousel";
import { LRBCharts } from "./scripts/4_components/LRBCharts";
import { LRBInstagramGrid } from "./scripts/4_components/LRBInstagramGrid";
import { LRBLightbox } from "./scripts/4_components/LRBLightbox";
import { LRBPaywall } from "./scripts/4_components/paywall/LRBPaywall";
import { DigitalEditionPaywall } from "./scripts/4_components/paywall/DigitalEditionPaywall";

/* 5_Pages */
import { LRBArticle } from "./scripts/5_pages/LRBArticle";
import { LRBArchive } from "./scripts/5_pages/LRBArchive";
import { LRBContributors } from "./scripts/5_pages/LRBContributors";
import { LRBInstitutions } from "./scripts/5_pages/LRBInstitutions";
import { LRBLetters } from "./scripts/5_pages/LRBLetters";
import { LRBCourses } from "./scripts/5_pages/LRBCourses";
import { LRBSearch } from "./scripts/5_pages/LRBSearch";
import { LRBToc } from "./scripts/5_pages/LRBToc";
import { LRBBlog } from "./scripts/5_pages/LRBBlog";

/* 6_Tracking */
import { LRBDataLayer } from "./scripts/6_tracking/LRBDataLayer";
import { LRBErrorTracking } from "./scripts/6_tracking/error/LRBErrorTracking";
import { LRBSentry } from "./scripts/6_tracking/error/sentry/LRBSentry";
import { LRBTracking } from "./scripts/6_tracking/LRBTracking";
import { GoogleGTag } from "./scripts/6_tracking/google/GTag";
import { GA4 } from "./scripts/6_tracking/google/GA4";
import { Hotjar } from "./scripts/6_tracking/hotjar/Hotjar";

import { Shame } from "./scripts/7_overrides/Shame";

window.isIntranet = false;
if (window.location.hostname.indexOf("lrb-intranet") > -1) {
    window.isIntranet = true;
}

/* Utils */
global.version = process.env.VERSION;
global.startDate = new Date();
global.utils = new Utilities();

/* Core Document Classes, Setup, and Loading */
global.documentReady = new DocumentReady();
global.pageReady = new PageReady();
global.setupPage = new SetupPage();
global.LRBSessionData = new LRBSessionData();
global.LRBAccessibility = new LRBAccessibility();
global.LRBTypography = new LRBTypography();

/* Scrolling and Resizing Handlers */
global.scrolling = new Scrolling();
global.resizing = new Resizing();

/* Components */
global.LRBAccordion = new LRBAccordion();
global.LRBAlerts = new LRBAlerts();
global.LRBEventsLoader = new LRBEventsLoader();
global.LRBModal = new LRBModal();
global.LRBNavigation = new LRBNavigation();
global.LRBPagination = new LRBPagination();
global.LRBReadMore = new LRBReadMore();
global.LRBTooltip = new LRBTooltip();
global.LRBUnlinkedDropdown = new LRBUnlinkedDropdown();
global.LRBAccountForms = new LRBAccountForms();
global.LRBInstitutionForms = new LRBInstitutionForms();
global.LRBAddressAutoComplete = new LRBAddressAutoComplete();
global.LRBForms = new LRBForms();
global.LRBFormValidation = new LRBFormValidation();
global.LRBSubscriptions = new LRBSubscriptions();
global.LRBDatePicker = new LRBDatePicker();
global.LRBDropdown = new LRBDropdown();
global.LRBCodeInput = new LRBCodeInput();
global.LRBRecaptcha = new LRBRecaptcha();

/* Paywalls */
global.LRBPaywall = new LRBPaywall();
global.DigitalEditionPaywall = new DigitalEditionPaywall();

/* Modules */
global.LRBCarousel = new LRBCarousel();
global.LRBCharts = new LRBCharts();
global.LRBInstagramGrid = new LRBInstagramGrid();
global.LRBVideo = new LRBVideo();
global.LRBLightbox = new LRBLightbox();

/* Pages */
global.LRBArticle = new LRBArticle();
global.LRBArchive = new LRBArchive();
global.LRBContributors = new LRBContributors();
global.LRBInstitutions = new LRBInstitutions();
global.LRBLetters = new LRBLetters();
global.LRBCourses = new LRBCourses();
global.LRBSearch = new LRBSearch();
global.LRBToc = new LRBToc();

/* Blog */
global.LRBBlog = new LRBBlog();

/* Tracking */
global.LRBDataLayer = new LRBDataLayer();
global.LRBErrorTracking = new LRBErrorTracking();
global.LRBSentry = new LRBSentry();
global.LRBTracking = new LRBTracking();
global.GoogleGTag = new GoogleGTag();
global.GA4 = new GA4();
global.Hotjar = new Hotjar();

/* Shame */
global.shame = new Shame();

/* Misc */
global.loadingIcon = '<div class="lrb-loading-icon" aria-hidden="true"></div>';
global.warnBG = "#FFFBE5";
global.infoBG = "#87b1d3";
global.successBG = "#5ab974";
global.errorBG = "#e94235";

global.LRBLoaded = false;
global.LRBallowSignIn = true;

if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "prod") {
    window.envMode = "production";
} else {
    window.envMode = "development";
}

global.headless = global.utils.detectHeadlessBrowser(window.navigator.userAgent);
global.isBot = global.utils.detectBot(window.navigator.userAgent);


/* Track time to start App */
var endDate = new Date();
var seconds = (endDate.getTime() - global.startDate.getTime()) / 1000;
console.log(
    `%c--App started in ${seconds} seconds--`,
    `background-color: ${global.warnBG};`
);


/* Entry Point */
var lrbAppStarted = false;
function lrbAppStart() {
    if(lrbAppStarted)return;
    lrbAppStarted = true;

    try {
        window.performance.mark("app_started");
    } catch (e) {}

    // Load Session Data
    global.LRBSessionData.setup();

    // Tracking Setup
    global.LRBTracking.setup();

    (async() => {
        try {
            // Main Setup
            await documentReady.setup();

            /* Trigger page ready function */
            await global.pageReady.setup();

            /* Page ready completed, trigger setup page function */
            await global.setupPage.setup();

            /* Track time to load App */
            endDate = new Date();
            seconds = (endDate.getTime() - global.startDate.getTime()) / 1000;
            console.log(
                `%c--App loaded in ${seconds} seconds--`,
                `background-color: ${global.warnBG};`
            );

        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('appStart Error',error);
        }
    })();
}
  
function onDOMReady() {
    try {
        window.performance.mark("dom_loaded");
    } catch (e) {}

    // Remove the DOMContentLoaded event listener
    document.removeEventListener('DOMContentLoaded', onDOMReady);

    // Start the app
    lrbAppStart();
}
