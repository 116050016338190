class LRBEventsLoader {
    constructor() {
    }

    setup() {
        console.log('%cLRBEventsLoader Setup', `background-color: ${global.infoBG};`);

        try {
            //this.loadHomeEvents();
            this.loadSidebarEvents();

            this.eventsLoaded($(".sidebar-events"));

        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Events Loader',error);
        }

        
    }

    loadHomeEvents(){
        let scope = this;

        if($("#home_events_content").length > 0){
            console.log('Loading Home Events');

            $("#home_events_content").load("/ajax/events/3/events", function(response, status, xhr){
                var statusCol = global.successBG;
                if(status == "error"){
                    statusCol = global.errorBG;
                }
                console.log('Home Events Loading: %c'+status, `background-color: ${statusCol};`);

                if(status == "error"){
                    var msg = "Home Events Loading Error: ";
                    console.error( msg + xhr.status + " " + xhr.statusText );
                    global.LRBErrorTracking.error(response);
                }else{
                    //$("#home_events_content").html(response);
                    scope.eventsLoaded($("#home_events_content"));
                }
            });

        }

    }

    loadSidebarEvents(){
        let scope = this;

        if($(".sidebar-events").length > 0){
            console.log('Loading Sidebar Events');

            $(".sidebar-events" ).load("/ajax/events/3/events_sidebar", function(response, status, xhr){
                var statusCol = global.successBG;
                if(status == "error"){
                    statusCol = global.errorBG;
                }
                console.log('Sidebar Events Loading: %c'+status, `background-color: ${statusCol};`);

                if(status == "error"){
                    var msg = "Sidebar Events Loading Error: ";
                    console.error( msg + xhr.status + " " + xhr.statusText );
                    global.LRBErrorTracking.error(response);
                }else{
                    $(".sidebar-events").html(response);
                    scope.eventsLoaded($(".sidebar-events"));
                    global.LRBCarousel.checkArticleSideNavClashes();
                }
            });

        }
    }

    eventsLoaded($events){
        global.LRBTypography.cleanupTypeFurniture('.events-panel');
        global.LRBCarousel.setup();
        setTimeout(function(){
            $events.addClass('events-loaded');
        },100);
        global.resizing.resizeHandler();
    }

    destroy(){
    }

}

module.exports = { LRBEventsLoader };
