class LRBInstitutions {
    constructor() {
    }

    setup() {

        try {
            let scope = this;
    
            if($('.section-librarian').length > 0){
                console.log('%cLRBInstitutions Setup', `background-color: ${global.infoBG};`);
                scope.setupChecklists();
            }
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Institutions',error);
        }
    }

    setupChecklists(){
        $('.lrb-content-container .inline-modal .ezrichtext-field ul').each(function(){
            $(this).wrap( "<div class='lrb-checklist lrb-checklist-singlecol'></div>" );
        });
    }

    destroy(){
    }

}

module.exports = { LRBInstitutions };
