class LRBAlerts {
    constructor() {
    }

    setup() {
        console.log('%cLRBAlerts Setup', `background-color: ${global.infoBG};`);
    }

    //============================
    // Show Error
    //============================
    showError(id,msg){
        $(".alert-success").slideUp();
        $("#"+id).html("&nbsp;");

        global.utils.scrollToTop();

        $("#"+id).slideUp().slideDown(300,function(){
            $("#"+id).html(msg);
        });

        $('.submitting').removeClass('submitting');
    }

    //============================
    // Show Notification
    //============================
    showNotification(id,msg){
        $(".alert-danger").slideUp();
        $("#"+id).html("&nbsp;");

        global.utils.scrollToTop();

        $("#"+id).slideUp().slideDown(300,function(){
            $("#"+id).html(msg);
        });
    }

    //============================
    // Show Form Error
    //============================
    showFormError(msg){
        $("#form-error-notification").html(global.utils.unescapeHTML(msg)).slideDown();
        $("#form-error-notification").attr('role','alert');
        $("#form-error-notification").attr('aria-hidden','false');
        global.utils.scrollTo($("#form-error-notification"));

        $('.submitting').removeClass('submitting');
    }

    //============================
    // Show Form Warning
    //============================
    showFormWarning(msg){
        $("#form-error-notification.status-warn").html(global.utils.unescapeHTML(msg)).slideDown();
        $("#form-error-notification.status-warn").attr('role','alert');
        $("#form-error-notification.status-warn").attr('aria-hidden','false');
        global.utils.scrollTo($("#form-error-notification.status-warn"));
    }
    clearFormWarnings(){
        $("#form-error-notification.status-warn").html('').slideUp();
        $("#form-error-notification.status-warn").attr('aria-hidden','true');
    }

    //============================
    // Show Form Success
    //============================
    showFormSuccess(msg){
        $("#form-success-notification").html(global.utils.unescapeHTML(msg)).slideDown();
        $("#form-success-notification").attr('role','alert');
        $("#form-success-notification").attr('aria-hidden','false');
        global.utils.scrollTo($("#form-success-notification"));
    }
    clearFormNotifications(){
        $("#form-success-notification").slideUp();
    }

    //============================
    // Show Modal Error
    //============================
    showModalError(msg){
        if($('body').hasClass('section-login')){
            //Use inline messaging
            global.LRBFormValidation.showGeneralError(msg);
        }else{
            //Use modal messaging
            $("#modal-error-notification").html(msg).slideDown();
            $("#modal-error-notification").attr('role','alert');
            $("#modal-error-notification").attr('aria-hidden','false');
        }

        $('.submitting').removeClass('submitting');
    }

    //============================
    // Show Modal Success
    //============================
    showModalSuccess(msg){
        $("#modal-success-notification").html(msg).slideDown();
        $("#modal-success-notification").attr('role','alert');
        $("#modal-success-notification").attr('aria-hidden','false');
    }
    clearModalNotifications(){
        $("#modal-error-notification").slideUp();
        $("#modal-success-notification").slideUp();
    }

    destroy(){
    }

}

module.exports = { LRBAlerts };
