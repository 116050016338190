const ENABLE_SENTRY = true;

// Set sample rate to 1.0 to capture 100% of errors for Staging
const STAGING_ERROR_SAMPLE_RATE = 1.0;
const DEBUG_STAGING = true;
// Set sample rate to 0.25 to capture 25% of errors for Production
const PRODUCTION_ERROR_SAMPLE_RATE = 0.25;
const DEBUG_PRODUCTION = false;

const IGNORE_ERRORS = [
    // Random plugins/extensions
    /top\.GLOBALS/,
    /\'scroller\'/,
    /ResizeObserver/,
    /this.media.webkitEnterFullscreen/,
    /e\[t\].call/,
    /Illegal\sinvocation/,
    /webkit-masked-url/,
    /WKWebView\sAPI/,
    /ReadwiseElement/,
    /__adblocker/,
    /AddFingerprintAction/,
    /msDiscoverChatAvailable/,
    /gtm\/js/,
    /src\/helpers/,
    /get-frame-manager-configuration/,
    /cancellation\sof\sshare/,
    /Share\scanceled/,
    /Stylesheet\sappend\stimeout/,
    /No\sreCAPTCHA\sclients\sexist/,
    /\#\:\~\:/,
    /jquery\.magnify/,
    /IndexSizeError/,
    /\<anonymous\>/,
    /\<unknown\>/,
    /closeFullScreen/,
    // Cabin errors
    /The\soperation\sis\sinsecure/,
    /hello\.js/,
    /v\(hello\)/,
    // Sentry errors
    /sentry\/scripts/,
    /sentry-cdn/,
    /ReplayContainer/,
    /replay\/build\/npm/,
    /HTMLIFrameElement/,
    /iframeLoadTimeout/,
    // OneTrust errors
    /zo\.bannerGroup\.html/,
    /Window\.getComputedStyle/,
    /otBannerSdk/,
    /otSDKStub/,
    /src\/sdk/,
    /scripttemplates/,
    /insertAlertHtml/,
    /this\.shiftBannerFocus/,
    /OneTrustStub/,
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    /originalCreateNotification/,
    /canvas\.contentDocument/,
    /MyApp\_RemoveAllHighlights/,
    /tt.epicplay.com/,
    /ZiteReader/,
    /jigsaw\sis\snot\sdefined/,
    /ComboSearch\sis\snot\sdefined/,
    /loading\.retry\.widdit.com/,
    /atomicFindClose/,
    // Facebook borked
    /fb\_xd\_fragment/,
    // ISP /optimizing/ proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    /bmi\_SafeAddOnload/,
    /EBCallBackMessageReceived/,
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    /conduitPage/,
    /Extension\scontext\sinvalidated/,
    /Permission\sdenied\sto\saccess\sproperty/
];

const DENY_URLS = [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /-extension/i,
    /^chrome:\/\//i,
    // Other plugins
    /kaspersky-labs\.com/i,
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /webkit-masked-url/i,
    /safari-web-extension/i,
    /safari-extension/i,
    /^file:\/\//i,
    /user-script:/i,
    // Cabin
    /withcabin\.com/i,
    // Hotjar
    /hotjar\.com/i,
    /jar\.com/i,
    // Facebook
    /facebook\.com/i,
    /facebook\.net/i,
    // Google
    /google\.com/i,
    /google\-analytics\.com/i,
    /googletagmanager\.com/i,
    /gstatic\.com/i,
    // New Relic
    /newrelic\.com/i,
    /nr-data\.com/i,
    // Eventbrite
    /evbuc\.com/i,
    // Cloudflare
    /cloudflare\.com/i,
    // OneTrust
    /cookielaw\.org/i,
    /onetrust\.com/i,
    // Proxies
    /www-lrb-co-uk/i,
    /openathens\.net/i,
    /turbopages\.org/i,
    /kproxy\.com/i,
    // Sentry
    /sentry-cdn\.com/i,
    /browser\.sentry-cdn\.com/i,
    // 12ft paywall bypass
    /12ft\.io/i
];

class LRBSentry {
    constructor() {
        window.sentrySetup = false;
        window.startSentry = this.intialiseSentry();
    }

    setup() {
        console.log('%cSentry Setup', `background-color: ${global.infoBG};`);
        this.intialiseSentry();
        this.updateSentryContext();
    }

    intialiseSentry(){
        if(ENABLE_SENTRY && !window.sentrySetup && !window.LRBErrorTracking.skipErrorSending && !window.isBot){

            console.info('%cSentry Initialising...', `background-color: ${global.warnBG};`);
            window.sentrySetup = true;

            var env = process.env.NODE_ENV.toLowerCase();
            var sampleRate = PRODUCTION_ERROR_SAMPLE_RATE;
            var debug = DEBUG_PRODUCTION;
            var attachStacktrace = DEBUG_PRODUCTION;

            if(env === "staging" || env === "dev" || env === "development"){
                sampleRate = STAGING_ERROR_SAMPLE_RATE;
                debug = DEBUG_STAGING;
                attachStacktrace = DEBUG_STAGING;
            }

            // Ignore URLs set above as regex, and in main error tracking to double check those are skipped
            var denyUrls = DENY_URLS.concat(global.LRBErrorTracking.IGNORE_URLS);

            // Initialise Sentry
            window.Sentry.init({
                maxBreadcrumbs: 50,
                debug: debug,
                environment: env,
                autoSessionTracking: true,
                attachStacktrace: attachStacktrace,
                tracesSampleRate: sampleRate,
                ignoreErrors: IGNORE_ERRORS,
                denyUrls: denyUrls,
                release: 'LRB@'+ global.version,
                beforeSend(event, hint) {
                    const error = hint.originalException;
                    if (
                      error &&
                      error.message &&
                      error.message.match(/database unavailable/i)
                    ) {
                      event.fingerprint = ["database-unavailable"];
                    }
                    return event;
                }
            });
        }
        if(!ENABLE_SENTRY){
            console.warn('Sentry Disabled');
        }

        this.updateSentryContext();
    }

    checkSentryEnabled(){
        if(!global.LRBErrorTracking.skipErrorSending()){
            this.intialiseSentry();
        }
    }

    captureMessage(message, severity){
        this.checkSentryEnabled();
        if(window.sentrySetup){
            try{
                this.updateSentryContext();
                window.Sentry.captureMessage(message, severity);
            }catch(e){
                console.error("Cannot access Sentry");
            }
        }else{
            console.error("Sentry not setup");
        }
    }

    error(error){
        this.checkSentryEnabled();
        if(window.sentrySetup){
            try{
                this.updateSentryContext();
                window.Sentry.captureException(error);
            }catch(e){
                console.error("Cannot access Sentry");
            }
        }else{
            console.error("Sentry not setup");
        }
    }


    updateSentryContext(){
        if(window.Sentry){
            try {
                // Update login state
                if(window.Sentry.setTag)window.Sentry.setTag("signed_in", global.LRBSessionData.loggedIn);
    
                // Update User ID
                if(window.Sentry.setUser){
                    if(global.LRBSessionData.userID){
                        window.Sentry.setUser({ id: global.LRBSessionData.userID});
                    }else{
                        window.Sentry.setUser(null);
                    }
                }
    
                // Update session details
                if(global.LRBSessionData.sessionData){
                    if(window.Sentry.setContext)window.Sentry.setContext("sessionData", global.LRBSessionData.sessionData);
                }
    
                // Update app details
                if(window.Sentry.setContext)window.Sentry.setContext("frontend", window.appDetails);
            } catch (error) {
                console.error("Cannot set Sentry context");
            }
        }
    }
}

module.exports = { LRBSentry };
