class LRBFormValidation {
    constructor() {
        //Displayed at the top of the form when there are errors with fields below
        this.generalError = "Please complete all fields.";

        //Displayed at the top of the Create Account form when there are errors with fields below
        this.generalCreateAccountError = "Please check your details and try again.";

        //Displayed below individual fields when there are errors with them
        this.invalidEmail_fieldError = "Please enter a valid email address.";
        this.invalidNumberLimit_fieldError = "This is not a valid number, please refer to the instruction below.";
        this.required_fieldError = "This field is required.";
        this.passwordMatch_fieldError = "The passwords you entered do not match.";
        this.emailMatch_fieldError = "The email addresses entered do not match.";
        this.googleRecaptcha_fieldError = "Please complete this field.";

        //If true then submit buttons on auto validating forms will not submit
        //This allows for easier testing of error messages without submitting forms
        this.debugSubmit = false;

        //If true then will not do front-end validation on Google Recaptcha fields, overridden by ENV VAR
        this.disableGoogleRecaptchaCheck = false;

        //Easy disabling of error messages
        this.allowErrorMessages = true;

        //Used by forms with the class 'no-genvalidation-errors' to disable general errors on that form
        this.disableGeneralErrorMessages = false;
    }

    setup() {
        console.log('%cLRBFormValidation Setup', `background-color: ${global.infoBG};`);

        try {
            this.autoValidate();
            this.checkForErrorsOnLoad();
    
            // Overwrite default value with ENV VAR if set (in header.html.twig)
            if (typeof window.lrbRecaptcha !== 'undefined') {
                this.disableGoogleRecaptchaCheck = window.lrbRecaptcha;
            }
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Auto Validation',error);
        }
    }

    /** General Error Messages **/

    //===============================
    // Show error message at top of form, if element available, as set in:
    // account_forms/form_notifications.html.twig
    //===============================
    showGeneralError(msg){
        if(!this.allowErrorMessages || this.disableGeneralErrorMessages){
            return false;
        }
        let scope = this;

        console.log("[General Form Error] "+msg);

        var $targetID = '#form-error-notification';
        if($('html').hasClass('lrb-modalOpen')){
            $targetID = '#modal-error-notification';
        }

        if($($targetID).length && msg && msg != ""){

            //If form does not have class to suppress General Validation Errors then show error message
            if(!$($targetID).parent().hasClass('no-GVE-errors')){
                $($targetID).html(msg);
                if($($targetID+':visible').length == 0){
                    $($targetID).slideDown();
                }
                $($targetID).removeAttr('aria-hidden');
                $($targetID).attr('role','alert');

                scope.hideGeneralSuccess(true);
            }

        }

        $('.modal-container .modalcontent').animate({ scrollTop: 0 }, 400, "swing");
        $('.submitting').removeClass('submitting');
    }

    //===============================
    // Hide error message at top of form
    //===============================
    hideGeneralError(animate = false){
        if(!this.allowErrorMessages){
            return false;
        }

        console.log("[Clear General Form Errors]");

        var $targetID = '#form-error-notification';
        if($('html').hasClass('lrb-modalOpen')){
            $targetID = '#modal-error-notification';
        }

        if($($targetID).length){
            $($targetID).html('');
            if(animate){
                $($targetID).slideUp(200);
            }else{
                $($targetID).css('display','none');
            }
            $($targetID).attr('aria-hidden',true);
        }
    }

    //===============================
    // Show success message at top of form, if element available, as set in:
    // account_forms/form_notifications.html.twig
    //===============================
    showGeneralSuccess(msg){
        if(!this.allowErrorMessages){
            return false;
        }

        console.log("[General Form Success] "+msg);
        if($('#form-success-notification').length && msg && msg != ""){

            //If form does not have class to suppress General Validation Success then show success message
            if(!$('#form-success-notification').parent().hasClass('no-GVE-success')){
                $('#form-success-notification').html(msg);
                if($('#form-success-notification:visible').length == 0){
                    $('#form-success-notification').slideDown();
                }
                $('#form-success-notification').removeAttr('aria-hidden');
                $('#form-success-notification').attr('role','alert');
            }

        }

        $('.submitting').removeClass('submitting');
    }

    //===============================
    // Hide error message at top of form
    //===============================
    hideGeneralSuccess(animate = false){
        if(!this.allowErrorMessages){
            return false;
        }

        console.log("[Clear General Form Success]");
        if($('#form-success-notification').length){
            $('#form-success-notification').html('');
            if(animate){
                $('#form-success-notification').slideUp(200);
            }else{
                $('#form-success-notification').css('display','none');
            }
            $('#form-success-notification').attr('aria-hidden',true);
        }
    }


    //===============================
    // If form errors exist on page-load then jump to relevant point on page, when 'errorAutoScroll' class exists
    //===============================
    checkForErrorsOnLoad(){
        if($('.errorAutoScroll').length){

            var scrollTarget = null;

            if($('.error').length){
                scrollTarget = $('.error').eq(0);
            }

            if($('#form-error-notification').length){
                if($('#form-error-notification').hasClass('status-error')){
                    scrollTarget = $('#form-error-notification');
                }
            }else if($('.validation-error-message.error').length){
                scrollTarget = $('.validation-error-message.error').first();
            }

            if(scrollTarget){
                console.log("Error notification found, jumping to that part of the page");
                setTimeout(function(){
                    global.utils.scrollTo(scrollTarget, 300, 1);
                },50);
            }else{
                console.log("No error notification found");
            }

        }
    }


    /** Per Field Error Messages **/

    //===============================
    // Show error message below field, if element available, as set in:
    // account_forms/form_validation_holder.html.twig
    //===============================
    showFieldError($field, msg){
        if(!this.allowErrorMessages){
            return false;
        }

        console.log("[Field Error] "+$field + " - " +msg);
        if($field && msg){
            $field.parent().find('.validation-error-message').html(msg).addClass('error');

            //fix for repeated fields in static forms - i.e. password confirm
            if($field.parent().parent().parent().hasClass('form-group-duo')){
                $field.parent().parent().parent().find('.validation-error-message').html(msg).addClass('error');
            }
        }

        $('.submitting').removeClass('submitting');
    }
    //===============================
    // Hide error message at top of form
    //===============================
    hideFieldError($field){
        if(!this.allowErrorMessages){
            return false;
        }

        console.log("[Clear Field Error] "+$field);
        if($field){
            $field.parent().find('.validation-error-message').html('').removeClass('error');
        }
    }
    clearFieldErrors(){
        if(!this.allowErrorMessages){
            return false;
        }

        console.log("[Clear All Field Errors]");
        $('.validation-error-message').html('').removeClass('error');
        $('form input, form select, form textarea').removeClass('error');
    }



    /** End Error Messages **/

    //===============================
    // Run through all validations automatically on forms that are set to automatically validate
    //===============================
    autoValidate(){

        let scope = this;

        $('.autoValidate').each(function(){

            var $parent = $(this);

            //Only setup on forms not already setup
            if(!$(this).hasClass('autoValidate-setup')){
                console.log("LRBFormValidation -- autoValidate setup");

                $(this).find('button[type="submit"], #form_send').on('click', function(e){

                    if($(this).hasClass('no-genvalidation-errors')){
                        //temp block general errors for this form
                        scope.disableGeneralErrorMessages = true;
                    }

                    var errors = !scope.autoValidateFieldsInForm($parent);

                    //reset temp error block
                    scope.disableGeneralErrorMessages = false;

                    $(this).addClass('submitting');

                    if(errors){
                        e.preventDefault();
                        e.stopPropagation();

                        $(this).removeClass('submitting');

                        return false;
                    }

                    if(scope.debugSubmit){
                        $(this).removeClass('submitting');
                        $(this).html('!!!!');

                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }

                });

                scope.setupSpecificDisabledFields($parent);

                $(this).addClass('autoValidate-setup');
            }
        });
    }
    autoValidateFieldsInForm($form){
        var errors = 0;
        this.clearFieldErrors();

        /* -- Avoid refactoring this, compressing to a one-line if/ternary or anything more compact breaks this -- */
        var emailFieldErrors = !this.emailTest($form);
        var requiredErrors = !this.requiredTest($form);

        var passwordMatchErrors = false;
        if($form.find('.passwordmatch--validate').length > 0){
            passwordMatchErrors = !this.fieldMatchTest($form, '.passwordmatch--validate', this.passwordMatch_fieldError);
        }

        var googleCaptchaErrors = !this.googleRecaptchaCompleteCheck($form);

        var numberLimitErrors = !this.numberLimitTest($form);

        var emailMatchErrors = false;
        if($form.find('.emailmatch--validate').length > 0){
            emailMatchErrors = !this.fieldMatchTest($form, '.emailmatch--validate', this.emailMatch_fieldError);
        }

        //A general error message override, for when there is only an ondividual error
        var generalErrorOverride = "";

        if(emailFieldErrors){
            console.log('Email Field Errors');
            errors++;
        }
        if(requiredErrors){
            console.log('Required Field Errors');
            errors++;
        }
        if(passwordMatchErrors){
            console.log('Password Match Errors');
            errors++;
            generalErrorOverride = this.passwordMatch_fieldError;
        }
        if(emailMatchErrors){
            console.log('Email Match Errors');
            errors++;
            generalErrorOverride = this.emailMatch_fieldError;
        }
        if(googleCaptchaErrors){
            console.log('Google Captcha Errors');
            errors++;
        }
        if(numberLimitErrors){
            console.log('Number Limit Errors');
            errors++;
        }

        if(errors > 1){
            generalErrorOverride = "";
        }

        /* ----- */

        if(errors > 0){
            if($form.attr('id') == 'create_account_form'){
                this.showGeneralError(this.generalCreateAccountError);
            }else{

                if(generalErrorOverride != ""){
                    this.showGeneralError(generalErrorOverride);
                }else{
                    this.showGeneralError(this.generalError);
                }
            }


            if(!$form.parent().hasClass('inner') && !$('html').hasClass('lrb-modalOpen')){
                //Scroll to first error, if not in a modal

                if($('html').hasClass('touch')){
                    //scroll to top on touch
                    global.utils.scrollToTop();
                }else{
                    //scroll to first error field on non-touch
                    var $firstError = $('input.error, select.error, textarea.error').eq(0);
                    global.utils.scrollTo($firstError, 200);
                }
            }

        }else{
            this.hideGeneralError(true);
        }

        return !errors;
    }

    //===============================
    // Check confirm field matches the one it is supposed to
    //===============================
    fieldMatchTest($parent, matchClass, $errorMsg){
        let scope = this;

        if($parent){
            var error = true;

            $parent.find(matchClass).each(function(){

                //setup re-test
                /*if(!$(this).hasClass('lrb-matchTesting')){
                    $(this).addClass('lrb-matchTesting');
                    $(this).change(function(){

                        //scope.fieldMatchTest($parent, $errorMsg);

                    });
                }*/


                var $target = null;
                if($(this).data('match-target')){
                    $target = $('.'+$(this).data('match-target'));
                }

                if($target){
                    if($(this).val() === $target.val()){
                        error = false;
                    }else{
                        $(this).addClass('error');
                        $target.addClass('error');
                        scope.showFieldError($(this), $errorMsg);
                    }
                }

            });

            if(error){
                return false;
            }else{
                return true;
            }

        }else{
            console.log('[Validation Issue] fieldMatchTest - no parent set');
            return false;
        }
    }

    //===============================
    // Check Google Recaptcha field completed
    //===============================
    googleRecaptchaCompleteCheck($parent){
        if(this.disableGoogleRecaptchaCheck){
            return true;
        }

        let scope = this;

        if($parent && $parent.find('.g-recaptcha').length > 0){

            if($parent.find('.g-recaptcha').find("#g-recaptcha-response").val() == ""){
                scope.showFieldError($parent.find('.g-recaptcha'), scope.googleRecaptcha_fieldError);
                return false;
            }else{
                return true;
            }

        }else{
            console.log('[Validation Issue] googleRecaptchaCompleteCheck - target not found');
            console.log($parent);
            return true;
        }
    }

    //===============================
    // Check fields with number limit are blank or numerical only, and not containing anything other than numbers
    //===============================
    numberLimitTest($parent){
        let scope = this;

        if($parent){
            var error = false;
            $parent.find('input[data-numberlimit]').each(function(){
                var valid = true;
                var val = $(this).val();

                // skip validation is field is blank
                if(val != ''){
                    // check if is numerical
                    if(!$.isNumeric(val)){
                        valid = false;
                    }else{

                        // check is within max limits
                        var limit = parseInt($(this).data('numberlimit'));
                        if(limit && limit > 0){
                            if(val.length > limit){
                                valid = false;
                            }
                        }

                        // check is within optional min limits
                        if($(this).data('numberlimit-min') != ''){
                            var min = parseInt($(this).data('numberlimit-min'));
                            if(min && min > 0){
                                if(val.length < min){
                                    valid = false;
                                }
                            }
                        }

                    }
                }

                if(!valid){
                    error = true;
                    $(this).addClass('error');
                    var message = scope.invalidNumberLimit_fieldError;
                    if($(this).data('numberlimit-message') != ''){
                        message = $(this).data('numberlimit-message');
                    }
                    scope.showFieldError($(this), message);
                }
            });

            if(error){
                return false;
            }else{
                return true;
            }

        }else{
            console.log('[Validation Issue] numberLimitTest - no parent set');
            return false;
        }
    }

    //===============================
    // Check email fields are valid emails
    //===============================
    emailTest($parent){
        let scope = this;

        if($parent){
            var error = false;
            $parent.find('input[type="email"]').each(function(){
                var valid = scope.isValidEmailAddress($(this).val());

                if(!valid){
                    error = true;
                    $(this).addClass('error');
                    scope.showFieldError($(this), scope.invalidEmail_fieldError);
                }
            });

            if(error){
                return false;
            }else{
                return true;
            }

        }else{
            console.log('[Validation Issue] emailTest - no parent set');
            return false;
        }
    }

    isValidEmailAddress(emailStr) {
        if(emailStr && emailStr != ""){
            
            /*
            [a-z0-9$&'+/=?^_`{|}~-]+            // Local part of the email: letters, digits, and certain special characters
            (?:                                 // Non-capturing group for domain segments
                \.[a-z0-9$&'+/=?^_`{|}~-]+      // Dot followed by more local part characters
            )*                                  // Zero or more occurrences of the domain segment group
            @                                   // @ symbol separating local part from domain part
            (?:                                 // Non-capturing group for domain
                [a-z0-9]                        // First character of domain
                (?:                             // Non-capturing group for domain segments
                    [a-z0-9-]*                  // Optional additional characters in domain
                    [a-z0-9]                    // Last character of domain
                )?                              // Optional domain segment
                \.                              // Dot separating domain segments
            )+                                  // One or more occurrences of the domain segment group
            [a-z0-9]                            // First character of top-level domain (TLD)
            (?:                                 // Non-capturing group for TLD segments
                [a-z0-9-]*                      // Optional additional characters in TLD
                [a-z0-9]                        // Last character of TLD
            )?                                  // Optional TLD segment
            /i                                  // Case insensitive matching
            */
            const pattern = /[a-z0-9$&'+/=?^_`{|}~-]+(?:\.[a-z0-9$&'+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
            
            var valid = pattern.test(emailStr);
            return valid;
            
        }else{
            //blank, as we dont want to flag this as an invalid email address
            return true;
        }
    };

    //===============================
    // Check required fields are filled in
    //===============================
    requiredTest($parent){
        let scope = this;

        if($parent){

            var error = false;
            $parent.find('input, textarea, select').each(function(){
                var validateThis = false;
                if($(this).attr('required')){
                    //A required field, flag for validation
                    validateThis = true;
                }

                if($(this).hasClass('forceRequired')){
                    //A field with fake validation (i.e. on account subscription DSB form)
                    //Check if this is a visible / active field, and if so then flag for validation
                    console.log();
                    if($(this).is(":visible")){
                        validateThis = true;
                    }else{
                    }
                }

                if(validateThis){
                    var isUncheckedCheckbox = false;
                    var isOnlySpaces = false;
                    var emptyArray = false;

                    if($(this).attr('type') == "checkbox"){

                        // Check for unchecked checkboxes
                        if($(this).prop('checked') == false){
                            isUncheckedCheckbox = true;
                        }

                    }else{


                        if($(this).val()){
                            if(Array.isArray($(this).val())){

                                if($(this).val().length == 0){
                                    emptyArray = true;
                                }

                            }else{

                                // Check for space only values
                                var amendedVal = $(this).val().replace(/ /g,'');
                                if(amendedVal == ""){
                                    isOnlySpaces = true;
                                }

                            }
                        }

                    }

                    if(!$(this).val() || isUncheckedCheckbox || isOnlySpaces || emptyArray){
                        $(this).addClass('error');
                        error = true;
                        var errorMsg = scope.required_fieldError;
                        try{
                            if($(this).data('required-error')){
                                errorMsg = ""+$(this).data('required-error');
                            }
                        }catch(e){
                            console.warn("Invalid 'required-error' set on required field");
                        }
                        scope.showFieldError($(this), errorMsg);
                    }
                }
            });

            if(error){
                return false;
            }else{
                return true;
            }

        }else{
            console.log('[Validation Issue] requiredTest - no parent set');
            return false;
        }
    }

    //===============================
    // This allows for fields to control disabled states of other fields according to selected values
    // set according to data values on a field; the disabler:
    // e.g.
    // 'data-spec-disabled' => '#appSupport_softwareVersionApple, #appSupport_softwareVersionAndroid', 'data-spec-enabledvals' => '[["3","6"],["4","7"]]'
    // would disable #appSupport_softwareVersionApple unless an option with value 3 or 6 is selected on the disabler, and
    // would disable #appSupport_softwareVersionAndroid unless an option with value 4 or 7 is selected on the disabler
    //===============================
    setupSpecificDisabledFields($parent){
        let scope = this;

        console.log("Setting up Specific Disabler Fields");
        $parent.find('.lrb-specific-disabler').each(function(){
            var $disabler = $(this);
            if($disabler){

                //Each disabler can control multiple fields, grab them all and setup
                var $disabledFields = $($disabler.data('spec-disabled'));
                var enabledVals = $disabler.data('spec-enabledvals');

                if($disabledFields && enabledVals){

                    $disabledFields.each(function(i){

                        var $disabled = $(this);
                        var enVals = enabledVals[i];

                        scope.checkDisablerVal($disabler, $disabled, enVals);

                    });

                    $disabler.on('change', function(){
                        $disabledFields.each(function(i){
                            var $disabled = $(this);
                            var enVals = enabledVals[i];

                            scope.checkDisablerVal($disabler, $disabled, enVals);

                        });
                    });

                }else{
                    console.error('Disabler Field missing required data');
                    global.LRBErrorTracking.captureMessage('Disabler Field missing required data');
                }

            }

        });
    }
    checkDisablerVal($disabler, $disabled, enabledVals){
        if($disabler && $disabled && enabledVals){

            var $disParent = $disabled.parent();
            if($.inArray($disabler.val(), enabledVals) > -1){
                //value matched, enable other field
                $disParent.removeClass('disabled');
                $disParent.slideDown();
            }else{
                //value not matched, disable other field
                $disParent.addClass('disabled');
                $disParent.slideUp();
                $disabled.val(null).trigger("change");
            }

        }else{
            console.error('Disabler Field missing required data');
            global.LRBErrorTracking.captureMessage('Disabler Field missing required data');
        }
    }

    destroy(){
    }

}

module.exports = { LRBFormValidation };
