const fitvids = require("fitvids");

class LRBVideo {
    constructor() {
    }

    setup() {
        console.log('%cLRBVideo Setup', `background-color: ${global.infoBG};`);

        try {
            this.scaleHTMLVideos();
            this.scaleVideoHolders();
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Video',error);
        }
    }

    scaleHTMLVideos(){
        fitvids('.ezmedia-field');
    }

    scaleVideoHolders(){
        fitvids('.ezrichtext-field iframe');
        fitvids('.video-holder');
    }

}

module.exports = { LRBVideo };
