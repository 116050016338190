var typogr = require('typogr');

class LRBTypography {
    constructor() {
        this.typogrified = false;

        this.autoSplitLink_1 = '<a href="#Read-More" class="lrb-readmorelink" data-readmore-target="';
        this.autoSplitLink_2 = '"></a>';
    }

    setup() {
        console.log('%cLRBTypography Setup', `background-color: ${global.infoBG};`);
    }

    //==========================
    // Setup auto split text elements (splits out first paragraph and adds readmore link)
    //==========================
    setupAutoSplit(){
        // global.LRBSentry.captureMessage('TM:2 setupAutoSplit');
        let scope = this;

        if($('.lrb-autosplit').length > 0){
            console.log("[Setup Auto Split]");

            $('.lrb-autosplit:not(.lrb-t-sas) .ezrichtext-field').children().unwrap();
            $('.lrb-autosplit:not(.lrb-t-sas)').each(function(){

                $(this).addClass('lrb-t-sas');

                var elClassList = "."+$(this).attr('class').split(" ").join(".");
                if($(this).children().length > 1){
                    var readMoreLink = scope.autoSplitLink_1 + elClassList + " .lrb-autosplit-hide" + scope.autoSplitLink_2;

                    $(this).children().not(':first-child').wrapAll('<div class="lrb-autosplit-hide"></div>');
                    $(this).after(readMoreLink);
                }else{
                    console.warn("[Auto Split] Skipping: only one child element of "+elClassList);
                }

            });

        }
    }

    //==========================
    // Clean up typography
    //==========================
    cleanUpTypography(){
        // global.LRBSentry.captureMessage('TM:3 setupAutoSplit');
        try{window.performance.mark('cleanup_typography_start');}catch(e){}

        this.cleanupTitles();
        this.cleanupPullquotes();
        this.cleanupSitemapHeader();
        this.cleanupTOC();
        this.cleanupForms();

        this.typogrify();

        this.cleanupTypeFurniture();
        this.cleanupArticleCopy();
        this.cleanupEllipses();
        this.cleanupEmojis();
        this.cleanupRedactedDisplay();
        this.markupDropcapParagraphs();

        try{window.performance.mark('cleanup_typography_end');}catch(e){}
        this.typogrified = true;
    }

    typogrify(){
        //Typogrify
        $('.typogrify:not(.lrb-t-t), .smartquotes:not(.lrb-t-t), .article-reviewed-item-title:not(.lrb-t-t), .article-reviewed-item-subtitle:not(.lrb-t-t), .toc-item span.by:not(.lrb-t-t)').each(function(){
            if($(this) && $(this).html()){
                $(this).addClass('lrb-t-t').html(typogr($(this).html()).chain().caps().amp().initQuotes().smartypants().ord().value());
            }
        });

        //Auto capitalise cases of LRB caps
        $('.caps:not(.lrb-auto-caps)').each(function(){
            if($(this).html() == "LRB"){
                $(this).addClass('lrb-auto-caps');
            }
        });
    }

    cleanupForms(){
        //Set label required field, and add class to matching field
        $('label').each(function(){
            if($(this).html().split('[*]').length > 1){
                $(this).html($(this).html().replace("[*]", '<span class="required-marker">*</span>'));
                $('#'+$(this).attr('for')).addClass('forceRequired');
            }
        });
    }

    cleanupTOC(){
        // Strip out item-meta from TOC listings so that typogrify will work and wrap widows
        $('.toc-item span.by .item-meta').remove();
        // Clean up trailing commas after item-meta removal
        $('.toc-item span.by:not(.lrb-t-ctoc)').each(function(){
        $(this).addClass('lrb-t-ctoc').text($(this).text().replace(/,\s*$/, ""));
        });

        //Clean out unwanted br tags from 'contributor-list--item > article-reviewed-item'
        $('.contributor-list--item .article-reviewed-item br').remove();

        //Set Symposium People names to not break over lines
        $('.symposium-people a:not(.lrb-t-ctoc)').each(function(){
            $(this).addClass('lrb-t-ctoc').html($(this).html().replace(" ", "&nbsp;"));
        });

        //Ensure TOC items have full-stops removed
        $('.toc-item .article-reviewed-item-title:not(.lrb-t-ctocfs), .toc-item .article-reviewed-item .by:not(.lrb-t-ctocfs)').each(function(){

            $(this).addClass('lrb-t-ctocfs');

            var str = $(this).html();
            //remove trailing spaces
            if (str[str.length-1] === " "){
                str = str.slice(0,-1);
            }
            //remove fullstop if it exists
            if (str[str.length-1] === "."){
                str = str.slice(0,-1);
            }

            //check last characters before i/meta tags
            if(str.split("<i").length > 1){
                var splitString = str.split("<i");
                //remove trailing spaces
                if (splitString[0][splitString[0].length-1] === " "){
                    splitString[0] = splitString[0].slice(0,-1);
                }
                //remove fullstop if it exists
                if (splitString[0][splitString[0].length-1] === "."){
                    splitString[0] = splitString[0].slice(0,-1);
                }

                str = splitString.join('<i');
            }

            $(this).html(str);
        });
    }

    cleanupSitemapHeader(){
        $('.sitemap-header:not(.lrb-t-csh)').each(function(){
            $(this).addClass('lrb-t-csh');
            $(this).html($(this).html().replace(/London Review of Books/g,"<em>London&nbsp;Review&nbsp;of&nbsp;Books</em>"));
            $(this).html($(this).html().replace(/<em><em>/g,"<em>"));
            $(this).html($(this).html().replace(/<\/em><\/em>/g,"</em>"));
        });
    }

    cleanupArticleCopy(){
        //Cleanup spaces in dropcaps
        $('#lrb-articleCopy .smallcapslede:not(.lrb-t-cac)').each(function(){
            $(this).addClass('lrb-t-cac').html($(this).html().replace("&nbsp;", "<span class='nbsp'>&nbsp;</span>"));
        });

        //Cleanup author names in Article title overlay
        $('.article-title-overlay .article-title-overlay--title .authorName a:not(.lrb-t-cac)').each(function(){
            $(this).addClass('lrb-t-cac').html($(this).html().replace(' ','&nbsp;'));
        });

        // Fix unnamed videos
        $('.article-videos iframe:not(.lrb-t-cac)').each(function(){
            $(this).addClass('lrb-t-cac');
            if($(this).attr('title') == undefined || $(this).attr('title') == ""){
                $(this).attr('title', 'Article Video');
            }
        });
    }

    cleanupRedactedDisplay(){
        //Adds a redacted placeholder with text '[redacted]' before the visually redacted element, and hides this
        //This means if a user copies and pastes text they get '[redacted]' rather than nothing
        $('#lrb-articleCopy p:not(.lrb-t-r), h1:not(.lrb-t-r), h2:not(.lrb-t-r), h3:not(.lrb-t-r), h4:not(.lrb-t-r), h5:not(.lrb-t-r), h6:not(.lrb-t-r)').each(function(){
            $(this).addClass('lrb-t-r').html($(this).html().replace(/<span class="redacted">/g, '<span class="redacted-placeholder">[redacted]</span><span class="redacted">'));
        });
    }

    cleanupTitles(){
        //Wrap ampersands, Cleanup quotes,
        //Run smartypants (Straight quotes, Backticks-style quotes, Dashes, Ellipses),
        //Wrap number suffixes
        $('h1,h2,h3,h4,h5,h6').each(function(){
            //Cleanup erroneous double single quotes in titles
            $(this).html($(this).html().replace(/‘‘/g,'‘'));
            $(this).html($(this).html().replace(/’’/g,'’'));

            $(this).html(typogr($(this).html().trim()).chain().caps().amp().initQuotes().smartypants().ord().value());
        });
        $('.lrb-arrowlink').each(function(){
            $(this).html($(this).html().replace(/LRB/g,'<em>LRB</em>'));
        });

        //check for easy/safe widow fixes:
        //if second to last word is 1 - 2 chars then add non breaking space
        //checks for wrapping a tag within title
        $('.cleanTitles').find('h1:not(.lrb-t-ct),h2:not(.lrb-t-ct),h3:not(.lrb-t-ct),h4:not(.lrb-t-ct),h5:not(.lrb-t-ct),h6:not(.lrb-t-ct), h1 a:not(.lrb-t-ct), h2 a:not(.lrb-t-ct), .standfirst:not(.lrb-t-ct), .grid-item:not(.lrb-t-ct)').each(function(){
            $(this).addClass('lrb-t-ct');
            var hWords = $(this).html().trim().split(" ").filter(Boolean);
            //only proceed for titles longer than 3 words
            if(hWords.length > 3){
                var end = hWords.pop();
                if(end == "</a>" || end == "</span>"){
                    //last word is a link tag, grab next
                    var realEnd = hWords.pop();
                    //if previous word is 1 - 2 chars, then de-widow
                    if(hWords[hWords.length - 1].length < 3){
                        $(this).html(hWords.join(' ') + "&nbsp;"+realEnd+end);
                    }
                }else{
                    //if previous word is 1 - 2 chars, then de-widow
                    if(hWords[hWords.length - 1].length < 3){
                        $(this).html(hWords.join(' ') + "&nbsp;"+end);
                    }
                }
            }
        });

        //Swap title hyphens to non-breaking hyphens
        $('h1 .ezstring-field:not(.lrb-t-cth),h2 .ezstring-field:not(.lrb-t-cth),h3 .ezstring-field:not(.lrb-t-cth),h4 .ezstring-field:not(.lrb-t-cth),h5 .ezstring-field:not(.lrb-t-cth),h6 .ezstring-field:not(.lrb-t-cth)').each(function(){
            $(this).addClass('lrb-t-cth');
            var words = $(this).html().split(" "), i=0;
            var amended = false;
            for (i; i < words.length; i++) {
                if(words[i].indexOf('-')>0 || words[i].indexOf('-')>0){
                    words[i] = '<span class="hyphenated">'+words[i]+'</span>';
                    amended = true;
                }
            }
            if(amended){
                $(this).html(words.join(' '));
            }
        });
    }

    cleanupPullquotes(){
        $('.pullquote-block .dropcap:not(.lrb-t-cp), .subject-pullquote .dropcap:not(.lrb-t-cp), .subject-more .block-item .dropcap:not(.lrb-t-cp), .pullquote-block .dropcaps:not(.lrb-t-cp), .subject-pullquote .dropcaps:not(.lrb-t-cp), .subject-more .block-item .dropcaps:not(.lrb-t-cp), .pullquote-block .smallcapslede:not(.lrb-t-cp), .subject-pullquote .smallcapslede:not(.lrb-t-cp), .subject-more .block-item .smallcapslede:not(.lrb-t-cp)').each(function(){
            $(this).addClass('lrb-t-cp').contents().unwrap();
        });
    }

    cleanupTypeFurniture(targetEl = null){
        if(targetEl){
            this.cleanupEllipses(targetEl);
        }
        this.cleanupDates();
    }

    cleanupDates(){
        $('.cleanDate:not(.lrb-t-cd)').each(function(){
            $(this).addClass('lrb-t-cd');

            //Remove triling :00 from times in dates
            $(this).html($(this).html().replace(":00",""));

            //Remove widows on event dates
            $(this).html(typogr($(this).html()).chain().widont().value());

            $(this).html($(this).html().replace("pm","&nbsp;p.m."));
        });
    }

    cleanupEmojis(){
        var content = $('.lrb-content-container:not(.lrb-t-e)');
        if(content.length > 0){
            const emojiRegex = /\p{Extended_Pictographic}/ug;
            content.addClass('lrb-t-e');
            if(emojiRegex.test($('.lrb-content-container').text())){
                // Emojis found in content container, load Emoji parsing library and convert to images
                try{

                    // Load Emoji Parser library
                    var a = document.createElement("script");
                    a.type = "text/javascript";
                    a.async = true;
                    a.crossOrigin = 'anonymous';
                    a.src = "https://cdn.jsdelivr.net/npm/twemoji@14.0.2/dist/twemoji.min.js";

                    // Handle errors to cope with CDN issues
                    a.onerror = function(){
                        console.error('Failed to load Emoji Parser');
                    }

                    // Once loaded parse emoji characters to PNG
                    a.onload = function(){

                        try{
                            twemoji.parse(
                                $('.lrb-content-container')[0],
                                { base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/' }
                            );
                        }catch(e){
                            console.error(e);
                        }

                    }

                    // Add script to page
                    var b = document.getElementsByTagName("script")[0];
                    b.parentNode.insertBefore(a, b);

                }catch(e){
                    console.error(e);
                }
            }
        }
    }

    markupDropcapParagraphs(){
        $('.article-copy p:not(.lrb-t-mdp)').each(function(){
            $(this).addClass('lrb-t-mdp');
            if($(this).find('.dropcaps, .dropcap').length > 0){
                $(this).addClass('has-dropcap');
            }
        });
    }

    cleanupEllipses(targetEl = '.typogrify, .ellipsis, h1,h2,h3,h4,h5,h6, #lrb-blog-prevpost .pullquote, #lrb-blog-nextpost .pullquote'){
        const segments = targetEl.split(',');
        const resultSegments = segments.map(segment => `:not(.lrb-t-ce)${segment.trim()}`);
        targetEl = resultSegments.join(',');

        global.LRBErrorTracking.setContext('LRBTypography cleanupEllipses');

        //Swap ellipsis for and spaced dots for correctly LRB formatted spaced dots
        if(targetEl && $(targetEl).length > 0){
            $(targetEl).each(function(){

                $(this).addClass('lrb-t-ce');
                var chunk = $(this).html();
                // The order of these is important, be cautious when editing please
                // Replace various versions of ellipses to ...

                //concatenated version of 4 lines below
                chunk = chunk.replace(/&hellip;|\u2026|…|<span class="ellipsis">.&nbsp;.&nbsp;.<\/span>/g,"...");

                /*$(this).html($(this).html().replace(/&hellip;/g,"..."));
                $(this).html($(this).html().replace("\u2026","..."));
                $(this).html($(this).html().replace(/…/g,"..."));
                $(this).html($(this).html().replace('<span class="ellipsis">.&nbsp;.&nbsp;.</span>',"..."));*/


                // Cleanup preceeding punctuation before ...

                //concatenated version of 10 lines below
                chunk = chunk.replace(/( |&nbsp;+|,|;|\.( )?)\.\.\./g,"...");

                /*$(this).html($(this).html().replace(/ \.\.\./g,"..."));
                $(this).html($(this).html().replace(/&nbsp;\.\.\./g,"..."));
                $(this).html($(this).html().replace(/&nbsp;&nbsp;\.\.\./g,"..."));
                $(this).html($(this).html().replace(/,\.\.\./g,"..."));
                $(this).html($(this).html().replace(/;\.\.\./g,"..."));
                $(this).html($(this).html().replace(/\. \.\.\./g,"..."));
                $(this).html($(this).html().replace(/\.\.\.\./g,"..."));*/


                // Doublecheck and reformat to correct spacing
                /*$(this).html($(this).html().replace(/ \.\.\./g,"..."));
                $(this).html($(this).html().replace(/&nbsp;\.\.\./g,"..."));
                $(this).html($(this).html().replace(/&nbsp;&nbsp;\.\.\./g,"..."));*/

                // Format to LRB house style ellipsis
                chunk = chunk.replace(/\.\.\./g,"<span class='ellipsis'><nobr>&nbsp;<span class='ellipsis-dot'>.</span><span class='ellipsis-dot'>.</span><span class='ellipsis-dot'>.</span></nobr></span>");
                $(this).html(chunk);
            });
        }

        global.LRBErrorTracking.setPageContext();
    }

}

module.exports = { LRBTypography };
