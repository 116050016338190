class LRBUnlinkedDropdown {
    constructor() {
    }

    setup() {
        console.log('%cLRBUnlinkedDropdown Setup', `background-color: ${global.infoBG};`);

        try {
            $('.lrb-unlinked-dropdown-btn').each(function(){
    
                $(this).on('click',function(e){
                    e.preventDefault();
                    e.stopPropagation();
    
                    if($(this).hasClass('force-hover')){
                        $('.lrb-unlinked-dropdown-content').velocity('stop').velocity("slideUp", {duration: 300}).velocity("fadeOut", {duration: 301, queue: false});
                        $('.lrb-unlinked-dropdown-btn').removeClass('force-hover');
                    }else{
                        if($(this).data('unlinked-dropdown') && $(this).data('unlinked-dropdown') != ""){
                            $('.lrb-unlinked-dropdown-content').velocity('stop').velocity("slideDown", {duration: 400}).velocity("fadeIn", {duration: 800, queue: false});
                            $(this).addClass('force-hover');
                        }
                    }
    
                    return false;
                });
    
            });
    
            $('.lrb-unlinked-dropdown-close').on('click',function(e){
                e.preventDefault();
                e.stopPropagation();
    
                $('.lrb-unlinked-dropdown-content').velocity('stop').velocity("slideUp", {duration: 300}).velocity("fadeOut", {duration: 301, queue: false});
                $('.lrb-unlinked-dropdown-btn').removeClass('force-hover');
    
                return false;
            });
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Unlinked Dropdown',error);
        }

    }

    destroy(){
    }

}

module.exports = { LRBUnlinkedDropdown };
