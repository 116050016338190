class LRBLetters {
    constructor() {
        window.filters = null;
        window.currentIssue = null;
        window.currentYear = null;
        window.setupLettersNav = this.setupLettersNav;
        window.loadIssues = this.loadIssues;
    }

    setup(){}

    setupLettersNav(filters, currentYear, currentIssue) {

        try {
            if($('.lettersnav').length > 0){
                console.log('%cLRBLetters Setup', `background-color: ${global.infoBG};`);
    
                window.filters = filters;
                window.currentIssue = currentIssue;
                window.currentYear = currentYear;
    
                window.loadIssues(currentYear);
    
    
                $("#yearMenu").on('change', function(){
                    window.loadIssues($(this).val());
                });
    
                $("#issuesMenu").on('change', function(){
                    location.href = $(this).val();
                });
    
            }
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up LettersNav',error);
        }
    }

    loadIssues(year){
		var html = '<option value="" disabled>Issue</option>';
		$.each(window.filters[year], function (index, value) {
			var selected = '';
			if(value.number == window.currentIssue){
				selected= ' selected';
			}
			html+='<option value="'+value.url+'/letters"'+selected+'>'+value.label+'</option>';
		});
		$("#issuesMenu").html(html).removeAttr('disabled');
	}

    destroy(){
    }

}

module.exports = { LRBLetters };
