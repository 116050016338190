const GA_ENABLED = true;
const SEND_GA_TRACKING = true;

// Google Tag Manager ID
const GOOGLE_TRACKING_ID_TEST = "UA-134082977-1";
const GOOGLE_TRACKING_ID_LIVE = "UA-186611-1";

class GoogleGTag {
    constructor() {

        // Stored dimensions to pass to GA when it's initialised
        window.ga_dimensions = {'anonymize_ip': true, 'cookie_prefix': 'lrb', 'groups': 'UA'};
		// Stored goals to pass to GA when it's initialised
        window.ga_goals = {};

        window.addGADimension = this.addDimension;
        window.setGAUserID = this.setUserID;
        // Temporarily disable whilst implementing GA4
        //window.gtag = this.gtag;
        window.trackingID = GOOGLE_TRACKING_ID_TEST;

        window.gaSSD = this.setSessionDimensions;
        window.gaJson = this.getJSONSessionData;
        window.gCID = this.getClientID;

        window.ga_setup = false;
        window.ga_failed = false;
    }

    setup() {
        global.LRBErrorTracking.setContext('GTag setup');

        if(!GA_ENABLED){
            return false;
        }
        console.log('%cGoogleGTag Setup', `background-color: ${global.infoBG};`);

        if(process.env.NODE_ENV === "production" || process.env.NODE_ENV === "prod"){
            window.trackingID = GOOGLE_TRACKING_ID_LIVE;
        }

        // Check for ID overrides from ENV Vars - CommonComponentsController > googleIDsAction
        if(window.GAID && window.GAID != ""){
            window.trackingID = window.GAID;
        }

        var gtagScript = document.createElement("script");
        gtagScript.type = "text/javascript";
        gtagScript.setAttribute("async", "true");
        gtagScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id="+window.trackingID);
        gtagScript.onerror = function(){
            window.ga_failed = true;
            console.error('Failed to load GA Scripts');
        }

        // Temporarily disable whilst implementing GA4
        //document.head.appendChild(gtagScript);

        global.LRBErrorTracking.setPageContext();
    }

    // Set a USER-ID to associate multiple sessions with the same logged in user, before GA is initialised
    setUserID(userID){
        if(userID){

            if(window.ga_setup){
                console.error('GTag setUserID: Cannot set UserID, GTag already initialised');
                return false;
            }

            window.ga_dimensions.user_id = userID;

        }else{
            console.warn('GTag setUserID: No ID set');
        }
    }

    // Add a dimension to the store, before GA is initialised
    addDimension(dimension, value){
		if(window.ga_dimensions.length == 20){
			console.error('GTag addDimension: Cannot add dimension, limit reached: '+window.ga_dimensions);
            return false;
		}

        if(dimension && value){

            if(window.ga_setup){
                console.error('GTag addDimension: Cannot add dimension, GTag already initialised - '+dimension);
                return false;
            }

            if(window.ga_dimensions[dimension]){
                console.warn('GTag addDimension: Overwriting Dimension - '+dimension);
            }

            console.warn('GTag Adding Dimension: '+dimension+" : "+value);
            window.ga_dimensions[dimension] = value;

        }else{
            console.warn('GTag addDimension: No Dimension set');
        }
    }

    // Track an event, after GA is initialised
    trackEvent(action, category, label, value, nonInteraction = false, debug = false){
        if(!window.ga_setup){
            console.error('GTag not setup, event not tracked: '+ action);
        }else{
            if(action){

                var eventVals = {'send_to': 'UA'};
                if(category){
                    eventVals.event_category = category;
                }
                if(category){
                    eventVals.event_label = label;
                }
                if(category){
                    eventVals.value = value;
                }
                if(nonInteraction){
                    eventVals.non_interaction = true;
                }

                if(debug){
                    eventVals.debug = true;
                }

                console.warn("GTagEvent: " +action+" - "+category+", "+label+", "+value);
                try{
                    window.gtag('event', action, eventVals);
                }catch(e){
                    console.log('Error sending GA event: '+action);
                    console.error(e);
                }

            }else{
                console.error('GTag trackEvent: no action specified');
            }
        }
    }

    // Track a dimension, after GA is initialised
    trackDimension(dimension, value, nonInteraction = true, eventName = "custom_dimension"){
        if(!window.ga_setup){
            console.error('GTag not setup, late dimension not tracked: '+ dimension);
        }else{
            if(dimension && value){

                console.warn("GTagLateDimension: " +dimension+" - "+value);
                try{
                    window.gtag('send', eventName, {dimension: value, 'non_interaction': nonInteraction});
                }catch(e){
                    console.error(e);
                }

            }else{
                console.error('GTag trackDimension: no data specified');
            }
        }
    }

    // Dimensions added based on data from session data
    setSessionDimensions(){
        global.LRBErrorTracking.setContext('GTag setSessionDimensions');

        let data = global.LRBSessionData.sessionData;
        if(data){

            if (data.hasOwnProperty('paywall')) {
                    // dimension2 - referred to as 'Article Access' in GA label
                    // Article Access - e.g. full/subs/free/hide
                    window.addGADimension('dimension2', data.paywall.article_access);

                    if (data.paywall.hasOwnProperty('disabled')) {
                        if (data.paywall.disabled === true) {
                            // dimension3 - referred to as 'Paywall' in GA label
                            // Paywall is set to disabled, send this immediately
                            // If paywall is enabled then paywall display is sent as separate event
                            window.addGADimension('dimension3', 'No Paywall');
                        }
                    }

                    // dimension4 - referred to as 'Sub System' in GA label
                    // Sub System - e.g. DSB/PCD/REG/DISC
                    window.addGADimension('dimension4', data.paywall.sub_system);
                }

                if (data.hasOwnProperty('institutional_access')) {
                    // dimension5 - referred to as 'Institution Name' in GA label
                    // Institution Name - e.g. London Review of Books
                    window.addGADimension('dimension5', data.institutional_access.ga_organisation);
                }

        }else{
            console.log('GTag: No session data');
            //global.LRBErrorTracking.captureMessage('GTag: No session data',LRBErrorTracking.LEVEL.ERROR,'production',true);
        }
        global.LRBErrorTracking.setPageContext();
    }

    // Initialise GA Tracking, using stored dimensions etc
    initialiseGATagTracking(){

        global.LRBErrorTracking.setContext('GTag initialiseGATagTracking');

        var sendRedactedPageview = false;

        // Check if URL matches specific pages we want to sanitise
        if( window.location.pathname.includes("password-sent") ||
            window.location.pathname.includes("reset-password") ||
            window.location.pathname.includes("password")
        ){
            sendRedactedPageview = true;
        }

        // Check if URL contains PII in URL params
        if( window.location.search.indexOf('@') > -1 ||
            global.utils.getURLParam("fname") != "" ||
            global.utils.getURLParam("lname") != "" ||
            global.utils.getURLParam("email") != ""
        ){
            sendRedactedPageview = true;
        }

        // setSessionDimensions
        window.gaSSD();

        if(SEND_GA_TRACKING && !window.ga_failed){
            if(window.ga_setup){
                console.error('GTag already setup, skipping');
                return false;
            }
            console.warn('GTag Initialising Tracking');

            if(sendRedactedPageview){
                // [Customised Pageview Event]:
                // To avoid sending PII from URL params etc; first, flag to not send pageview
                window.addGADimension('send_page_view', false);
                // Secondly, send a manual pageview event
                window.addGADimension('page_title', document.title);
                window.addGADimension('page_path', window.location.pathname);
                console.warn("GTag Not Tracking Pageview due to PII content on this page");
            }

            // Add cross domain tracking
            var domains = {'domains': [
                'mylrb.com',
                'mylrb.co.uk'
            ]};
            window.addGADimension('linker', domains);

            // Default GA Pageview Event etc
            window.gtag('js', new Date());
            // Update referrer from URL passed through session redirect
            if(window.lrb_referrer && window.lrb_referrer != undefined && window.lrb_referrer != ""){
                window.gtag('set', {'referrer': window.lrb_referrer});
                try{
                    Object.defineProperty(document, "referrer", {get : function(){ return window.lrb_referrer; }});
                }catch(e){
                    global.LRBErrorTracking.setContext('GA Session Referral');

                    console.error("Session Referral Passthrough Fail: "+e);
                    global.LRBErrorTracking.captureMessage('Session Referral Passthrough Fail');

                    global.LRBErrorTracking.setPageContext();
                }
            }

            global.LRBErrorTracking.setContext('GTag initialiseGATagTracking');

            // Trigger GA
            window.gtag('config', window.trackingID, window.ga_dimensions);
            window.ga_setup = true;

            global.LRBTracking.logSessionData();

            // This was a session referral redirect, cleanup the URL after tracking is done
            if(window.lrb_referrer && window.lrb_referrer != undefined && window.lrb_referrer != ""){
                global.utils.updateURL(window.location.pathname);
            }

        }else{
            if(window.ga_failed){
                global.LRBErrorTracking.setContext('GA Tracking');

                console.error('Cannot load Tracking, likely due to script blockers');
                //global.LRBErrorTracking.captureMessage('Cannot load Tracking, likely due to script blockers',LRBErrorTracking.LEVEL.INFO,'production',true);

                global.LRBErrorTracking.setPageContext();
            }
            console.warn('GTag Not Initialising Tracking:: No events/tracking will be sent to GA');
        }

        global.LRBErrorTracking.setPageContext();
    }

    // Temporarily disable whilst implementing GA4
    /*gtag(){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(arguments);
    }*/

    getClientID(){
        global.LRBErrorTracking.setContext('GTag getClientID');

        var cID = false;
        try{
            if(ga && ga.getAll){
                cID = ga.getAll()[0].get('clientId');

                var trackers = ga.getAll();
                var i, len;
                for (i = 0, len = trackers.length; i < len; i += 1) {
                    if (trackers[i].get('trackingId') === window.trackingID) {
                        cID = trackers[i].get('clientId');
                    }
                }
            }else{
                console.warn("GAgetJSONSessionData: Could not access GA");
            }
        }catch(e){
            console.warn("GAgetJSONSessionData: Could not get GA clientID");
            //global.LRBErrorTracking.captureMessage('Could not get GA clientID', LRBErrorTracking.LEVEL.ERROR, 'production');
        }

        global.LRBErrorTracking.setPageContext();
        return cID;
    }

    // Exports required tracked session data and returns JSON object
    // to be passed along with a form in hidden field for reporting
    getJSONSessionData(){
        if(!GA_ENABLED){
            return false;
        }else{
            var data = {
                'setup': window.ga_setup,
                'sendTracking': SEND_GA_TRACKING,
                'dataLayer': window.dataLayer,
                'blocked': false
            };
            var clientID = window.gCID();
            if(clientID){
                data.clientId = clientID;
            }

            return data;
        }
    }

    destroy(){
    }

}

module.exports = { GoogleGTag };
