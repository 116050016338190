require('chart.js');
require('chartjs-plugin-deferred');
const hoverintent = require('hoverintent');

class LRBCharts {
    constructor() {
        window.renderUsageChart = this.renderUsageChart;
        window.renderBarChart = this.renderBarChart;
        window.getRandomColour = this.getRandomColour;
        window.keyItemMatchCheck = this.keyItemMatchCheck;
        window.clearKeyMatches = this.clearKeyMatches;

        window.chartCnt = 0;
    }

    setup() {
        console.log('%cLRBCharts Setup', `background-color: ${global.infoBG};`);
    }

    /* Expects graphData as Object formatted:
    {
        dataGroups:[{title:"Jan 2019", data:[20,50,66,30,10,35]},
                    {title:"Feb 2019", data:[100,70,90,30,35,70]},
                    {title:"Mar 2019", data:[30,10,44,90,2,75]}],
        keyTitle: "Key",
        key:[{title:"Contents", colour:"#09355B"},
            {title:"Article", colour:"#1394C4"},
            {title:"Letter", colour:"#1EBC6B"},
            {title:"Biography", colour:"#FFD600"},
            {title:"Test", colour:"#F77062"},
            {title:"Turnaway", colour:"#C28AFE"}],
        xTitle: "Months",
        yTitle: "Usage",
        minVal: "0",
        maxVal: "100"
    }
    */
    renderUsageChart(graphData, target, bgImage){
        if(graphData && target){

            window.chartCnt += 1;

            //Base Rendering
            $(target).addClass('usageGraph-holder clearfix');
            var baseMarkup = $('<div class="usageGraph clearfix"><div class="graph-chunk-holder"><div class="graph-chunks clearfix"></div><div class="graph-y-vals"><div class="axis-max"></div><div class="axis-min"></div></div><div class="graph-y"><div class="axis-title"></div></div></div><div class="graph-x"><div class="col-titles clearfix"></div><div class="axis-title"></div></div><div class="graph-key"><div class="graph-key--title"></div><div class="graph-key--items"></div></div></div>');
            $(target).append(baseMarkup);
            target = target+' .usageGraph';

            //Render Chart BG
            $(target).find('.graph-chunks').css("background-image", "url('"+bgImage+"')");

            //Render Data Groups as Bar Charts
            var i = 0;
            var k = 0;
            var delay = 500;
            var cW = Math.floor(100 / graphData.dataGroups.length);

            var min = graphData.minVal;
            var max = graphData.maxVal;
            if(!max || max == 0 || max === undefined){

                //if max is not set in graphData then calculate from values
                max = 0;
                for (i = 0; i < graphData.dataGroups.length; i++) {
                    for (k = 0; k < graphData.dataGroups[i].data.length; k++) {
                        max = Math.max(max, graphData.dataGroups[i].data[k]);
                    }
                }

                if(max > 100){
                    //round up to nearest 100
                    max += 100 - (max % 100);
                }else{
                    //round up to 100
                }
            }

            for (i = 0; i < graphData.dataGroups.length; i++) {
                var groupData = graphData.dataGroups[i].data;
                var groupTitle = graphData.dataGroups[i].title;

                var canvasEl = $('<div class="canvas-holder" style="width:'+cW+'%;"><canvas></canvas></div>');
                var canvas = canvasEl.find('canvas').get(0).getContext("2d");

                $(target).find('.graph-chunks').append(canvasEl);

                var barChart = window.renderBarChart(groupData, groupTitle, graphData.key, canvas, min, max, delay);

                //on hovering over canvas check to see if there are matches between bars and key items
                var $key = $(target).find('.graph-key');
                $(target).find('canvas').mousemove(function(e){
                    var el = barChart.getElementsAtEvent(e);
                    if(el[0]){
                        window.keyItemMatchCheck($key, el[0]._model.label);
                    }else{
                        window.clearKeyMatches($key);
                    }
                });

                $(target).find('canvas').each(function(){
                    var el = $(this)[0];
                    hoverintent(el,
                        function(){
                            // Nothing
                        }, function(){
                            window.clearKeyMatches($key);
                        }
                    );
                });

                delay += 200;
            }

            for (i = 0; i < graphData.key.length; i++) {
                //Render Key
                var keyItem = $('<div class="graph-key--item"><div class="graph-key--itemCol" style="background-color:'+graphData.key[i].colour+';"></div><div class="graph-key--itemCopy">'+graphData.key[i].title+'</div></div>');
                $(target).find('.graph-key .graph-key--items').append(keyItem);
                $(target).find('.graph-key .graph-key--title').html(graphData.keyTitle);
            }

            //Render X-Axis Title
            var $colTitles = $(target).find('.graph-x .col-titles');
            for (i = 0; i < graphData.dataGroups.length; i++) {
                var colTitle = $('<div class="col-title" style="width:'+cW+'%;"><span>'+graphData.dataGroups[i].title+'</span></div>');
                $colTitles.append(colTitle);
            }
            $(target).find('.graph-x .axis-title').html(graphData.xTitle);

            //Render Y-Axis Title
            $(target).find('.graph-y .axis-title').html(graphData.yTitle);

            //Render min / max Y-Axis vals
            $(target).find('.graph-y-vals .axis-max').html(max);
            $(target).find('.graph-y-vals .axis-min').html(min);

        }else{
            console.warn("No Data for Usage Chart");
            global.LRBErrorTracking.captureMessage('No Data for Usage Chart',LRBErrorTracking.LEVEL.WARNINGS);
        }
    }

    //checks for matching hovered canvas items agains key
    keyItemMatchCheck($key, title = ""){
        if($key){
            if(title != ""){

                var matchFound = false;
                $key.find('.graph-key--item').each(function(){
                    var itemTitle = $(this).find('.graph-key--itemCopy').html();
                    if(itemTitle.toLowerCase() == title.toLowerCase()){
                        //Matched
                        $key.addClass('matched');
                        $(this).addClass('matched');
                        matchFound = true;
                    }
                });

                if(!matchFound){
                    window.clearKeyMatches($key);
                }

            }else{
                window.clearKeyMatches($key);
            }
        }
    }
    clearKeyMatches($key){
        if($key){
            //Unmatched
            $key.removeClass('matched');
            $key.find('.graph-key--item').removeClass('matched');
        }
    }

    renderBarChart(chartData,chartTitle,chartKey,canvas, min = 0, max=100, delay=0){
        if(chartData && chartTitle && chartKey && canvas){
            var colours = [];
            var labelset = [];

            var i = 0;
            for (i; i < chartKey.length; i++){
                colours.push(chartKey[i].colour);
                labelset.push(chartKey[i].title);
            }
            //Add additional random colours in case needed
            for (var i = 0, len = labelset.length - colours.length; i < len; i++) {
                colours.push(window.getRandomColour());
            }

            var enableTooltips = true;

            Chart.defaults.global.legend.display = false;
            Chart.defaults.global.defaultFontFamily = "Ideal Sans";

            var barChart = new Chart(canvas, {
                type: 'bar',
                data: {
                    labels: labelset,
                    datasets: [{
                        backgroundColor: colours,
                        data: chartData,
                        borderWidth: 0,
                        borderSkipped: 'bottom',
                        label: ''
                    }]
                },
                options: {
                    plugins: {
                        deferred: {
                            yOffset: '50%',// defer until 50% of the canvas height are inside the viewport
                            delay: delay
                        }
                    },
                    scales: {
                        xAxes: [{
                            display: false,
                            barPercentage: 1,
                            gridLines: {
                                display: false,
                                drawOnChartArea: false,
                                drawTicks: false,
                                drawBorder: false
                            },
                            ticks:{
                                display: false
                            }
                        }],
                        yAxes: [{
                            display: false,
                            gridLines: {
                                display: false,
                                drawOnChartArea: false,
                                drawTicks: false,
                                drawBorder: false
                            },
                            ticks: {
                                display: false,
                                suggestedMin: min,
                                max: max
                            }
                        }]
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                    aspectRatio: 0.75,
                    layout: {
                        padding: {
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0
                        }
                    },
                    legend: {
                        display: false
                    },
                    title: {
                        display: false
                    },
                    tooltips: {
                        enabled: enableTooltips,
                        xPadding: 10,
                        yPadding: 10,
                        backgroundColor: '#494746',
                        displayColors: false,
                        bodyFontColor: '#fff',
                        cornerRadius: 0
                    }
                }
            });

            return barChart;

        }else{
            console.warn("No Data for Bar Chart");
            global.LRBErrorTracking.captureMessage("No Data for Bar Chart",LRBErrorTracking.LEVEL.WARNING);
        }
    }

    getRandomColour() {
        //Get a random colour
        var letters = '0123456789ABCDEF'.split('');
        var colour = '#';
        for (var i = 0; i < 6; i++ ) {
            colour += letters[Math.floor(Math.random() * 16)];
        }
        return colour;
    }

    destroy(){
    }

}

module.exports = { LRBCharts };
