require('velocity-animate');
//var AutoComplete = require('autocomplete-js');

class LRBContributors {
    constructor() {
        this.autoCompleteDataUrl = "/contributors/search/";
        this.autoCompleteTimer = null;
        this.singleCharSearchTimer = null;
        this.maxResults = 0; // set to 0 for no limit
        this.searchInterval = 600;
        this.minCharCount = 2;
    }

    setup() {

        try {
            if($('body').hasClass('section-contributors')){
                console.log('%cLRBContributors Setup', `background-color: ${global.infoBG};`);
    
                this.setupAutoComplete();
                this.setupFeaturedFilter();
    
                $('.contrib-cover-image').addClass('lazyload');
            }
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Contributors',error);
        }
    }

    loadCovers(){
        $('.contrib-cover-image:not(.lazyloaded):not(.load-failed)').addClass('lazyload');
    }

    setupAutoComplete(){
        let scope = this;

        if(!$('#contributorSearchField').hasClass('autocompleteSetup')){
            $('#contributorSearchField').addClass('autocompleteSetup');

            $('#contributorSearchField').after('<div class="contributorAutocompleteResults"></div>');

            $('#contributorSearchField').on('input update', function(e){
                e.preventDefault();
                e.stopPropagation();

                scope.autoCompleteTriggered($(this).val(),scope);

                return false;
            });
            $("#contributorSearchField").blur(function(){
                $('.contributorAutocompleteResults').velocity('stop',true).velocity("slideUp", {duration: 300, delay:100});
            });
            $("#contributorSearchField").focus(function(){
                if($(this).hasClass('hasResults')){
                    $('.contributorAutocompleteResults').velocity('stop',true).velocity("slideDown", {duration: 400});
                }
            });

            setInterval(function(){
                if($("#contributorSearchField").val() == ""){
                    scope.clearAutoComplete(true);
                }
            },1000);

        }else{
            console.warn("Contributor Autocomplete already setup - skipping");
        }
    }

    autoCompleteTriggered(val,scope){
        //console.log("autoCompleteTriggered: "+val);
        if(val != ""){
            if(scope.autoCompleteTimer){
                clearTimeout(scope.autoCompleteTimer);
            }
            scope.autoCompleteTimer = setTimeout(function(){
                scope.searchForVal(val);
            },scope.searchInterval);
        }else{
            this.clearAutoComplete();
        }
    }

    clearAutoComplete(silent = false){
        if(!silent){
            console.warn("Contributor Autocomplete Clearing");
        }else{
            //$('.contributorAutocompleteResults').velocity('stop').velocity("slideUp", {duration: 300});
        }

        $('.contributors-search .form-footer-linkholder p').html("&nbsp;");
        $(".contributorAutocompleteResults").html("");
        $("#contributorSearchField").removeClass('loading error success hasResults');
    }

    searchForVal(val){
        let scope = this;
        if(scope.singleCharSearchTimer){
            clearTimeout(scope.singleCharSearchTimer);
        }

        if(val){
            if(val == "" || val.length < scope.minCharCount){
                scope.clearAutoComplete();

                //If single character then after a longer delay with no input load that page
                /*if(val.length == 1){
                    scope.singleCharSearchTimer = setTimeout(function(){
                        window.location = "/contributors/filter/"+val;
                        $("#contributorSearchField").addClass('loading').prop('disabled', true);
                    }, 1500);
                }*/

            }else{

                var url = scope.autoCompleteDataUrl + "" + val;
                console.warn("Searching for Contributor Matches : "+url);
                scope.clearAutoComplete();
                $('#contributorSearchField').addClass("loading");

                $.getJSON(url, function(response, status, xhr){
                    $('#contributorSearchField').removeClass("loading");
                    if(status == "error"){
                        scope.showContributorSearchError(xhr.status + " " + xhr.statusText);
                    }else{
                        console.log( "Contributor Search Success" );

                        if(response.length > 0){
                            var i=0;
                            $('.contributors-search .form-footer-linkholder p').html("&nbsp;");
                            $(".contributorAutocompleteResults").append('<ul></ul>');
                            $("#contributorSearchField").addClass('hasResults success');
                            for (i = 0; i < response.length; i++) {
                                if(scope.maxResults == 0 || i < scope.maxResults){
                                    var result = response[i];
                                    if(result){
                                        var name = result.full_name;
                                        var slug = result.slug;
                                        if(name != "" && slug != ""){
                                            $(".contributorAutocompleteResults ul").append('<li><a href="/contributors/'+slug+'">'+name+'</a></li>');
                                        }else{
                                            console.error("Contributor Match Error: Missing details {name:'"+name+"', slug:'"+slug+"'}");
                                        }
                                    }
                                }
                            }
                            $('.contributorAutocompleteResults').velocity('stop',true).velocity("slideDown", {duration: 400});
                            $('.contributorAutocompleteResults a').on('click', function(){
                                setTimeout(function(){
                                    scope.clearAutoComplete();
                                },300);
                            });
                        }else{
                            scope.noResults();
                        }

                    }

                }).fail(function(e) {
                    scope.showContributorSearchError(e);
                });

            }
        }else{
            console.error("Invalid value sent for contributor search");
        }
    }

    noResults(){
        console.warn("No Matches");
        this.clearAutoComplete();

        $("#contributorSearchField").addClass('error');
        $('.contributors-search .form-footer-linkholder p').html("No contributors found for this name.");
    }

    showContributorSearchError(error = ""){
        console.error("Contributor Search Error: "+error);
        global.LRBErrorTracking.captureMessage("Contributor Search Error: "+error);

        $("#contributorSearchField").addClass('error');
        $('.contributors-search .form-footer-linkholder p').html("An error occured when performing this search, please try again.");
    }

    setupFeaturedFilter(){
        if($('.contributors-list .featured').length == 0){

            console.log("No Featured Contributors set, showing all");

            //No Featured Contributors are set, show all and remove button
            $('.contributors-list').addClass('allShown');
            $('.contributors-showall-link').remove();
            $('.contributors-list .list-title').html($('.contributors-list .list-title').data('txt'));

        }else{

            console.log("Featured Contributors set, creating filter");

            //Featured Contributors are set, provide button to toggle this
            $('.contributors-list .list-title').html($('.contributors-list .list-title').data('txt-featured'));
            $('.contributors-showall-link a').html($('.contributors-showall-link a').data('btntxt'));


            $('.contributors-showall-link a').on('click', function(e){
                e.preventDefault();
                e.stopPropagation();

                var $list = $('.contributors-list');

                if($list.hasClass('allShown')){
                    //show just featured
                    $list.css('opacity', 0).removeClass('allShown');
                    $('.contributors-showall-link a').html($('.contributors-showall-link a').data('btntxt'));
                    $('.contributors-list .list-title').html($('.contributors-list .list-title').data('txt-featured'));
                }else{
                    //show all
                    $list.css('opacity', 0).addClass('allShown');
                    $('.contributors-showall-link a').html($('.contributors-showall-link a').data('btntxt-featured'));
                    $('.contributors-list .list-title').html($('.contributors-list .list-title').data('txt'));
                }
                $list.velocity('stop').velocity("fadeIn", {duration: 800});
                global.utils.scrollTo($list,80);

                return false;
            });

        }
    }

    destroy(){
        $("#contributorSearchField").remove();
        this.autoComplete = null;
    }

}

module.exports = { LRBContributors };
