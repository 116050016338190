const ScrollReveal = require('scrollreveal').default;
const hoverintent = require('hoverintent');

/* Run upon each page load / partial load
* (for now we're not doing partial page loading, but best to keep things
* separated in case this needs to be added in in future) */

class PageReady {
    constructor() {
        this.pageReady = false;
        this.revealOptions = {
            origin:'bottom',
            distance:'30px',
            duration:800,
            delay:1,
            easing:'cubic-bezier(0.215, 0.61, 0.355, 1)',
            scale:1,
            opacity:0,
            mobile:true,
            reset:false,
            viewFactor:0.18,
            beforeReveal: function (domEl) {
                //global.resizing.resizeHandler();
                //setTimeout(global.resizing.resizeHandler,10);
            },
            afterReveal : function(domEl) {
                if(domEl){
                    $(domEl).addClass('revealed');
                    $(domEl).removeClass('revealing');
                }
            },
            afterReset  : function(domEl) {
                if(domEl){
                    $(domEl).removeClass('revealed');
                }
            }
        };
    }

    setup() {
        $('html').addClass('lrb-readyingpage');
        // global.LRBSentry.captureMessage('TM:PageReady Start');

        console.log('%c[PageReady]', `background-color: ${global.infoBG};`);
        try{window.performance.mark('page_ready');}catch(e){}

        (async() => {
            try {
                this.delayedSetup();
                this.updatePageClass();
                this.setupNavigationLinks();
                this.setupKeyboardEvents();
                this.setupTouchEvents();
                this.watchForHashChange();
    
                try{window.performance.mark('page_ready_finished');}catch(e){}

            } catch (error) {
                global.LRBErrorTracking.reportErrorWithFallbackMessage('PageReady Error',error);
            }
        })();

        // global.LRBSentry.captureMessage('TM:PageReady Complete');

        $('html').removeClass('lrb-readyingpage');
        $('html').addClass('lrb-ready');
        this.pageReady = true;

        // global.LRBSentry.captureMessage('TM:PageReady End');
    }

    delayedSetup(){
        let scope = this;
        setTimeout(function() {
            $('html').removeClass('page-initial-load');

            //$('body').waitForImages(function(){
                scope.setupScrollReveal();
            //});

        }, 10);
    }

    setupScrollReveal(){
        if(!global.isPrint){
            //force arrowicons to reveal
            $('.lrb-arrowlink').addClass('reveal arrow-reveal');

            //force search options panel links to sequence reveal
            //$('.lrb-search--subjects a').addClass('reveal search-sequenced-reveal');

            if(!window.sr){
                window.sr = new ScrollReveal();
            }
            if($('.reveal').length > 0){
                window.sr.reveal('.reveal', this.revealOptions);
            }
            if($('.search-sequenced-reveal').length > 0){
                window.sr.reveal('.search-sequenced-reveal', { interval: 70, opacity: 0 });
            }
            if($('.delayed-reveal').length > 0){
                window.sr.reveal('.delayed-reveal', {delay:200});
            }
            if($('.left-reveal').length > 0){
                window.sr.reveal('.left-reveal', {origin:'left'});
            }
            if($('.right-reveal').length > 0){
                window.sr.reveal('.right-reveal', {origin:'right'});
            }
            if($('.static-reveal').length > 0){
                window.sr.reveal('.static-reveal', {duration:800, distance:'0px'});
            }
            if($('.arrow-reveal').length > 0){
                window.sr.reveal('.arrow-reveal', {duration:800, distance:'0px', delay: 200});
            }
        }else{
            this.forceScrollReveal();
        }
    }

    forceScrollReveal(){
        $('reveal').addClass('force-reveal');
    }

    updateScrollReveal(){
        if(window.sr){
            window.sr.sync();
        }
    }

    updatePageClass(){

    }

    setupNavigationLinks() {

        let scope = this;

        global.LRBNavigation.resetSuperNav('.mainnav-link--paper');

        $('.mainnav-link--paper').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            global.LRBNavigation.resetSuperNav('.supernav-link--paper');
            global.LRBNavigation.toggleSuperNav();

            return false;
        });
        $('.mainnav-link--subjects').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            global.LRBNavigation.resetSuperNav('.supernav-link--subjects');
            global.LRBNavigation.toggleSuperNav();

            return false;
        });
        $('.mainnav-link--shop').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            global.LRBNavigation.resetSuperNav('.supernav-link--shop');
            global.LRBNavigation.toggleSuperNav();

            setTimeout(function(){
                var top = 0;
                if($('.supernav-link--shop').length > 0){
                    top = $('.supernav-link--shop').offset().top;
                }
                $('.sidenav-scrolling').animate({ scrollTop: top + 100 }, 400);
            },200);

            return false;
        });
        $('a.burgerstack').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            global.LRBNavigation.resetSuperNav();
            global.LRBNavigation.toggleSuperNav();

            return false;
        });

        // Dont reload the page we're already on for nav link clicks
        // Instead, close overlays and scroll to top of page
        $('.nav-pagelink, .logo a, .institution-navigation a, .paperArticle-letters .article-letters-inner a').on('click', function(e){
            if($(this).attr('href') == window.location.pathname){
                e.preventDefault();
                e.stopPropagation();

                global.LRBNavigation.closeAllOverlays();
                global.utils.scrollToTop();

                return false;
            }
        });

        $('.fadeHoverList li a').each(function(){
            var el = $(this)[0];
            var $el = $(this);
            hoverintent(el,
                function(){
                    $el.parent().parent().parent().addClass('hovering');
                }, function(){
                    $el.parent().parent().parent().removeClass('hovering');
                }
            );
        });

        $('.overlay-container, .sidenav-close, .searchnav-close').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            global.LRBNavigation.closeAllOverlays();

            return false;
        });

        $('.mainnav-link--search, .social-button.search-button').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            if(global.viewportWidth <= 850){
                //open search overlay on mobile
                global.LRBNavigation.toggleOverlaySearchNav();
            }else{
                //open search dropdown from header on other
                global.LRBNavigation.toggleSearchNav();
            }

            return false;
        });

        //Dont submit blank search forms
        $('.searchForm').on('submit', function(e){
            if($(this).find('.searchField').val() == ""){
                e.preventDefault();
                e.stopPropagation();

                return false;
            }
        });


        $('.supernav a').on('click', function(e){

            var $parent = $(this).parent();

            if(!$parent.hasClass('has-submenu')){

                /*setTimeout(function(){
                    window.location = $(this).attr('href');
                },350);*/

                //Regular link, check to see if it is external
                if($(this).attr('target') == "_blank"){
                    //External, open link and leave menu open
                }else{
                    //Internal, close menu and open link
                    global.LRBNavigation.closeAllOverlays();
                }

            }else{
                e.preventDefault();
                e.stopPropagation();

                //toggle submenu
                $parent.toggleClass('submenuOpen');

                if(!$('html').hasClass('supernavAnimating')){
                    if($parent.hasClass('submenuOpen')){

                        //Show Submenu
                        $('html').addClass('supernavAnimating');

                        $('.submenuOpen').removeClass('submenuOpen');
                        $parent.addClass('submenuOpen');
                        $('.has-submenu ul').velocity("stop").velocity("slideUp", { duration: 450});
                        $parent.find('ul').velocity("stop").velocity("slideDown", { duration: 450, complete: function(){
                            $('html').removeClass('supernavAnimating');
                        }});

                    }else{

                        //Hide Submenu
                        $('html').addClass('supernavAnimating');

                        $('.submenuOpen').removeClass('submenuOpen');
                        $('.has-submenu ul').velocity("stop").velocity("slideUp", { duration: 450, complete:function(){
                            $('html').removeClass('supernavAnimating');
                        }});

                    }
                }

                return false;
            }
        });
    }

    setupKeyboardEvents() {
        document.onkeydown = this.keypressHandler;
    }

    keypressHandler(e) {
        e = e || window.event;

        //console.log(e.keyCode);

        switch(e.keyCode){
            case '38':
                //up arrow
                break;
            case '40':
                //down arrow
                break;
            case 37:
                //left arrow
                break;
            case 39:
                //right arrow
                break;
            case 27:
                //Escape
                global.LRBNavigation.closeAllOverlays();
                break;
        }
    }

    setupTouchEvents(){

        //Prevent body touch scroll on modals / sidenavs, whilst allowing inner scrolling on those elements
        $('body').on('touchmove', function(e){
            if($('html').hasClass('lrb-overlayed') ||
                $('html').hasClass('lrb-supernavOpen') ||
                $('html').hasClass('lrb-searchOverlayOpen') ||
                $('html').hasClass('lrb-searchNavOpen') ||
                $('html').hasClass('lrb-modalOpen') ||
                $('html').hasClass('lrb-tacModalOpen')){

                if($(e.target).parents('.sidenav-scrolling').length > 0){
                    //valid scrolling target
                }else{
                    //invalid scrolling target
                    e.preventDefault();
                    e.stopPropagation();

                    console.log("setupTouchEvents > preventing scroll");

                    return false;
                }

            }

        });
    }

    watchForHashChange(){
        window.onhashchange = this.handleHashChange;
        let scope = this;
        setTimeout(function(){scope.handleHashChange()},250);
    }
    handleHashChange(){
        try{
            if(location.hash && location.hash !== null && location.hash !== undefined && location.hash !== "" && location.hash !== "#" && !location.hash.includes(':~:text')){
                console.log("Hashchange - "+location.hash);

                // Open Login modals
                if(location.hash.toLowerCase() == "#login"){
                    location.hash = "";
                    global.LRBModal.openLoginModal();
                    return;
                }

                // Jump to section with id of hash
                let $target = $(location.hash);
                if($target.length > 0){
                    var offset = 80;
                    if($('html').hasClass('lrb-article')){
                        offset = 220;
                    }
                    global.utils.scrollTo($target, offset, 450);
                    return;
                }
            }

            // Open advanced Search
            if(location.hash && $('body').hasClass('section-search-results') && location.hash == "#advanced"){
                LRBSearch.showAdvancedSearch();
                global.utils.scrollToTop();
                return;
            }

            if(location.hash && $('body').hasClass('section-courses')){
                global.LRBCourses.handleHashChange(location.hash);
            }
        }catch(e){
            global.LRBErrorTracking.captureMessage('Could not handle hash change: '+location.hash);
        }
    }

}

module.exports = { PageReady };
