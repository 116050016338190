window.Headroom = require("headroom.js/dist/headroom.js");
//require("headroom.js/dist/jQuery.headroom.js");
const Flickity = require("flickity");
const inView = require('in-view');

class LRBNavigation {
    constructor() {
        this.headroom = null;
        global.headerPinned = true;
        this.navPinningDelay = 500;

        this.fakePaginateLimit = 15;
        this.currentlyShownFilteredItems = null;
    }

    setup() {
        console.log('%cLRBNavigation Setup', `background-color: ${global.infoBG};`);

        try {
            this.setupSearchOptionsNav();
            this.setupHeadroom();
            this.setupNavLetterNavigations();
            this.setupDropdownNavigations();
            this.setupArticleSubnav();
            this.setupArticleTitleOverlay();
            this.setupListFilterNav();
            this.setupTOCNavigation();

        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Navigation',error);
        }
    }

    setupSearchOptionsNav(){
        let scope = this;

        $('.search-options a').on('click', function(){
            scope.closeAllOverlays();
        });
    }

    setupHeadroom() {

        if($('html').hasClass('ezmode')){
            //if we're in EZ admin then dont setup
            return false;
        }

        let scope = this;
        var scrollOffset = Math.max(205, global.viewportHeight * 0.05);

        if($(".header-container").length > 0){
            this.headroom = new Headroom(document.querySelector(".header-container"),{
            //this.headroom = $(".header-container").headroom({
                // scroll tolerance in px before state changes
                tolerance: 15,
                // vertical offset in px before element is first unpinned
                offset: scrollOffset,
                // css classes to apply
                classes: {
                    // when element is initialised
                    initial: "lrbhead",
                    // when scrolling up
                    pinned: "lrbhead--pinned",
                    // when scrolling down
                    unpinned: "lrbhead--unpinned",
                    // when above offset
                    top: "lrbhead--top",
                    // when below offset
                    notTop: "lrbhead--not-top",
                    // when at bottom of scoll area
                    bottom: "lrbhead--bottom",
                    // when not at bottom of scroll area
                    notBottom: "lrbhead--not-bottom"
                },
                // callback when pinned, `this` is headroom object
                onPin: function() {
                    scope.setHeaderPinned();
                },
                // callback when unpinned, `this` is headroom object
                onUnpin: function() {
                    scope.setHeaderUnpinned();
                },
                // callback when above offset, `this` is headroom object
                onTop: function() {
                    $('html').removeClass('lrb-nt');
                },
                // callback when below offset, `this` is headroom object
                onNotTop: function() {
                    $('html').addClass('lrb-nt');
                },
                // callback at bottom of page, `this` is headroom object
                onBottom: function() {},
                // callback when moving away from bottom of page, `this` is headroom object
                onNotBottom: function() {}
            });

            this.headroom.init();

            setInterval(scope.updateHeaderPinState, scope.navPinningDelay);
        }
    }

    setHeaderPinned(){
        global.headerPinned = true;
    }
    setHeaderUnpinned(){
        global.headerPinned = false;
    }
    updateHeaderPinState(){
        if(global.headerPinned){
            $('html').removeClass('lrbhead--unpin');
            $('html').addClass('lrbhead--pin');
        }else{
            $('html').addClass('lrbhead--unpin');
            $('html').removeClass('lrbhead--pin');
        }
    }

    updateHeadroomOnResize(){
        if(this.headroom){
            this.headroom.offset = Math.max(205, global.viewportHeight * 0.05);
        }
    }

    setupNavLetterNavigations(){
        $('.lrb-alphabet-nav').each(function(){
            var $el = $(this).find('.lrb-alphabet-nav__list');

            var flkty = new Flickity( $el[0], {
                cellAlign: 'center',
                contain: true,
                draggable: true,
                freeScroll: true,
                prevNextButtons: false,
                pageDots: false,
                wrapAround: false
            });

            $(this).find('.prev-link').on('click', function(e){
                e.preventDefault();
                e.stopPropagation();

                flkty.previous();
                flkty.previous();
                flkty.previous();
                flkty.previous();
                flkty.previous();

                return false;
            });

            $(this).find('.next-link').on('click', function(e){
                e.preventDefault();
                e.stopPropagation();

                flkty.next();
                flkty.next();
                flkty.next();
                flkty.next();
                flkty.next();

                return false;
            });

            $el.find('li').each(function(i){
                if($(this).find('.active, .selected').length > 0){
                    flkty.selectCell(i, false, true);
                }
            });
        });
    }

    setupDropdownNavigations(){
        let scope = this;
        $('.drop-menu').each(function (index, value) {
            scope.selectDropMenuItem($(this));
        });

        $('body').click(function(){
            scope.hideDropMenus();
        });

        $(".drop-menu .menu").on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            return false;
        });

        $('.drop-menu').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            $('.menu').not($('.menu',$(this))).hide();
            $('.drop-menu').not($(this)).removeClass("active");
            $('.menu',$(this)).slideToggle();
            $(this).toggleClass("active");

            return false;
        });

        $('.drop-menu .menu a').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            //console.warn("menuclick");

            $(".menu li a").removeClass("selected");
            $(this).addClass("selected");
            scope.selectDropMenuItem($(this).parent().parent().parent());

            return false;
        });
    }

    selectDropMenuItem(menu){
        //console.log(menu);

        var found = 0;

        $('.menu li',menu).each(function (index, value) {
        if($('a',$(this)).hasClass("selected")){

            found=1;
            $('.display',menu).text(
                $('a',$(this)).text()
            );

            $('.value',menu).text(
                $('a',$(this)).data("value")
            );
        }
        });

        if(!found){
            $('.display',menu).text(
                menu.data("default")
            );
        }

        this.hideDropMenus();
    }

    hideDropMenus(){
        $('.menu').hide();
        $('.drop-menu').removeClass("active");
    }



    toggleSuperNav(){
        /*if(!$('html').hasClass('lrb-supernavOpen')){
            $('body').css('top', -(document.documentElement.scrollTop) + 'px').addClass('noscroll');
        }else{
            $('body').css('top', '0px').addClass('no-scroll');
        }*/

        this.closeSearchNav();
        $('html').toggleClass('lrb-supernavOpen');
    }

    toggleOverlaySearchNav(){
        $('.searchField').val('');
        this.closeSearchNav();
        $('html').toggleClass('lrb-searchOverlayOpen');
        if($('html').hasClass('lrb-searchOverlayOpen')){
            $('.searchoverlay-container .searchField').focus();
        }
    }

    resetSuperNav(linkClass){
        $('.has-submenu ul').hide();
        $('.submenuOpen').removeClass('submenuOpen');

        if(linkClass){
            $(linkClass).parent().addClass('submenuOpen');
            //$(linkClass).parent().find('ul').show();
            $(linkClass).parent().find('ul').velocity("stop").velocity("slideDown", { delay: 200, duration: 450 });
        }
    }
    resetOverlaySearchNav(linkClass){
        $('.has-submenu ul').hide();
        $('.submenuOpen').removeClass('submenuOpen');

        if(linkClass){
            $(linkClass).parent().addClass('submenuOpen');
            $(linkClass).parent().find('ul').show();
        }
    }

    toggleSearchNav(){
        let scope = this;

        if(!$('html').hasClass('lrb-searchNavOpen')){
            scope.openSearchNav();
        }else{
            scope.closeSearchNav();
        }
    }

    openSearchNav(){
        if(!$('html').hasClass('lrb-searchNavOpen')){

            if(!$('html').hasClass('searchNavAnimating')){
                $('html').addClass('searchNavAnimating');

                /*$('.lrb-search').velocity("stop").velocity("slideDown", { duration: 450, complete: function(){
                    $('html').removeClass('searchNavAnimating');

                    // update reveal setup for search options if havent already
                    if($('.lrb-search').find('.revealed').length == 0){
                        global.pageReady.updateScrollReveal();
                    }
                }});*/

                var hHeight = $('.lrb-search').outerHeight(true);
                $('.header-container .header').velocity('stop').velocity({ duration:450, height: hHeight});
                //$('.header-container .header').css('min-height',hHeight+'px');

                $('html').addClass('lrb-searchNavOpen');

                setTimeout(function(){
                    $('html').removeClass('searchNavAnimating');
                    $('.searchField').focus();
                },800);
            }

        }
    }

    closeSearchNav(){
        if($('html').hasClass('lrb-searchNavOpen')){

            if(!$('html').hasClass('searchNavAnimating')){
                $('html').addClass('searchNavAnimating');

                /*$('.lrb-search').velocity("stop").velocity("slideUp", { duration: 450, complete: function(){
                    $('html').removeClass('searchNavAnimating');
                }});*/

                $('.header-container .header').velocity("reverse", {duration: 200, complete: function(){
                    $('.header-container .header').removeAttr('style');
                }});
                //$('.header').removeAttr('style');

                setTimeout(function(){
                    $('html').removeClass('searchNavAnimating');
                    $('.searchField').val('');
                },350);

                $('html').removeClass('lrb-searchNavOpen');
            }

        }
    }

    closeAllOverlays(){
        $('html').removeClass('lrb-supernavOpen lrb-overlayed lrb-searchOverlayOpen');
        this.closeSearchNav();
        global.LRBModal.closeModal();
        global.LRBLightbox.closeLightbox();
        setTimeout(this.resetSuperNav, 400);
        setTimeout(this.resetOverlaySearchNav, 400);
    }

    setupTOCNavigation(){
        $('.tocCoverTopLink').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            //global.utils.scrollToTop();

            return false;
        });
    }

    setupArticleSubnav(){

        if($('html').hasClass('ezmode')){
            //if we're in EZ admin then return false
            return false;
        }

        if(this.checkIfArticlePage()){

            var $el = $('.article-subnav-slider');

            if($el.length > 0 && !$el.hasClass('subnavSetup')){
                console.log('Setting up article-subnav');

                var flkty = new Flickity( $el[0], {
                    cellAlign: 'left',
                    setGallerySize: false,
                    adaptiveHeight: false,
                    contain: true,
                    draggable: true,
                    freeScroll: false,
                    prevNextButtons: true,
                    pageDots: false,
                    wrapAround: false
                });

                flkty.on('dragStart', function( event, pointer ) {
                    document.ontouchmove = function (e) {
                        e.preventDefault();
                    }
                });
                flkty.on('dragEnd', function( event, pointer ) {
                    document.ontouchmove = function (e) {
                        return true;
                    }
                });

                $el.find('.previous').on('click', function(e){
                    e.preventDefault();
                    e.stopPropagation();

                    if(global.viewportWidth > 850){
                        flkty.previous();
                    }
                    if(global.viewportWidth > 1024){
                        //flkty.previous();
                    }

                    return false;
                });

                $el.find('.next').on('click', function(e){
                    e.preventDefault();
                    e.stopPropagation();

                    if(global.viewportWidth > 850){
                        flkty.next();
                    }
                    if(global.viewportWidth > 1024){
                        //flkty.next();
                    }

                    return false;
                });

                $($el).addClass('subnavSetup');
            }else{
                console.log('Skipping Article Subnav, already setup');
            }

        }else{
            if($('.article-subnav-slider').length > 0){
                console.warn('Not an article page, force destroying article subnav');
                $('.article-subnav-slider').remove();
            }
        }
    }

    checkIfArticlePage(){

        if($('html').hasClass('ezmode')){
            //if we're in EZ admin then return false
            return false;
        }

        var articlePage = false;
        if($('.article-header-container').length > 0 && $('.article').length > 0 && $('.lrb-article').length > 0){
            if(!$('.article').hasClass('type--video') && !$('.article').hasClass('type--podcast')){
                articlePage = true;
            }
        }

        return articlePage;
    }

    setupArticleTitleOverlay(){

        if($('html').hasClass('ezmode')){
            //if we're in EZ admin then dont setup
            return false;
        }

        if(this.checkIfArticlePage()){

            if(!$('.article-title-overlay').hasClass('titleOverlaySetup')){
                console.log('Setting up article-title-overlay');

                $('html').addClass('articleHeaderNotVisible');

                inView('.article-header-container').on('enter', function(){
                    $('html').removeClass('articleHeaderNotVisible');
                    $('html').addClass('articleHeaderVisible');
                }).on('exit', function(){
                    $('html').addClass('articleHeaderNotVisible');
                    $('html').removeClass('articleHeaderVisible');

                    global.LRBTracking.trackArticleReadStart('Began scrolling', 'Reading');

                });
            }else{
                console.log('Skipping Article Title Overlay, already setup');
            }

        }else{
            if($('.article-title-overlay').length > 0){
                console.warn('Not an article page, force destroying article title overlay');
                $('.article-title-overlay').remove();
            }
        }
    }


    setupListFilterNav(){

        if($('body').hasClass('section-contributors')){
            this.fakePaginateLimit = 5;
        }else{
            this.fakePaginateLimit = 15;
        }

        let scope = this;
        let $toShow = null;

        if($('.lrb-list-filter-nav').length > 0){
            $('.lrb-list-filter-nav').each(function(){
                var $el = $(this);

                var skipSetup = $el.data('skip-setup');
                if(skipSetup){
                    console.warn('Skipping setup of Filter Nav element');
                    return false;
                }

                if(!$el.hasClass('filternavSetup')){

                    var fakePaginate = $el.data('paginate');
                    var fakePageText = "";
                    if(fakePaginate){
                        var fakePageText = ", with fake pagination";
                    }
                    console.log('Setting up lrb-list-filter-nav'+fakePageText);

                    var flkty = new Flickity( $el[0], {
                        cellAlign: 'center',
                        setGallerySize: true,
                        adaptiveHeight: false,
                        contain: true,
                        draggable: false,
                        freeScroll: false,
                        prevNextButtons: false,
                        pageDots: false,
                        wrapAround: false
                    });

                    flkty.on('dragStart', function( event, pointer ) {
                        document.ontouchmove = function (e) {
                            e.preventDefault();
                        }
                    });
                    flkty.on('dragEnd', function( event, pointer ) {
                        document.ontouchmove = function (e) {
                            return true;
                        }
                    });
                    flkty.on('resize', function( event, pointer ) {
                        scope.checkFilterListDragging(flkty, $el);
                    });

                    scope.checkFilterListDragging(flkty, $el);
                    $($el).addClass('filternavSetup');


                    //Setup list-filter functionality, to allow quick JS filtering of elements across a page
                    $('.lrb-list-filter-nav:not(.contributor-list-nav)').each(function(){
                        var $parent = $(this);
                        scope.checkForFilterListBlogPosts($parent);

                        //Initial Nav/List setup
                        $(this).find('.list-filter-nav--link').each(function(i){
                            $(this).data('flktyid',i);

                            //check for list items to filter for this nav link
                            $toShow = null;
                            if($(this).data('show-class')){
                                $toShow = $("."+$(this).data('show-class'));
                                if($toShow.length == 0){
                                    //nothing set for display, remove this filter
                                    $(this).parent().remove();
                                }
                                if(fakePaginate){
                                    $toShow.addClass('fake-paginate');
                                }
                            }
                        });
                        $(this).find('.list-filter-nav--link').each(function(i){

                            //check for list items to filter for this nav link
                            $toShow = null;
                            if($(this).data('show-class')){
                                $toShow = $("."+$(this).data('show-class'));
                            }else{
                                return;
                            }

                            if($(this).hasClass('selected')){

                                scope.showFilterListItems($toShow, $parent, scope, fakePaginate);

                            }

                            //Remove Empty categories on Events
                            if($('body').hasClass('section-events') && $toShow && $toShow.length == 0){
                                $(this).parent().remove();
                                flkty.resize();
                            }

                            if($parent.find('li').length < 2){
                                $parent.hide();
                            }

                        });


                        //Filter nav Click setup
                        $(this).find('.list-filter-nav--link').on('click', function(e){

                            if($(this).data('show-class')){

                                e.preventDefault();
                                e.stopPropagation();

                                if(!$(this).hasClass('selected')){

                                    $parent.find('.list-filter-nav--link.selected').removeClass('selected');
                                    $(this).addClass('selected');
                                    flkty.selectCell($(this).data('flktyid'));

                                    $toShow = $("."+$(this).data('show-class'));
                                    if($toShow){

                                        scope.showFilterListItems($toShow, $parent, scope, fakePaginate);

                                    }else{
                                        console.error('No valid target for List Filter link');
                                    }

                                }

                                return false;

                            }else{
                                console.warn('No data for List Filter link');
                            }
                        });

                        // Fake Pagination Link setup
                        if(fakePaginate){
                            //$parent.parent().parent().find('.see-more-link .see-more').on('click', function(e){
                            $('.see-more-link .see-more').on('click', function(e){
                                e.preventDefault();
                                e.stopPropagation();

                                scope.handleFakePaginate($parent, scope);

                                return false;
                            });
                        }else{
                            //$parent.find('.see-more-link').remove();
                            $('.see-more-link').remove();
                        }

                        // Change sort order of Fake Pagination (flips order back and forth)
                        if($('.jsOldestSortToggle').length > 0){
                            $('.jsOldestSortToggle').on('click', function(e){
                                e.preventDefault();
                                e.stopPropagation();

                                // Flip Order
                                //var list = $(this).parent().parent().find('.results-list--contents');
                                var list = $('.results-list--contents');
                                var listItems = list.children('.list-filter--item');
                                list.append(listItems.get().reverse());

                                // Reshow to correct inverted offset
                                //$toShow = $("."+$(this).parent().parent().find('.list-filter-nav--link.selected').data('show-class'));
                                $toShow = $("."+$('.list-filter-nav--link.selected').data('show-class'));
                                //console.log($toShow);
                                scope.showFilterListItems($toShow, $parent, scope, fakePaginate);

                                // Fix classes and reveal
                                $('.first-shown-item').removeClass('first-shown-item');
                                $toShow.eq(0).addClass('first-shown-item');
                                window.pageReady.updateScrollReveal();

                                // Scroll and update button copy
                                global.utils.scrollTo($('.contributor-list'), 85);
                                $(this).toggleClass('oldest');
                                if($(this).hasClass('oldest')){
                                    $(this).html('Show newest');
                                }else{
                                    $(this).html('Show oldest');
                                }

                                return false;
                            });
                        }

                    });
                }else{
                    console.log('Skipping List Filter Nav, already setup');
                }
            });
        }

        //reset pagination amount
        this.fakePaginateLimit = 15;

    }

    showFilterListItems($toShow, $parent, scope, fakePaginate = false){

        $('.list-filter--item').hide().attr('aria-hidden',true);
        if(fakePaginate){
            $toShow.addClass('fake-paginate');
            $toShow.slice(0,scope.fakePaginateLimit).removeClass('fake-paginate');

            if($toShow.length <= scope.fakePaginateLimit){
                //$parent.parent().parent().find('.see-more-link .see-more').hide();
                $('.see-more-link .see-more').hide();
            }else{
                //$parent.parent().parent().find('.see-more-link .see-more').show();
                $('.see-more-link .see-more').show();
            }
        }

        //console.log($toShow);

        $toShow.velocity('stop').velocity("fadeIn", {duration: 400});
        window.pageReady.updateScrollReveal();
        $toShow.removeAttr('aria-hidden');
        $('.first-shown-item').removeClass('first-shown-item');
        $toShow.eq(0).addClass('first-shown-item');

        scope.currentlyShownFilteredItems = $toShow;

        scope.checkForFilterListBlogPosts($parent);
    }

    handleFakePaginate($parent, scope){
        if(scope.currentlyShownFilteredItems){

            console.log("Handling Fake Paginate");
            var $remainingFakePaginateItems = scope.currentlyShownFilteredItems.filter('.fake-paginate');
            var $fakePaginateItemsToShow = $remainingFakePaginateItems.slice(0,scope.fakePaginateLimit);
            $fakePaginateItemsToShow.removeClass('fake-paginate');
            window.pageReady.updateScrollReveal();
            $fakePaginateItemsToShow.removeAttr('aria-hidden');
            if($remainingFakePaginateItems.length <= scope.fakePaginateLimit){
                //$parent.parent().parent().find('.see-more-link .see-more').hide();
                $('.see-more-link .see-more').hide();
            }

        }else{
            console.warn("No items for fake paginate to handle");
        }
    }

    checkForFilterListBlogPosts($filterListNav){
        if($filterListNav.find('.list-filter-nav--link.selected').data('show-class') == "blog_post"){
            $filterListNav.parent().parent().addClass('showingBlogPosts');
        }else{
            $filterListNav.parent().parent().removeClass('showingBlogPosts');
        }
    }

    checkFilterListDragging(flkty, $el){
        // see if filter list needs to be draggable or not, based on it's contents
        if(flkty && $el){
            var oldDrag = flkty.options.draggable;

            var contentsWidth = 0;
            $el.find('.flickity-slider li').each(function(){
                contentsWidth += $(this).outerWidth(true);
            });

            if(contentsWidth > $el.find('.flickity-viewport').outerWidth(true)){
                //contents are larger than carousel viewport, add scrolling
                flkty.options.draggable = true;
            }else{
                //contents are smaller than carousel viewport, remove scrolling
                flkty.options.draggable = false;
            }

            if(flkty.options.draggable != oldDrag){
                //draggable option changed, update Flickity
                flkty.updateDraggable();
            }
        }else{
            console.error('Missing objects on checkFilterListDragging');
        }
    }

    destroy(){
        this.scrolledArticle = false;
    }

}

module.exports = { LRBNavigation };
