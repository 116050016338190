class LRBArticle {
    constructor() {
        this.articleId = 'not-set';
    }

    setup() {
        if($('.section-the-paper').length > 0 && $('.lrb-article').length > 0){
            console.log('%cLRBArticle Setup', `background-color: ${global.infoBG};`);

            if(document.querySelector('meta[name="articleid"]')){
                this.articleId = document.querySelector('meta[name="articleid"]').content;
            }

            this.setupArticleSubnav();
        }
    }

    setupArticleSubnav(){
        if($('.section-the-paper').length > 0 && $('.lrb-article').length > 0){
            console.log('Loading Article Subnav: /ajax/article/subnav/'+this.articleId);

            $(".article-subnav-container").load("/ajax/article/subnav/"+this.articleId, function(response, status, xhr){
                var statusCol = global.successBG;
                if(status == "error"){
                    statusCol = global.errorBG;
                }
                console.log('Article Subnav Loading: %c'+status, `background-color: ${statusCol};`);

                if(status == "error"){
                    var msg = "Article Subnav Loading Error: ";
                    console.error( msg + xhr.status + " " + xhr.statusText );
                    //global.Bugsnag.notify(response);
                }else{
                    $(".article-subnav-container").html(response);
                    global.LRBNavigation.setupArticleSubnav();
                }
            });

        }
    }

    destroy(){
    }

}

module.exports = { LRBArticle };
