/*

This updates the UI of Account and Institutional login buttons in the header according to data from lrb_session cookie set in Fastly preflight
This also updates all the subscription links that are set to use a campaign code from Office admin (see updateSubscriptionCodes() for more detail)

1. setup() is triggered at the start of the app launch to load cookie immediately

2. updatePage() is triggered at the relevant point of the app loading sequence

3. updatePage() triggers updateLoginUI(), updateInstitutionalLogin() using session data loaded in setup()

4. updatePage() also triggers updateSubscriptionCodes() which uses geolocated subcodes from session data loaded in setup()

*/

const SESSION_COOKIE = 'lrb_session';
const NON_SESSION_URLS = ['/__','/admin','/account/check','/login_check','/api','/graphql','/lrb_assets','/logout','/federated','/federated-auth','/content/download','/ajax','/session'];

class LRBSessionData {
    constructor() {
        this.sessionCookie = null;
        this.sessionData = {};
        this.loggedIn = false;
        this.facebookBot = false;
        this.subCodes = null;
        this.trackingData = null;
        this.subXData = {key:'lrb-subx',session_set:false,s_pw:true,referrer:{google:false,facebook:false,instagram:false,twitter:false,threads:false,youtube:false,reddit:false}};
        this.userID = null;

        // Temporarily force a_pw to be true
        /*this.tempForceAPWonURLs = [
            '/podcasts-and-videos/podcasts/the-belgrano-diary/*'
        ];

        // Temporarily force a_pw to be false
        this.tempUnforceAPWonURLs = [
            '/podcasts-and-videos/podcasts/the-belgrano-diary/introducing-the-belgrano-diary', // intro
            '/podcasts-and-videos/podcasts/the-belgrano-diary/half-a-million-sheep-can-t-be-wrong', // episode 1
            '/podcasts-and-videos/podcasts/the-belgrano-diary/gotcha' // episode 2
        ];*/
    }

    /* Load session cookie, set as lrb_session in Fastly preflight, values set in PreflightController */
    setup() {
        global.LRBErrorTracking.setContext('LRBSessionData Setup');

        console.log('%cLRBSessionData Setup', `background-color: ${global.infoBG};`);

        // Load Session Cookie = cookie is set in preflight
        let sessionCookie = window.utils.getCookie(SESSION_COOKIE);
        let data = null;
        let scope = this;

        // Load Session data from cookie
        if(sessionCookie && sessionCookie != ''){
            // Parse session data from cookie
            try{
                data = JSON.parse(atob(sessionCookie));
            }catch(e){
                scope.handleSessionDataError('No valid session data',LRBErrorTracking.LEVEL.ERROR,'production');
                data = null;
            }
        }else{
            // No cookie is set on local dev, so we only want to track this in prod
            //scope.handleSessionDataError('No session cookie set',LRBErrorTracking.LEVEL.ERROR,'production',true);
            data = null;
        }
        this.sessionData = data;

        if(this.sessionData){

            // Check if logged in (used only to update UI, nothing secure / sensitive)
            try{
                this.loggedIn = this.sessionData.paywall.logged_in;
            }catch(e){
                scope.handleSessionDataError('Cannot determine logged in state',LRBErrorTracking.LEVEL.ERROR,'production',true);
            }

            // Check if is a Facebook bot/crawler, used to disable some tracking and error reporting
            try{
                if(this.sessionData.hasOwnProperty('facebook_bot')){
                    this.facebookBot = this.sessionData.facebook_bot;
                }
            }catch(e){
                scope.handleSessionDataError('Cannot determine if facebook bot',LRBErrorTracking.LEVEL.ERROR,'production',true);
            }

            // Check for subscription codes data to update UI with
            try{
                this.subCodes = this.sessionData.sub_codes;
            }catch(e){
                scope.handleSessionDataError('Cannot load subscription codes',LRBErrorTracking.LEVEL.ERROR,'production',true);
            }

            // Check for tracking data for GA and other reporting endpoints
            try{
                this.trackingData = this.sessionData.tracking_data;
                if(this.trackingData){
                    if(this.trackingData.hasOwnProperty('user_id')){
                        var userID = this.trackingData.user_id;
                        if(userID && userID != ''){
                            this.userID = userID;
                            global.GA4.setUserID(userID);
                        }
                    }
                }
            }catch(e){
                scope.handleSessionDataError('Cannot load tracking data','error','production',true);
            }
        }

        global.LRBErrorTracking.setPageContext();

        this.setReferrerData();
        this.setSubXData();
        this.updatePage();
    }

    setReferrerData(){
        if(document.referrer && document.referrer != ""){
            const referrer = document.referrer.toLowerCase();

            /*
                /^              # ensure start of string
                http            # match 'http'
                s?              # 's' if it exists is okay
                :\/\/           # match '://'
                ([^\/]+\.)?     # match any non '/' chars followed by a '.' (if they exist)
                reddit\.com     # match 'reddit.com'
                (\/|$)          # match '/' or the end of the string
                /i              # match case-insenitive
            */
            const googleRegExp = new RegExp(/^https?:\/\/([^\/]+\.)?google\.c/i);
            const facebookRegExp = new RegExp(/^https?:\/\/([^\/]+\.)?facebook\.c/i);
            const instagramRegExp = new RegExp(/^https?:\/\/([^\/]+\.)?instagram\.c/i);
            const youtubeRegExp = new RegExp(/^https?:\/\/([^\/]+\.)?youtube\.c/i);
            const redditRegExp = new RegExp(/^https?:\/\/([^\/]+\.)?reddit\.c/i);
            const threadsRegExp = new RegExp(/^https?:\/\/([^\/]+\.)?threads\.c/i);
            const twitterRegExp = new RegExp(/^https?:\/\/([^\/]+\.)?t\.c/i);
            const twitterLongRegExp = new RegExp(/^https?:\/\/([^\/]+\.)?twitter\.c/i);

            if(googleRegExp.test(referrer)){
                // Google
                this.subXData.referrer.google = true;
            }else if(facebookRegExp.test(referrer)){
                // Facebook
                this.subXData.referrer.facebook = true;
            }else if(instagramRegExp.test(referrer)){
                // Instagram
                this.subXData.referrer.instagram = true;
            }else if(youtubeRegExp.test(referrer)){
                // Youtube
                this.subXData.referrer.youtube = true;
            }else if(redditRegExp.test(referrer)){
                // Reddit
                this.subXData.referrer.reddit = true;
            }else if(threadsRegExp.test(referrer)){
                // Threads
                this.subXData.referrer.threads = true;
            }else if(twitterRegExp.test(referrer) || twitterLongRegExp.test(referrer)){
                // Twitter
                this.subXData.referrer.twitter = true;
            }
        }
    }

    setSubXData(){
        // Check for tracking data for SubX
        try{
            if(this.trackingData){

                // Duplicate and merge trackingData into subXData
                // subXData will change and alter values
                // but trackingData should remain the same for sending to GA
                this.subXData = { ...this.subXData, ...this.trackingData };
                this.subXData.session_set = true;

            }
        }catch(e){
            scope.handleSessionDataError('Cannot load SubX Data','error','production',true);
        }

        // Merge in content data if set from metadatacontroller
        this.subXData.content = {};
        if(window.lrbContentData){

            // Duplicate lrbContentData
            // subXData will change and alter values
            // lrbContentData may be referenced in future or checked for debug
            this.subXData.content = { ...window.lrbContentData };

            // Check for URLs that should be forced to have APW enabled
            // If a wildcard is at the end of the URL then all child pages will have APW enabled (but not the parent)
            /*for (let i = 0; i < this.tempForceAPWonURLs.length; i++) {
                let forcedUrl = this.tempForceAPWonURLs[i];
                let wildcardUrl = false;
                if (forcedUrl.endsWith('*')) wildcardUrl = true;
                forcedUrl = forcedUrl.replace('*','');

                if(window.location.pathname.includes(forcedUrl)){
                    // Check if the forcedUrl ends with a wildcard character
                    if(wildcardUrl){
                        // This is a wildcard URL, so should only to URLs that are children of this folder
                        const childURL = window.location.pathname.replace(forcedUrl,'').replace('/','');
                        if(childURL.length > 0){
                            this.subXData.content.apw = true;
                        }
                    }else{
                        this.subXData.content.apw = true;
                    }
                }
            }

            // Make any temporary un-force of a_pw based on URLs
            for (let i = 0; i < this.tempUnforceAPWonURLs.length; i++) {
                const unforcedUrl = this.tempUnforceAPWonURLs[i];
                if(window.location.pathname.includes(unforcedUrl)){
                    this.subXData.content.apw = false;
                }
            }*/

            // apw is addtional_paywall - notes that this page should be elligible for paywall
            // forces s_pw to be true to ensure SubX picks up that this should be considered for paywall in all cases
            // without forcing s_pw we see inconsistent application of Blog paywall on SubX's side
            // So setting s_pw true here for stability of paywall on non-article pages
            if(this.subXData.content.hasOwnProperty('apw')){
                if(this.subXData.content.apw){
                    this.subXData.a_pw = true;
                    this.subXData.s_pw = true;
                }
                delete this.subXData.content.apw;
            }
          
            // Check for paywall overrides
            // opw is override_and_hide_paywall - notes that the paywall should never be displayed on this page
            if(this.subXData.content.hasOwnProperty('opw')){
                this.subXData.o_pw = this.subXData.content.opw;
                if(this.subXData.o_pw){
                    // enforce this due to inconsistent results from SubX's implementation
                    this.subXData.a_pw = false;
                    this.subXData.s_pw = false;
                }
                delete this.subXData.content.opw;
            }
          
            // fpw is fallback_paywall - subx should not be used at all for paywall, and our own internal fallback paywall used and enabled immediately
            // Sets subx paywall values to false
            if(this.subXData.content.hasOwnProperty('fpw')){
                if(this.subXData.content.fpw){
                    this.subXData.a_pw = false;
                    this.subXData.s_pw = false;
                    this.subXData.o_pw = false;
                    this.subXData.f_pw = true;
                }
                delete this.subXData.content.fpw;
            }

            // Cleanup subject strings
            if(this.subXData.content.hasOwnProperty('category')){
                this.subXData.content.category = this.subXData.content.category.replace("&amp;", "&");
            }

        }

        // Internal paywall checks
        global.DigitalEditionPaywall.checkDigitalPaywall();
        global.LRBPaywall.checkFallbackPaywall();
        global.LRBPaywall.checkForcedPaywallDisplay();

        // Set article type if present
        var articleTypeMeta = document.querySelector("meta[property='article:type']");
        if(articleTypeMeta){
            var articleType = articleTypeMeta.getAttribute("content");
            if(articleType && articleType!= ''){
                this.subXData.content.article_type = articleType;
            }
        }

        // Set content type for SubX to determine where to apply the paywall by default: 'Article'
        this.subXData.content.content_type = global.LRBTracking.getPageGroup();

        // Check for courses pages
        if(this.subXData.content.content_type == global.LRBTracking.contentGroups.COURSES){
            this.subXData.s_pw = false;
        }

        // If SubX is not enabled then do not send data to datalayer
        // SubX JS is also not loaded if this value is not true
        // SubX data in window.LRBSessionData.subXData will still be accessible for debug
        if(!window.subxenabled){
            return false;
        }

        global.LRBDataLayer.pushData(this.subXData);
        this.setSubXValuesInForms();
    }

    // Set hidden subx values into signup and newsletter forms
    setSubXValuesInForms(){
        const subXCID = global.utils.getURLParam('utm_campaign');
        if(subXCID && subXCID != '' && $('#subx_campaign_id').length > 0){
            $('#subx_campaign_id').val(subXCID);
        }

        const subXCName = global.utils.getURLParam('utm_content');
        if(subXCName && subXCName != '' && $('#subx_campaign_name').length > 0){
            $('#subx_campaign_name').val(subXCName);
        }
    }

    handleSessionDataError(error, severity = LRBErrorTracking.LEVEL.ERROR, env = null, avoidIntranet = false){
        var skipError = false;
        var reason = '';

        // Do not report session errors on URLs that do not get sessions set in Preflight (lrb_preflight.vcl)
        if(this.checkIfNonSessionUrl()){
            skipError = true;
            reason = ' (no session set on this URL)';
        }

        if(!skipError){
            global.LRBErrorTracking.captureMessage(error,severity,env,avoidIntranet);
        }else{
            console.error(error);
            console.log('Not reporting error to ErrorTracking'+reason, error);
        }
    }

    checkIfNonSessionUrl(url){
        if(!url){
            url = window.location.href;
        }
        // Check if page is a on a list of URLs that do not get sessions set in Preflight (lrb_preflight.vcl)
        var ignoreUrl = false;
        for (var i = 0; i < NON_SESSION_URLS.length; i++) {
            const nonSessionUrl = NON_SESSION_URLS[i];
            if(url.toLowerCase().includes(nonSessionUrl.toLowerCase())){
                ignoreUrl = true;
            }
        }
        return ignoreUrl;
    }

    /* Update UI logged in states based on session cookie */
    updatePage(dataOverride = null) {
        global.LRBErrorTracking.setContext('LRBSessionData updatePage');

        let data = null;
        let scope = this;

        // Overwrite session data from object if present and not in production, used for debug purposes
        if(dataOverride && process.env.NODE_ENV != "production" && process.env.NODE_ENV != "prod"){
            data = JSON.parse(dataOverride);
        }else{
            data = global.LRBSessionData.sessionData;
        }
        
        global.LRBErrorTracking.setPageContext();

        try {
            scope.updateInstitutionalLogin(data);
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('updateInstitutionalLogin Error',error);
        }
        try {
            scope.updateLoginUI();
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('updateLoginUI Error',error);
        }
        try {
            scope.updateSubscriptionCodes();
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('updateSubscriptionCodes Error',error);
        }
    }

    /*  Update account login UI buttons according to session state */
    updateLoginUI(){
        if(global.LRBSessionData.loggedIn === true){

            /* Logged in */
            // My Account button
            $('#header-my-account').show().attr('aria-hidden',false);
            $('#header-sign-in').hide().attr('aria-hidden',true);
            $('.header-container').addClass('logged-in');
            // Sidenav logged in button
            $('.subnavlink-loggedIn').show();
            $('.subnavlink-loggedOut').remove();

            // In this issue block
            $('.in-this-issue-links #issue-read').css('display','block').attr('aria-hidden',false);
            $('.in-this-issue-links #issue-login').hide().attr('aria-hidden',true);

            // Account links
            $('#account-links .account-links-myaccount').css('display','block').attr('aria-hidden',false);
            $('#account-links .account-links-login').hide().attr('aria-hidden',true);

            // Sitemap links
            $('#sitemap-block-links #sitemap-myaccount').show().attr('aria-hidden',false);
            $('#sitemap-block-links #sitemap-logout').show().attr('aria-hidden',false);
            $('#sitemap-block-links #sitemap-login').hide().attr('aria-hidden',true);

            $('.header-container').addClass('logged-in');
            $('.lrb-content-container, html').addClass('logged-in');
            /* End Logged in */

        }else{

            /* Logged out */
            $('#header-sign-in').show().attr('aria-hidden',false);
            /* End Logged out */

        }
    }

    /*  Update institutional login UI buttons according to session state - data is lrb_session cookies passed from updatePage() */
    updateInstitutionalLogin(data){
        if(data){

            // Set the Institutional access banner; i.e. 'Your access is being provided by the LRB' shown at the top of the main navigation
            /* Inst Access Banner */
            if(data.Inst){
                if(data.Inst.hasOwnProperty('Organisation')){

                    // Institution Organisation is set, so show the banner
                    let message = `${data.Inst.Organisation} doesn't have a valid subscription`;

                    if(data.Inst.Licences){
                        // Institution has a valid licence, update message as such
                        message = `Your access is being provided by ${data.Inst.Organisation}`;
                    }

                    $('.access-notification').text(message);
                }
            }
            /* End Inst Access Banner */

            // Update Institutional access links according to admin login state
            /* Inst Access Links */
            if(data.hasOwnProperty('institutional_access')){
                if(data.institutional_access.institutionAdmin){
                    // Logged in as admin
                    $('#institution_admin_logged_in').show().attr('aria-hidden',false);
                } else {
                    // Not logged in as admin
                    $('#institution_admin_logged_out').css('display', 'inline-block').attr('aria-hidden',false);
                }
            }
            /* End Inst Access Links */

        }else{

            // Institutional data not set, clean up UI
            /* Missing Inst Data */
            $('#institution_admin_logged_out').css('display', 'inline-block').attr('aria-hidden',false);
            console.info('Cannot update InstUI - missing data');
            /* End Missing Inst Data */

        }
    }

    /* Update subscription URLs with geolocated URL, including campaign codes set in Office admin */
    updateSubscriptionCodes(){
        global.LRBErrorTracking.setContext('LRBSessionData updateSubscriptionCodes');

        let scope = this;

        // Load subscription codes - returns sub codes according to geo location
        console.log('Checking for subscription links...');
        var data = this.subCodes;
        if(!data){
            console.info('Loading fallback subcodes...');
            var url = '/ajax/sub-codes';
            if(!window.location.origin.includes('lrb.co.uk') && !window.location.origin.includes('lrb-intranet')){
                url = 'https://www.lrb.co.uk/ajax/sub-codes';
            }
            $.getJSON( url, function(){})
            .done(function(fallbackData) {
                console.log('FBD',fallbackData);
                if(fallbackData){
                    data = fallbackData;
                }else{
                    console.error("Could not load fallback subcodes");
                    //global.LRBErrorTracking.captureMessage("Could not load fallback subcodes",LRBErrorTracking.LEVEL.ERROR,'production',true);
                }
            })
            .fail(function(e) {

                // Error loading '/ajax/sub-codes'
                var error = "Fallback subcodes error";
                console.log(error);
                //global.LRBErrorTracking.captureMessage(error,LRBErrorTracking.LEVEL.ERROR,'production',true);

            })
            .always(function() {

                // handle subs data after XHR complete
                scope.handleSubscriptionCodeData(data);

            });
        }else{

            // handle preflight passed subs data
            scope.handleSubscriptionCodeData(data);

        }

        global.LRBErrorTracking.setPageContext();
    }

    handleSubscriptionCodeData(data){
        if(data){
            var subLinks = $('a.js-subLink');
            if(subLinks && subLinks.length > 0){
    
                /* Handle sub-codes data */
                console.log("Subscription Codes loaded", data);
                // Update subscription code link hrefs to geo located ones from loaded data
                // Looks through all a link tags with class '.js-subLink', and matches campaign codes from Office admin based on data-cid on that link tag
                subLinks.each(function(){
                    var subLink = $(this);
                    var campaignID = subLink.data('cid');
                    var subCodeURL = '';
    
                    // If USCA is defined then this is a USCA user, so set all sub URLs to that one
                    // Otherwise set to the matching campaign ID to get codes as set in Office admin
                    if(campaignID && campaignID != '' && data.hasOwnProperty(campaignID)){
                        subCodeURL = data[campaignID];
                    }
    
                    // if subCodeURL not set from data then try to set to fallback URL from data
                    if(subCodeURL == '' && data.hasOwnProperty('fallback_url')){
                        if(data['fallback_url'] && data['fallback_url'] != ''){
                            subCodeURL = data['fallback_url'];
                        }
                    }
    
                    console.log('Checking for subcode URL for campaign ID '+campaignID+' -- '+subCodeURL);
                    // If a new URL is set for this link (either from geo location, or a campaign code in Office admin) then update the href
                    if(subCodeURL){
                        subLink.attr('href',subCodeURL);
                    }else{
                        console.error("Could not find subcode: "+campaignID);
                        global.LRBErrorTracking.captureMessage("Could not find subcode: "+campaignID,LRBErrorTracking.LEVEL.ERROR,'production',true);
                    }
                });
                /* End Handle sub-codes data */
    
            }
        }
    }



    /* Debug methods for testing updatePage() locally without Fastly preflight cookie */

    /*dummyLogin(){
        var data = {
            "paywall":{
                "subscribed":true,
                "type":"Temporary Access (Subscription Pending)",
                "expires":null,
                "article_access":"temp",
                "sub_system":"TEMP",
                "logged_in":true
            },
            "Inst":null,
            "institutional_access":{
                "organisation":false,
                "institutionAdmin":false,
                "institutionAccess":"",
                "ga_organisation":""
            }
        };
        this.updatePage(JSON.stringify(data));
    }

    dummyInstLoginExpired(){
        var data = {
            "paywall":{
                "subscribed":true,
                "type":"Temporary Access (Subscription Pending)",
                "expires":null,
                "article_access":"temp",
                "sub_system":"TEMP",
                "logged_in":true
            },
            "Inst":{
                "Organisation":'Testing'
            },
            "institutional_access":{
                "organisation":false,
                "institutionAdmin":false,
                "institutionAccess":"",
                "ga_organisation":""
            }
        };
        this.updatePage(JSON.stringify(data));
    }
    dummyInstLogin(){
        var data = {
            "paywall":{
                "subscribed":true,
                "type":"Temporary Access (Subscription Pending)",
                "expires":null,
                "article_access":"temp",
                "sub_system":"TEMP",
                "logged_in":true
            },
            "Inst":{
                "Organisation":'Testing',
                "Licences":true
            },
            "institutional_access":{
                "organisation":false,
                "institutionAdmin":false,
                "institutionAccess":"",
                "ga_organisation":""
            }
        };
        this.updatePage(JSON.stringify(data));
    }*/

}

module.exports = { LRBSessionData };
