class Utilities {
    constructor() {}

    //==========================
    // Check for emojis
    //==========================
    isEmoji(str){
        if(str.match("(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c\ude32-\ude3a]|[\ud83c\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])")) {
            return true;
        }else{
            return false;
        }
    }

    updateURL(href) {
        if (history && history.pushState && href) {
            history.pushState(null, null, href);
        }
    }

    updateTitle(title) {
        if (title) {
            document.title = title;
        }
    }

    scrollToTop(t) {
		if(!$('html').hasClass('scrollingToTop')){
			console.log('[Scroll To Top]');
			var nt = t;
			if (!nt || nt <= 0) { nt = 800; }
			$('html').addClass('scrollingToTop');
			$('html, body').animate({ scrollTop: 0 }, nt, "swing", function(){
				$('html').removeClass('scrollingToTop');
			});
		}
    }

    scrollTo(jQueryElement, offset = 0, t = 800) {
        console.log('scrolling:');
        console.log(jQueryElement && jQueryElement.length > 0);
        if (jQueryElement && jQueryElement.offset()) {
            try {
                $('html, body').animate({ scrollTop: jQueryElement.offset().top - offset }, t);
            } catch (e) {
                global.LRBErrorTracking.error(e);
            }
        }
    }

    escapeHTML(unsafe) {
        let div = document.createElement('div');
        div.innerText = unsafe;
        return div.innerHTML;
    }

    unescapeHTML(html) {
        const txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    //trigger something at a const max rate
    //const myHandler = (event) => // do something with the event
    //const tHandler = throttled(200, myHandler);
    //domNode.addEventListener("mousemove", tHandler);
    throttled(delay, fn) {
      let lastCall = 0;
      return function (...args) {
        const now = (new Date).getTime();
        if (now - lastCall < delay) {
          return;
        }
        lastCall = now;
        return fn(...args);
      }
    }

    //trigger something only once interaction is stopped, e.g. typing
    //const myHandler = (event) => // do something with the event
    //const dHandler = debounced(200, myHandler);
    //domNode.addEventListener("input", dHandler);
    debounced(delay, fn) {
      let timerId;
      return function (...args) {
        if (timerId) {
          clearTimeout(timerId);
        }
        timerId = setTimeout(() => {
          fn(...args);
          timerId = null;
        }, delay);
      }
    }

    interval(func, wait, times){
        var interv = function(w, t){
            return function(){
                if(typeof t === "undefined" || t-- > 0){
                    setTimeout(interv, w);
                    try{
                        func.call(null);
                    }
                    catch(e){
                        t = 0;
                        //throw e.toString();
                    }
                }
            };
        }(wait, times);

        setTimeout(interv, wait);
    };

    setupClearFocusForms() {
        $(".clearMeFocus").focus(function() {
            if ($(this).val() == $(this).attr("title")) {
                $(this).val("");
            }
        });
        $(".clearMeFocus").blur(function() {
            if ($(this).val() == "") {
                $(this).val($(this).attr("title"));
            }
        });
    }

    getMaxHeight(el, outerHeight = false, padding = false){
        var maxHeight = 0;
        if(outerHeight){
            if(padding){
                maxHeight = Math.max.apply(null, el.map(function(){return $(this).outerHeight(true);}).get());
            }else{
                maxHeight = Math.max.apply(null, el.map(function(){return $(this).outerHeight();}).get());
            }
        }else{
            maxHeight = Math.max.apply(null, el.map(function(){return $(this).height();}).get());
        }
        return maxHeight;
    }

    updateSizeRefs() {
        //Update size references
        if (typeof(window.innerWidth) === 'number') {
            //Non-IE
            global.viewportWidth = window.innerWidth;
            global.viewportHeight = window.innerHeight;
        } else if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
            //IE 6+ in 'standards compliant mode'
            global.viewportWidth = document.documentElement.clientWidth;
            global.viewportHeight = document.documentElement.clientHeight;
        } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
            //IE 4 compatible
            global.viewportWidth = document.body.clientWidth;
            global.viewportHeight = document.body.clientHeight;
        }
    }

    measureScrollBarWidth() {
        var scrollDiv1 = document.createElement("div");
        var scrollDiv2 = document.createElement("div");
        scrollDiv1.className = "scrollbar-measure";
        document.body.appendChild(scrollDiv1);
        scrollDiv1.appendChild(scrollDiv2);
        scrollDiv1.style.height = "40px";
        scrollDiv2.style.height = "100px";
        global.scrollbarWidth = scrollDiv1.offsetWidth - scrollDiv2.offsetWidth;
        document.body.removeChild(scrollDiv1);
    }

    getCookie(name){
        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }

    setCookie(name, value, days){
        var d = new Date;
        d.setTime(d.getTime() + 24*60*60*1000*days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    }

    deleteCookie(name){
        this.setCookie(name, '', -1);
    }

    getSanitisedURL(){
        return window.location.origin + window.location.pathname;
    }

    getURLParam(name){
        var urlParam = new URLSearchParams(window.location.search).get(name);
        if(urlParam && urlParam != ""){
            return urlParam;
        }else{
            return "";
        }
    }

    getURLHash(){
        var urlHash = window.location.hash;
        if(urlHash && urlHash != ""){
            return urlHash;
        }else{
            return "";
        }
    }

    detectBot(userAgent){
        const robots = new RegExp([
            /bot/,/spider/,/crawl/,                            // GENERAL TERMS
            /apis-google/,/adsbot/,/googlebot/,                // GOOGLE ROBOTS
            /mediapartners/,/google favicon/,
            /feedfetcher/,/google-read-aloud/,
            /duplexweb-google/,/googleweblight/,
            /bing/,/yandex/,/baidu/,/duckduck/,/yahoo/,        // OTHER ENGINES
            /ecosia/,/ia_archiver/,/slurp/,
            /facebook/,/instagram/,/pinterest/,/reddit/,       // SOCIAL MEDIA
            /slack/,/twitter/,/whatsapp/,/youtube/,
            /semrush/,/yeti/,/gitcrawlerbot/,/phantomjs/,       // OTHER
            /samsung/,/lua-resty-http/,/ngx_lua/,
            /dalvik/,/screaming/,/fbav/,/fb_iab/,
            /fiery feed/,/fiery%20feed/
        ].map((r) => r.source).join("|"),"i");               // BUILD REGEXP + "i" FLAG
        return robots.test(userAgent.toLowerCase());
    };

    detectHeadlessBrowser(userAgent){
        const robots = new RegExp([
            /selenium/,/phantomjs/,
        ].map((r) => r.source).join("|"),"i");
        if(robots.test(userAgent.toLowerCase())) return true;

        var documentDetectionKeys = [
            "__webdriver_evaluate",
            "__selenium_evaluate",
            "__webdriver_script_function",
            "__webdriver_script_func",
            "__webdriver_script_fn",
            "__fxdriver_evaluate",
            "__driver_unwrapped",
            "__webdriver_unwrapped",
            "__driver_evaluate",
            "__selenium_unwrapped",
            "__fxdriver_unwrapped",
        ];

        var windowDetectionKeys = [
            "_phantom",
            "__nightmare",
            "_selenium",
            "callPhantom",
            "callSelenium",
            "_Selenium_IDE_Recorder",
        ];

        for (const windowDetectionKey in windowDetectionKeys) {
            const windowDetectionKeyValue = windowDetectionKeys[windowDetectionKey];
            if (window[windowDetectionKeyValue]) {
                return true;
            }
        };
        for (const documentDetectionKey in documentDetectionKeys) {
            const documentDetectionKeyValue = documentDetectionKeys[documentDetectionKey];
            if (window['document'][documentDetectionKeyValue]) {
                return true;
            }
        };

        for (const documentKey in window['document']) {
            if (documentKey.match(/\$[a-z]dc_/) && window['document'][documentKey]['cache_']) {
                return true;
            }
        }

        if (window['external'] && window['external'].toString() && (window['external'].toString()['indexOf']('Sequentum') != -1)) return true;

        if (window['document']['documentElement']['getAttribute']('selenium')) return true;
        if (window['document']['documentElement']['getAttribute']('webdriver')) return true;
        if (window['document']['documentElement']['getAttribute']('driver')) return true;

        return false;
    };

}

module.exports = { Utilities };
