/*
* Paywall for the Digital Edition page located at http://www.lrb.co.uk/4d422a2b-60d3-457f-8754-e8a09ebe3fb1
*/

class DigitalEditionPaywall {
    constructor() {
        window.lrbDEPW = this.showDigitalEditionPaywall;
        window.lrbDE = this.showDownloadLinks;
    }

    setup() {
        if($('.section-4d422a2b-60d3-457f-8754-e8a09ebe3fb1 .print-issue-holder').length > 0){
            console.log('%cDigital Edition Paywall Setup', `background-color: ${global.warnBG};`);
        }
    }

    checkDigitalPaywall(){
        // Setup Digital Paywall if needed
        // Should trigger whenever someone does not have article access
        // i.e. s_pw (show paywall) is true
        if(global.LRBSessionData.subXData.s_pw){
            global.DigitalEditionPaywall.showDigitalEditionPaywall();
        }else{
            global.DigitalEditionPaywall.showDownloadLinks();
        }
    }

    showDownloadLinks(){
        $('.section-4d422a2b-60d3-457f-8754-e8a09ebe3fb1 .print-download-link, .archive-container .downloadLink').fadeIn(600);
        $('.section-4d422a2b-60d3-457f-8754-e8a09ebe3fb1 .print-archive-container').removeAttr('style');
        $('.section-4d422a2b-60d3-457f-8754-e8a09ebe3fb1 .print-issue-holder .print-download-link').fadeIn(600);
        $('.section-4d422a2b-60d3-457f-8754-e8a09ebe3fb1 .print-issue-holder .print-login-link').remove();
    }

    showDigitalEditionPaywall(){
        $('.section-4d422a2b-60d3-457f-8754-e8a09ebe3fb1 .print-archive-container .js-downloadLink .article-issue-wrapper').unwrap();
        $('.section-4d422a2b-60d3-457f-8754-e8a09ebe3fb1 .print-archive-container .downloadLink').remove();
        $('.section-4d422a2b-60d3-457f-8754-e8a09ebe3fb1 .print-issue-holder .print-download-link').remove();
        $('.section-4d422a2b-60d3-457f-8754-e8a09ebe3fb1 .print-issue-holder .print-login-link').fadeIn(600);
    }

}

module.exports = { DigitalEditionPaywall };
