const SITE_KEY = "6LczS7oSAAAAAAsRvla1WKdNqLQdw95-8QSnBSYT";

class LRBRecaptcha {
    constructor() {
        this.recaptchaSetup = false;
    }

    setup() {

        try {
            if($('.g-recaptcha').length > 0){
                console.log('%cLRBRecaptcha Setup', `background-color: ${global.infoBG};`);
                this.setupGoogleRecaptchas();
            }
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Recaptcha',error);
        }

    }

    setupGoogleRecaptchas(){
        console.log('Initialising Recaptchas');
        $('.g-recaptcha').each(function(i, el) {
            if($(this).hasClass('grsetup') || this.recaptchaSetup){
                return false;
            }

            if(window.grecaptcha && window.grecaptcha.render){
                window.grecaptcha.render(el, {
                    'sitekey' : SITE_KEY,
                    'theme' : $(el).attr('data-theme'),
                    'size' : $(el).attr('data-size'),
                    'tabindex' : $(el).attr('data-tabindex'),
                    'callback' : $(el).attr('data-callback'),
                    'expired-callback' : $(el).attr('data-expired-callback'),
                    'error-callback' : $(el).attr('data-error-callback')
                });
                $(this).addClass('grsetup');
                this.recaptchaSetup = true;
            }else{
                console.error('Error rendering recaptcha');
            }

        });
    }

    destroy(){
    }

}

module.exports = { LRBRecaptcha };
