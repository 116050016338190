class LRBAddressAutoComplete {

    /*
    Used for Contributor static forms, and DSB / PCD account address forms
    */

    constructor() {
        window.lrbInitAutoComplete = this.initAutocomplete;
        window.fillInAddress = this.fillInAddress;
        window.lrbGeolocate = this.geolocate;

        window.componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            neighborhood: 'short_name',
            postal_town: 'long_name',
            locality: 'long_name',
            administrative_area_level_2: 'long_name',
            administrative_area_level_1: 'long_name',
            country: 'short_name',
            postal_code_prefix: 'short_name',
            postal_code: 'short_name',
            postal_code_suffix: 'short_name'
        };
    }

    // Uses the Autocomplete widget to help the user select a
    // place, then it retrieves the address components associated with that
    // place, and then it populates the form fields with those details.
    // This requires the Places library, included in the head of the page
    initAutocomplete(_lrbFormMap, elID, restrictUS = false) {
        if(!_lrbFormMap){
            return;
        }

        console.log('%cLRBAddressAutoComplete Setup', `background-color: ${global.infoBG};`);

        if(!_lrbFormMap){
            console.error("[LRBFormMap parameter not provided]");
        }

        // Create the autocomplete object, restricting the search predictions to
        // geographical location types.
        var autocomplete = new google.maps.places.Autocomplete(
            document.getElementById(elID), {types: ['geocode']});

        if(autocomplete){
            if (restrictUS) {
                window.componentForm.administrative_area_level_1 = 'short_name';
                // Restrict the search to the list of countries
                autocomplete.setComponentRestrictions(
                    {'country': ['us', 'ca', 'vi', 'gu', 'mp'] });
            }

            // Avoid paying for data that you don't need by restricting the set of
            // place fields that are returned to just the address components.
            autocomplete.setFields(['address_component']);

            var compForm = window.componentForm;

            // When the user selects an address from the drop-down, populate the
            // address fields in the form.
            autocomplete.addListener('place_changed', function(){
                console.warn("Address Lookup changed: "+elID);
                // Get the place details from the autocomplete object.
                var place = autocomplete.getPlace();
                window.fillInAddress(this.autocomplete, place, _lrbFormMap, compForm);
            });
        }

    }

    // Pass in autocopmplete object, the "place" identified by the address lookup, the map of address attributes to the
    // form elements and the address attributes to use
    fillInAddress(autocomplete, place, lrbFormMap, componentForm) {
        for (var component in lrbFormMap) {
            // Clear out any entries in the current address form
            var docCompEl = document.getElementById(component);
            if(docCompEl){
                docCompEl.value = '';
                docCompEl.disabled = false;
            }
        }

        //Check that Google Place and ComponentForm objects exist
        if(!place){
            console.error("[Google Place not defined]");
            return false;
        }else{
            console.log("Google Place...");
            console.log(place);
        }
        //Check that Google Place address_components exist
        if(!place.address_components){
            console.error("[Google Place Address Components not defined]");
            return false;
        }else{
            console.log("Google Place Address Components...");
            console.log(place.address_components);
        }
        if(!componentForm){
            console.error("[componentForm not defined]");
            return false;
        }else{
            console.log("Component Form...");
            console.log(componentForm);
        }

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        var indx = 0;
        outer: for (indx; indx < place.address_components.length; indx++) {
            var addressType = place.address_components[indx].types[0];
            if (componentForm[addressType]) {
                // If the address attribute matches
                var val = place.address_components[indx][componentForm[addressType]];
                if(val){
                    inner: for (var addressPart in lrbFormMap) {
                        // Loop through each field (addresspart) in the displayed form and match with the values returned by google
                        // Addresspart MIGHT be an array ie value is derived from more than 1 google attribute
                        if (lrbFormMap[addressPart].length == 1 && lrbFormMap[addressPart][0] == addressType ) {
                            // Single value
                            if (typeof document.getElementById(addressPart).options == 'undefined') {
                                // Not a select element - write value into form field
                                document.getElementById(addressPart).value = val;
                            } else {
                                // Select element - find the correct option to select
                                var i = 0;
                                var j = document.getElementById(addressPart).options.length;
                                for (i; i< j; i++){
                                    if (document.getElementById(addressPart).options[i].value == val) {
                                        document.getElementById(addressPart).selectedIndex = i;
                                        $('#'+addressPart).trigger('change');
                                        i = j;
                                    }
                                }

                            }
                            // This field has been updated, skip the rest and move on to the next value
                            continue outer;
                        } else {
                            // Form field value is derived from a combination of google attributes
                            var p=0;
                            var googleAttribCount = lrbFormMap[addressPart].length;
                            for(p; p < googleAttribCount; p++){
                                if (lrbFormMap[addressPart][p] == addressType){
                                    // Add the attribute value to the form
                                    if (document.getElementById(addressPart).value == '' ) {
                                        // initial value
                                        document.getElementById(addressPart).value = val;
                                    } else {
                                        if (document.getElementById(addressPart).value.indexOf(val) < 0 ) {
                                            // append any new address information
                                            document.getElementById(addressPart).value += isNaN(document.getElementById(addressPart).value) ?  ", " + val : " " + val;
                                        }
                                    }
                                    p = googleAttribCount;
                                    // Move to the next form field
                                    continue outer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // Bias the autocomplete object to the user's geographical location,
    // as supplied by the browser's 'navigator.geolocation' object.
    geolocate() {
        try{
            if (navigator.geolocation) {
                // Browser is location aware - yay!
                navigator.geolocation.getCurrentPosition(function(position) {

                    if(position){
                        // Get users location - or at least the location the browser thinks it is in
                        var geolocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                        if(geolocation && autocomplete){
                            var circle = new google.maps.Circle({center: geolocation, radius: position.coords.accuracy});
                            autocomplete.setBounds(circle.getBounds());
                        }
                    }

                });
            }
        }catch(e){
            console.log('[Geolocate ERROR] '+e);
        }
    }


    destroy(){
    }

}

module.exports = { LRBAddressAutoComplete };
