require('velocity-animate');

class LRBReadMore {
    constructor() {
    }

    setup() {
        console.log('%cLRBReadMore Setup', `background-color: ${global.infoBG};`);

        this.setupReviewReadMore();
        this.setupReadMoreLinks();
        global.pageReady.updateScrollReveal();
    }

    setupReviewReadMore(){
        try {

            //Generate read more links on article page reviewed items, when more than 3 shown on mobile
            $('.article.defaultArticle #lrb-articleCopy .reviewed-items-holder').each(function(){
                var itemCnt = $(this).find('.article-reviewed-item').length;
                $(this).addClass('cnt-'+itemCnt);
                if(itemCnt > 3){
                    $(this).addClass('collapsing-reviews');

                    $(this).find('.article-reviewed-item').each(function(i){
                        if(i > 2){
                            $(this).addClass('collapsing-review--item');
                        }
                    });

                    $('.collapsing-reviews').each(function(){
                        $(this).find('.collapsing-review--item').wrapAll('<div class="collapsing-review"></div>');
                    });
                }
            });

        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up ReviewReadMore',error);
        }
    }

    setupReadMoreLinks(){
        try {
            //Setup 'Read More' Links, will show/hide the element(s) according to the string in data
            $('.lrb-readmorelink').each(function(){
                if($(this).hasClass('readmore-target-setup')){
                    return false;
                }

                const $target = $($(this).data('readmore-target'));

                var transcriptLink = false;
                if($(this).parents('.paperArticle-transcript').length > 0){
                    transcriptLink = true;
                }
                var reviewedItemsLink = false;
                if($(this).parent().hasClass('reviewed-items-holder')){
                    reviewedItemsLink = true;
                }

                var startCopy = 'Read More';
                if(transcriptLink){
                    startCopy = 'Show More';
                }
                if(reviewedItemsLink){
                    startCopy = 'Show All';
                }

                if($target){

                    $target.hide();
                    $target.addClass('lrb-readmore-target--item');
                    $(this).addClass('readmore-target-setup');
                    
                    $('.lrb-readmorelink').on('click', function(e){
                        e.preventDefault();
                        e.stopPropagation();

                        if(!$('html').hasClass('readmoreAnimating')){
                            $(this).toggleClass('opened');
                            if($(this).hasClass('opened')){

                                var readLessCopy = 'Read Less';
                                if(transcriptLink){
                                    readLessCopy = 'Show Less';
                                }
                                if(reviewedItemsLink){
                                    readLessCopy = 'Show Less';
                                }

                                $target.velocity('stop').velocity("slideDown", {duration: 400});
                                $('html').addClass('readmoreAnimating');
                                $(this).html(readLessCopy);
                                setTimeout(function(){
                                    $('html').removeClass('readmoreAnimating');
                                },500);

                            }else{

                                var readMoreCopy = 'Read More';
                                if(transcriptLink){
                                    readMoreCopy = 'Show More';
                                }
                                if(reviewedItemsLink){
                                    readMoreCopy = 'Show All';
                                }

                                $target.velocity('stop').velocity("slideUp", {duration: 400});
                                $('html').addClass('readmoreAnimating');
                                $(this).html(readMoreCopy);
                                if(!transcriptLink){
                                    global.utils.scrollToTop(400);
                                }

                                setTimeout(function(){
                                    $('html').removeClass('readmoreAnimating');
                                },500);

                            }
                        }

                        return false;
                    });

                    $(this).html(startCopy);

                }else{
                    console.error('No valid target for Read More link');
                    $(this).remove();
                }
            });
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up ReadMore',error);
        }
    }

    destroy(){
    }

}

module.exports = { LRBReadMore };
