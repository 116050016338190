var Mousetrap = require('mousetrap');

class LRBAccessibility {
    constructor() {
    }

    setup() {
        console.log('%cLRBAccessibility Setup', `background-color: ${global.infoBG};`);

        this.setupHotkeys();
    }

    setupHotkeys(){
        // mod is equivalent to CTRL on Windows, and COMMAND on Mac
        //var accessKeys = 'mod';

        // mod conflicts with basic browser shortcuts, use alt instead
        var accessKeys = 'alt';

        if($('html').hasClass('linux')){
            accessKeys = 'ctrl+shift';
        }

        //s - Jump to article body
        Mousetrap.bind(accessKeys+'+s', function() {
            global.utils.scrollTo($('#lrb-articleCopy'), 80);
        });

        //1 - Homepage
        Mousetrap.bind(accessKeys+'+1', function() {
            window.location = "/";
        });

        //2 - Latest Issue
        Mousetrap.bind(accessKeys+'+2', function() {
            window.location = "/the-paper";
        });

        //3 - Site Map
        Mousetrap.bind(accessKeys+'+3', function() {
            window.location = "/sitemap";
        });

        //4 - Search
        Mousetrap.bind(accessKeys+'+4', function() {
            window.location = "/search-results";
        });

        //5 - About
        Mousetrap.bind(accessKeys+'+5', function() {
            window.location = "/about";
        });

        //8 - Terms
        Mousetrap.bind(accessKeys+'+8', function() {
            window.location = "/terms";
        });

        //9 - Contact
        Mousetrap.bind(accessKeys+'+9', function() {
            window.location = "/contacts";
        });

        //0 - Accessibility
        Mousetrap.bind(accessKeys+'+0', function() {
            window.location = "/accessibility";
        });


        Mousetrap.bind('mod+shift+u', function() {
            //global.setupPage.toggleKNMI();
        });

    }

}

module.exports = { LRBAccessibility };
