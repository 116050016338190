const Flickity = require("flickity/dist/flickity.pkgd.js");
const FlickityFullscreen = require("flickity-fullscreen/fullscreen.js");

class LRBLightbox {
    constructor() {
        this.lightbox = null;
    }

    setup() {
        console.log('%cLRBLightbox Setup', `background-color: ${global.infoBG};`);

        try {
            let scope = this;
    
            this.setupLightboxImages();
    
            $(".close-lightbox").on('click', function(e){
                e.preventDefault();
                e.stopPropagation();
    
                scope.closeLightbox();
    
                return false;
            });
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Lightbox',error);
        }

    }

    setupLightboxImages(){

        let scope = this;

        var lightBoxImages = [];
        // Setup lightbox on articles images
        $('article .embedded_image_wrapper').each(function(){

            // Do not add right aligned poem images on article pages
            var isPoemImage = false;
            if($(this).parent().hasClass('align-right') && $('.paperArticle-container').length > 0){
                isPoemImage = true;
            }

            var isBlog = false;
            if($('html').hasClass('lrb-blog')){
                isBlog = true;
            }

            // Do not add gallery images, the gallery has it's own zoom
            if($(this).find('.galleryblock,.lrb-carousel').length == 0 && !isPoemImage){
                $(this).find('.lrb-imageHolder').each(function(){

                    // Only add those that have the zoom data attribute
                    // TODO: do not add those in a href
                    if($(this).find('img').data('zoom') == true || $(this).find('img').data('zoom') == 'true' || isBlog){
                        var caption = $(this).parents('.embedded_image_wrapper--captioned').find('.embedded-image-caption').html();
                        lightBoxImages.push({image:$(this),appsrc:$(this).find('img').data('appsrc'),caption:caption});
                    }

                });
            }
        });
        // Setup lightbox on TOC cover image
        $('.toc-cover-holder .lightbox').each(function(){
            $(this).find('.lrb-imageHolder').each(function(){
                lightBoxImages.push({image:$(this),appsrc:$(this).find('img').data('appsrc'),noIcon:true});
            });
        });

        // Blog header images
        $('.post-content .post-page-image').each(function(){
            if($(this).find('img').data('zoom') == true || $(this).find('img').data('zoom') == 'true'){
                var caption = $(this).data('caption');
                lightBoxImages.push({image:$(this),appsrc:$(this).data('appsrc'),caption:caption});
            }
        });

        if(lightBoxImages.length > 0){
            for(var i = 0; i < lightBoxImages.length; i++){

                var $image = lightBoxImages[i].image;
                var appsrc = lightBoxImages[i].appsrc;

                $image.wrap('<a href="'+appsrc+'" class="lightbox" aria-controls="lightbox-overlay" aria-expanded="false" data-id="'+i+'"></a>');
                // Do not add the zoom icon for those that do not require it, i.e. TOC cover image
                if(!lightBoxImages[i].noIcon){
                    $image.append('<span class="zoom-icon"></span>');
                }

            }

            /*// If the image inside a lightbox link is missing then disable lightbox
            $('a.lightbox').each(function(){
                var missingImg = $(this).find('img.missing-image, img.load-failed');
                if(missingImg.length > 0){
                    $(this).removeClass('lightbox');
                    $(this).find('zoom-icon').remove();
                }
            });*/

            this.setupLightbox(lightBoxImages);
        }else{
            console.debug('Not setting up lightbox images, no images to load');
        }
    }

    setupLightbox(lightBoxImages = []){

        if(lightBoxImages.length > 0){

            let scope = this;
            var $el = $('#lightbox-overlay .lightbox-images');

            if(!$el.hasClass('lightboxSetup')){
                console.debug('Setting up lightbox');

                var draggable = true;
                var useArrows = true;
                if(lightBoxImages.length < 2){
                    draggable = false;
                    useArrows = false;
                }

                // Setup carousel
                scope.lightbox = new Flickity( $el[0], {
                    cellAlign: 'center',
                    contain: true,
                    draggable: draggable,
                    freeScroll: false,
                    prevNextButtons: useArrows,
                    pageDots: false,
                    wrapAround: false,
                    arrowShape: global.LRBCarousel.arrowSVG,
                    setGallerySize: false
                });

                // Add images to carousel, with captions
                for (var i = 0; i < lightBoxImages.length; i++) {
                    var imageClass = 'lightbox-image';
                    var captionHTML = '';
                    if(lightBoxImages[i].caption){
                        imageClass += ' lightbox-image--captioned';
                        captionHTML = '<div class="lightbox-caption">'+lightBoxImages[i].caption+'</div>';
                    }
                    var $img = $('<div class="lightbox-image--holder"><div class="'+imageClass+'">'+ lightBoxImages[i].image.html() +'</div>'+ captionHTML +'</div>');
                    $img.find('.zoom-icon').remove();
                    scope.lightbox.append($img);
                }

                $($el).addClass('lightboxSetup');

                global.LRBCarousel.setupCarouselListeners($el, scope.lightbox);
                global.LRBCarousel.resizeCarousel(scope.lightbox, 100);
            }else{
                console.debug('Skipping lightbox, already setup');
            }

            $('a.lightbox').on('click',function(e){
                e.preventDefault();
                e.stopPropagation();

                $(this).attr('aria-expanded',true);
                scope.openLightbox($(this).data('id'));

                return false;
            });

        }else{
            console.debug('Not setting up lightbox, no images to load');
        }

    }

    openLightbox(index = 0){
        console.log('Open Lightbox: slide '+index);
        $('html').addClass('lrb-lightboxOpen');
        $('#lightbox-overlay').attr('aria-hidden',false);
        this.lightbox.select(index,false,true);
    }

    closeLightbox(){
        $('html').removeClass('lrb-lightboxOpen');
        $('a.lightbox').attr('aria-expanded',false);
        $('#lightbox-overlay').attr('aria-hidden',true);
    }

}

module.exports = { LRBLightbox };
