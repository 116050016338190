const Flickity = require("flickity/dist/flickity.pkgd.js");
const FlickityFullscreen = require("flickity-fullscreen/fullscreen.js");

class LRBCarousel {
    constructor() {
        this.setupComplete = false;

        this.universalCarousels = [];
        this.tabletCarousels = [];
        this.mobileCarousels = [];
        this.galleryCarousels = [];

        this.arrowSVG = "M70,0l9,9.6L37.9,50l41,41l-9,9L20,50L70,0z";
        this.zoomSVG = "M3.7,23.8l7.3-7.3L9.5,15l-7.3,7.3v-7.1c0,0-0.4,0-1.1,0s-1.1,0-1.1,0v9.7C0,25.2,0,26,0,26s0.9,0,1.1,0h9.7c0,0,0-0.4,0-1.1c0-0.5,0-1.1,0-1.1H3.7z M15.2,0c0,0,0,0.4,0,1.1s0,1.1,0,1.1h7.1L15,9.5l1.5,1.5l7.3-7.3v7.1c0,0,0.4,0,1.1,0s1.1,0,1.1,0V1.1C26,0.8,26,0,26,0s-0.8,0-1.1,0H15.2z";
        this.closeZoomSVG = "M18.7,8.9L26,1.6l-1.5-1.5l-7.3,7.3V0.3c0,0-0.4,0-1.1,0c-0.7,0-1.1,0-1.1,0V10c0,0.3,0,1.1,0,1.1s0.9,0,1.1,0h9.7c0,0,0-0.4,0-1.1c0-0.5,0-1.1,0-1.1H18.7z M0.3,14.9c0,0,0,0.4,0,1.1c0,0.7,0,1.1,0,1.1h7.1l-7.3,7.3l1.5,1.5l7.3-7.3v7.1c0,0,0.4,0,1.1,0s1.1,0,1.1,0V16c0-0.3,0-1.1,0-1.1s-0.8,0-1.1,0H0.3z";
        this.zoomViewbox = "0 0 26 26";
        this.closeSVG = "M26,2.3 23.7,0 13,10.7 2.3,0 0,2.3 10.7,13 0,23.7 2.3,26 13,15.3 23.7,26 26,23.7 15.3,13";
        this.prevSVG = "M18.3,26l2.3-2.5L10,13L20.7,2.3L18.3,0l-13,13L18.3,26z";
        this.nextSVG = "M7.7,26l-2.3-2.5L16,13L5.3,2.3L7.7,0l13,13L7.7,26z";
    }

    setup() {
        console.log('%cLRBCarousel Setup', `background-color: ${global.infoBG};`);

        try {
            // Remove 'archive' class from any carousel items
            $('.lrb-carousel-item .from-the-archive').removeClass('from-the-archive');
    
            this.setupComplete = true;
            this.addFullsreenIcon();
            this.setupUniversalCarousels();

            this.cleanupTabletCarousels();
            this.setupTabletCarousels();

            this.cleanupMobileCarousels();
            this.setupMobileCarousels();

            this.setupGalleryCarousels();
            
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Carousels',error);
        }

    }

    // Not currently supported in core, so have to add this way to customise
    // https://github.com/metafizzy/flickity-fullscreen/issues/17
    addFullsreenIcon(){
        let scope = this;
        var pathDirections = {
          view: scope.zoomSVG,
          exit: scope.closeSVG,
        };
        var svgURI = 'http://www.w3.org/2000/svg';
        FlickityFullscreen.FullscreenButton.prototype.createIcon = function() {
          var svg = document.createElementNS( svgURI, 'svg');
          svg.setAttribute( 'class', 'flickity-button-icon' );
          svg.setAttribute( 'viewBox', scope.zoomViewbox );
          // path & direction
          var path = document.createElementNS( svgURI, 'path');
          var direction = pathDirections[ this.name ];
          path.setAttribute( 'd', direction );
          // put it together
          svg.appendChild( path );
          this.element.appendChild( svg );
        };
    }

    //Checks for cases where a gallery displays and goes under the sidenav on an article page
    //Where this occurs a class is added to that gallery element to allow for style changes
    checkArticleSideNavClashes(){
        if(!global.isPrint){
            if($('.section-the-paper .article-sidebar-contents').length > 0){
                var sidebarHeight = $('.article-sidebar-contents').outerHeight(true);

                $('.article-content .gallery-wrapper').each(function(){
                    var galleryYPos = $(this).position().top;
                    //console.log("checkArticleSideNavClashes: ypos:"+galleryYPos+"  sidebarHeight: "+sidebarHeight);
                    if(galleryYPos <= sidebarHeight + 40){
                        $(this).addClass('sidebarAdjacent');
                    }else{
                        $(this).removeClass('sidebarAdjacent');
                    }

                    var flkty = Flickity.data($(this).find('.gallery-block')[0]);
                    if(flkty){
                        flkty.resize();
                    }
                });

                //Add a class to denote when an article ends with a gallery, used for layout fixes
                $('#lrb-articleCopy .article-copy>*').each(function(i){
                    if(i == $('#lrb-articleCopy .article-copy>*').length - 1){
                        if($(this).find('.gallery-wrapper').length > 0){
                            $(this).addClass('articleEndGallery');
                            $(this).parent().parent().parent().addClass('endsWithGallery');
                        }
                    }
                });
            }
        }
    }

    //Ensure captions on gallery are not too wide, overlaying UI
    checkCaptionWidth(){
        if($('.gallery-block').length > 0){
            $('.gallery-block .image-caption').css('max-width', $('.gallery-block').outerWidth(true) - 146 +"px");
        }
    }

    //Check if carousels are allowed to be rendered - blocked when in EZ
    checkIfCarouselAllowed(){
        if($('body').hasClass('ez-page-builder-edit') || global.isPrint || !this.setupComplete){
            return false;
        }else{
            return true;
        }
    }

    setupUniversalCarousels(){
        if(!this.checkIfCarouselAllowed()){
            return false;
        }

        let scope = this;

        $('.lrb-carousel--all').each(function(){
            var $el = $(this);

            if(!$el.hasClass('carouselSetup')){
                console.debug('Setting up carousel--all');

                var cAl = 'left';
                if($el.hasClass('lrb-carousel--centred')){
                    cAl = 'center';
                }

                var wrap = false;
                if($el.hasClass('lrb-carousel--looped')){
                    //wrap = true;
                }

                var flkty = new Flickity( $el[0], {
                    cellAlign: cAl,
                    contain: true,
                    draggable: true,
                    freeScroll: false,
                    prevNextButtons: true,
                    pageDots: false,
                    wrapAround: wrap,
                    arrowShape: scope.arrowSVG
                });

                scope.universalCarousels.push(flkty);

                scope.setupCarouselListeners($el, flkty);

                scope.setupCarouselOuterNav($el, flkty);
                //scope.checkArticleSideNavClashes();
                $($el).addClass('carouselSetup');
                scope.resizeCarousel(flkty, 100);
            }else{
                console.debug('Skipping carousel--all, already setup');
            }
        });

    }
    cleanupUniversalCarousels(){
        if(this.universalCarousels.length > 0){
            console.debug('Destroy carousel--all');
            var i=0;
            for (i; i < this.universalCarousels.length; i++) {
                this.universalCarousels[i].destroy();
            }
            this.universalCarousels = [];
            $('.lrb-carousel--all').removeClass('carouselSetup');

            $('.lrb-carousel--all').parent().find('.carousel-outernav-holder').remove();
            $('.lrb-carousel--all').parents('.carousel-outernav-wrapper').find('.carousel-outernav-holder').remove();
        }
        this.forceDestoryCarousel($('.lrb-carousel--all'));
    }

    setupTabletCarousels(){
        if(!this.checkIfCarouselAllowed()){
            return false;
        }

        let scope = this;

        $('.lrb-carousel--tablet').each(function(){
            var $el = $(this);

            if(!$el.hasClass('carouselSetup')){
                console.debug('Setting up carousel--tablet');

                var flkty = new Flickity( $el[0], {
                    cellAlign: 'left',
                    contain: true,
                    draggable: true,
                    freeScroll: false,
                    prevNextButtons: true,
                    pageDots: false,
                    wrapAround: false,
                    arrowShape: scope.arrowSVG
                });

                scope.tabletCarousels.push(flkty);

                scope.setupCarouselListeners($el, flkty);

                scope.setupCarouselOuterNav($el, flkty);
                //scope.checkArticleSideNavClashes();
                $($el).addClass('carouselSetup');
                scope.resizeCarousel(flkty, 100);
            }else{
                console.debug('Skipping carousel--tablet, already setup');
            }
        });

    }
    cleanupTabletCarousels(){
        if(this.tabletCarousels.length > 0){
            console.debug('Destroy carousel--tablet');
            var i=0;
            for (i; i < this.tabletCarousels.length; i++) {
                this.tabletCarousels[i].destroy();
            }
            this.tabletCarousels = [];
            $('.lrb-carousel--tablet').removeClass('carouselSetup');

            $('.lrb-carousel--tablet').parent().find('.carousel-outernav-holder').remove();
            $('.lrb-carousel--tablet').parents('.carousel-outernav-wrapper').find('.carousel-outernav-holder').remove();
        }
        this.forceDestoryCarousel($('.lrb-carousel--tablet'));
    }

    setupMobileCarousels(){
        if(!this.checkIfCarouselAllowed()){
            return false;
        }

        let scope = this;

        $('.lrb-carousel--mobile').each(function(){
            var $el = $(this);

            if(!$el.hasClass('carouselSetup')){
                console.log('Setting up carousel--mobile');

                var flkty = new Flickity( $el[0], {
                    cellAlign: 'left',
                    contain: true,
                    draggable: true,
                    freeScroll: false,
                    prevNextButtons: true,
                    pageDots: false,
                    wrapAround: false,
                    arrowShape: scope.arrowSVG
                });

                scope.mobileCarousels.push(flkty);

                scope.setupCarouselListeners($el, flkty);

                scope.setupCarouselOuterNav($el, flkty);
                //scope.checkArticleSideNavClashes();
                $($el).addClass('carouselSetup');
                scope.resizeCarousel(flkty, 100);
            }else{
                console.debug('Skipping carousel--mobile, already setup');
            }
        });

    }
    cleanupMobileCarousels(){
        if(this.mobileCarousels.length > 0){
            console.debug('Destroy carousel--mobile');
            var i=0;
            for (i; i < this.mobileCarousels.length; i++) {
                this.mobileCarousels[i].destroy();
            }
            this.mobileCarousels = [];
            $('.lrb-carousel--mobile').removeClass('carouselSetup');

            $('.lrb-carousel--mobile').parent().find('.carousel-outernav-holder').remove();
            $('.lrb-carousel--mobile').parents('.carousel-outernav-wrapper').find('.carousel-outernav-holder').remove();
        }
        this.forceDestoryCarousel($('.lrb-carousel--mobile'));
    }


    setupCarouselOuterNav($carousel, flkty){
        if(!$carousel.hasClass('no-progress')){

            let scope = this;

            let $progressMarkup = $('<div class="carousel-outernav-holder clearfix"><div class="carousel-outernav clearfix"><div class="carousel-outernav--progressIndicator"><div class="progressMarker"></div></div>');
            let $parent = $carousel.parents('.carousel-outernav-wrapper');
            if($parent.length == 0){
                $parent = $carousel.parent();
            }
            $parent.append($progressMarkup);
            let $el = $parent.find('.carousel-outernav-holder');

            if($el.length > 0){

                //Setup Progress Indicator
                var $progressInd = $el.find('.carousel-outernav--progressIndicator');
                var slideCnt = $carousel.find('.lrb-carousel-item').length;
                if($progressInd.length > 0 && slideCnt > 1){
                    var markerW = 100 / slideCnt;
                    $progressInd.find('.progressMarker').css('width', markerW+"%");
                }

            }

            flkty.on('change', function(){
                scope.updateCarouselOuterNav($carousel, flkty);
            });

            scope.updateCarouselOuterNav($carousel, flkty);

        }
    }
    updateCarouselOuterNav($carousel, flkty){
        let $el = $carousel.parent().find('.carousel-outernav-holder');

        if($el.length > 0){

            //Update Progress Indicator
            var $progressInd = $el.find('.carousel-outernav--progressIndicator');
            if($progressInd.length > 0){
                var markerX = flkty.selectedIndex * (Math.floor(100 / $carousel.find('.lrb-carousel-item').length));
                if(flkty.selectedIndex == $carousel.find('.lrb-carousel-item').length - 1){
                    markerX = 100 - (100 / $carousel.find('.lrb-carousel-item').length);
                }
                $progressInd.find('.progressMarker').css('left', markerX+"%");
            }
        }
    }

    resizeCarousel(flkty, delay = 0){
        if(flkty){
            setTimeout(function(){
                flkty.resize();
            }, 1 + delay)
        }
    }


    setupGalleryCarousels(){
        if(!global.isPrint){

            let scope = this;

            var freeScroll = false;
            if(global.touch){
                freeScroll = true;
            }

            $('.lrb-carousel--gallery').each(function(){
                var $el = $(this);

                if(!$el.hasClass('carouselSetup')){
                    console.debug('Setting up carousel--gallery');

                    var flkty = new FlickityFullscreen( $el[0], {
                        cellAlign: 'left',
                        setGallerySize: true,
                        adaptiveHeight: true,
                        contain: true,
                        draggable: true,
                        freeScroll: freeScroll,
                        prevNextButtons: true,
                        pageDots: false,
                        wrapAround: false,
                        arrowShape: scope.arrowSVG,
                        fullscreen: true
                    });

                    scope.galleryCarousels.push(flkty);

                    flkty.on('dragStart', function( event, pointer ) {
                        document.ontouchmove = function (e) {
                            e.preventDefault();
                        }
                    });
                    flkty.on('dragEnd', function( event, pointer ) {
                        document.ontouchmove = function (e) {
                            return true;
                        }
                    });
                    flkty.on('change', function(){
                        scope.updateGalleryOuterNav($el, flkty);
                    });
                    flkty.on('staticClick', function(event, pointer, cellElem, cellIndex){
                        if(cellIndex !== undefined && global.viewportWidth > 768){
                            flkty.select(cellIndex);
                        }
                    });

                    $el[0].addEventListener('load', function(){
                        //Gallery Image Lazy-Loaded, check gallery layout
                        flkty.resize();
                    }, true);

                    scope.checkArticleSideNavClashes();
                    scope.setupGalleryCarouselNav($el);
                    scope.setupGalleryOuterNav($el, flkty);
                    scope.updateGalleryOuterNav($el, flkty);
                    $($el).addClass('carouselSetup');
                }else{
                    console.debug('Skipping carousel--gallery, already setup');
                }

            });

        }

    }
    setupGalleryCarouselNav($gallery){
        let $el = $gallery.parents('.gallery-wrapper').find('.gallery-carouselnav');

        if($el.length > 0){
            //use centre aligning image on old blog
            var imageAlign = 'left';
            if($('html').hasClass('oLrbBlog')){
                imageAlign = 'center';
            }

            var flkty = new Flickity( $el[0], {
                cellAlign: 'left',
                setGallerySize: false,
                adaptiveHeight: false,
                contain: true,
                draggable: true,
                freeScroll: false,
                prevNextButtons: false,
                pageDots: false,
                wrapAround: false,
                asNavFor: $gallery[0],
                cellAlign: imageAlign
            });

            flkty.on('dragStart', function( event, pointer ) {
                document.ontouchmove = function (e) {
                    e.preventDefault();
                }
            });
            flkty.on('dragEnd', function( event, pointer ) {
                document.ontouchmove = function (e) {
                    return true;
                }
            });
        }

    }
    setupGalleryOuterNav($gallery, flkty){
        let $el = $gallery.parents('.gallery-wrapper').find('.gallery-outernav-holder');

        if($el.length > 0){

            //Setup Progress Indicator
            var $progressInd = $el.find('.gallery-outernav--progressIndicator');
            if($progressInd.length > 0){
                var markerW = 100 / $gallery.find('.lrb-carousel-item').length;
                $progressInd.find('.progressMarker').css('width', markerW+"%");
            }

            //Setup Buttons
            $el.find('.gallery-outernav--nav .prev').on('click', function(e){
                e.preventDefault();
                e.stopPropagation();

                flkty.previous();

                return false;
            });
            $el.find('.gallery-outernav--nav .next').on('click', function(e){
                e.preventDefault();
                e.stopPropagation();

                flkty.next();

                return false;
            });

        }
    }
    updateGalleryOuterNav($gallery, flkty){
        let $el = $gallery.parents('.gallery-wrapper').find('.gallery-outernav-holder');

        if($el.length > 0){

            //Update Progress Indicator
            var $progressInd = $el.find('.gallery-outernav--progressIndicator');
            if($progressInd.length > 0){
                var markerX = flkty.selectedIndex * (Math.floor(100 / $gallery.find('.lrb-carousel-item').length));
                if(flkty.selectedIndex == $gallery.find('.lrb-carousel-item').length - 1){
                    markerX = 100 - (100 / $gallery.find('.lrb-carousel-item').length);
                }
                $progressInd.find('.progressMarker').css('left', markerX+"%");
            }

            if(flkty.selectedIndex == 0){
                $el.find('.gallery-outernav--nav .prev').addClass("disabled");
            }else{
                $el.find('.gallery-outernav--nav .prev').removeClass("disabled");
            }
            if(flkty.selectedIndex == $gallery.find('.lrb-carousel-item').length - 1){
                $el.find('.gallery-outernav--nav .next').addClass("disabled");
            }else{
                $el.find('.gallery-outernav--nav .next').removeClass("disabled");
            }

            //Update Caption
            var caption = "&nbsp;";
            if($gallery.find('.lrb-carousel-item.is-selected').find('.image-caption').length){
                caption = $gallery.find('.lrb-carousel-item.is-selected').find('.image-caption').html();
            }
            $el.find('.image-caption').css('opacity',0);
            $el.find('.image-caption').html(caption);
            $el.find('.image-caption').velocity('stop').velocity("fadeIn", {duration: 500})
        }
    }
    cleanupGalleryCarousels(){
        if(this.galleryCarousels.length > 0){
            console.debug('Destroy carousel--gallery');
            var i=0;
            for (i; i < this.galleryCarousels.length; i++) {
                this.galleryCarousels[i].destroy();
            }
            this.galleryCarousels = [];
            $('.lrb-carousel--gallery').removeClass('carouselSetup');
        }
        this.forceDestoryCarousel($('.lrb-carousel--gallery'));
    }

    updateImageCaptionOffsets(){
        if($('html').hasClass('oLrbBlog')){

            $('.lrb-carousel--gallery').each(function(){
                var offset = 0;

                $(this).find('.image-caption').each(function(){
                    offset = Math.max(offset, $(this).outerHeight(true));
                });
                offset += 25;

                $(this).css('margin-bottom',offset+'px');
                $(this).parent().parent().css('margin-bottom', (offset+0) +'px');

            });

        }
    }

    forceDestoryCarousel($carousel){
        // Carousels sometimes not properly destroyed, this is to ensure they are
        // Especially when loaded in mobile view, and expanding viewport
        $carousel.each(function(){
            if($(this).hasClass('flickity-enabled')){
                //has not been destroyed, force destroy
                $(this).removeClass('flickity-enabled is-draggable');
                console.warn("[" + $(this)[0].className + " not destroyed] Force Destroying");

                $(this).html($(this).find('.flickity-slider').html());
                $(this).find('.lrb-carousel-item').removeClass('is-selected').removeAttr('style');

                $carousel.parent().find('.carousel-outernav-holder').remove();
                $carousel.parents('.carousel-outernav-wrapper').find('.carousel-outernav-holder').remove();
            }
        });

    }

    setupCarouselListeners($el, flkty){

        let scope = this;

        // Prevent page scrolling on touch devices when dragging the carousel
        flkty.on('dragStart', function( event, pointer ) {
            document.ontouchmove = function (e) {
                e.preventDefault();
            }
        });
        flkty.on('dragEnd', function( event, pointer ) {
            document.ontouchmove = function (e) {
                return true;
            }
        });

        scope.updateSlideTabIndexing($el, flkty, '.lrb-carousel-item');
        flkty.on('change', function(){
            scope.updateSlideTabIndexing($el, flkty, '.lrb-carousel-item');
        });

        // Set correct resizing so all slides have equal height, set by largest slide
        /*flkty.on( 'resize', function() {
            var heights = [];
            $el.find('.lrb-carousel-item').each(function(){
                $(this).removeAttr('height');
                var h = 0;
                $(this).find('>*').each(function(){
                    h+=$(this).height();
                });
                heights.push(h);
            });

            var max = Math.max.apply(Math, heights);

            $el.find('.lrb-carousel-item').each(function(){
                $(this).height(max);
            });
        });*/

    }

    updateSlideTabIndexing($carousel, flkty, slideClass){

        console.log("Updating carousel tab indexing");

        //Set tab index for focusable elements in slides to -1, unless is the active slide, aids with accessibility, and avoids focusing on elements not in view / visible

        var focuseableElemsSelector = 'a, button, input, textarea, [tabindex=0], select';

        if($carousel && slideClass){
            $carousel.find(slideClass).each(function(){
                $(this).find(focuseableElemsSelector).attr('tabindex', '-1');
            });
        }

        if(flkty && flkty.selectedCells){
            flkty.selectedCells.forEach(function(elem){
                $(elem.element).find(focuseableElemsSelector).attr('tabindex', '0');
            });
        }

    }

    destroy(){
        this.cleanupUniversalCarousels();
        this.cleanupTabletCarousels();
        this.cleanupMobileCarousels();
        this.cleanupGalleryCarousels();
        $('.sidebarAdjacent').removeClass('sidebarAdjacent');
    }

}

module.exports = { LRBCarousel };
