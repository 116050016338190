require("select2/dist/js/select2.full.js");
var DropdownCSS = require("select2/src/js/select2/compat/dropdownCss.js");

class LRBDropdown {
    constructor() {
        window.decadeDropdown = this.triggerDecadeDropdown;
        window.animateAll = true;
    }

    setup() {
        console.log('%cLRBDropdown Setup', `background-color: ${global.infoBG};`);

        try {
            //Decorator to wrap the dropdown markup in the same element as the selector
            $.fn.select2.amd.define("CreateAttachContainerDropdownAdapter", [
                "select2/utils",
                "select2/dropdown",
                "select2/dropdown/attachBody",
                "select2/dropdown/attachContainer",
                "select2/dropdown/search",
                "select2/dropdown/minimumResultsForSearch",
                "select2/dropdown/closeOnSelect",
              ],
              function(Utils, Dropdown, AttachBody, AttachContainer, DropdownSearch, MinimumResultsForSearch, CloseOnSelect) {
                return function (_options) {
                  var options = $.extend(true, {}, $.fn.select2.defaults.defaults, _options);
                  var adapter = null;
                  if (options.dropdownAdapter == null) {
                    if (options.multiple) {
                      adapter = Dropdown;
                    } else {
                      adapter = Utils.Decorate(Dropdown, DropdownSearch);
                    }
    
                    if (options.minimumResultsForSearch !== 0) {
                      adapter = Utils.Decorate(
                        adapter,
                        MinimumResultsForSearch
                      );
                    }
    
                    if (options.closeOnSelect) {
                      adapter = Utils.Decorate(
                        adapter,
                        CloseOnSelect
                      );
                    }
    
                    if (
                      options.dropdownCssClass != null ||
                      options.dropdownCss != null ||
                      options.adaptDropdownCssClass != null
                    ) {
    
                      adapter = Utils.Decorate(
                        adapter,
                        DropdownCSS
                      );
                    }
    
                    adapter = Utils.Decorate(
                      adapter,
                      AttachContainer
                    );
                  }
                  return adapter;
                }
              }
            );
    
            this.setupSidenavMobileDropdowns();
            this.setupLRBDropdowns();
            
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Dropdown',error);
        }
    }

    triggerDecadeDropdown(target){
        if(target && target !=''){
            $('.decadenav-item .lrb-dropdown').select2('close');

            var sortStr = '';
            var sort = global.utils.getURLParam('sort');
            if(sort && sort != ''){
                sortStr = '?sort='+sort;
            }

            window.location = '/archive/'+target+sortStr;
        }
    }

    //====================================
    // Generate dropdown for mobile display for .lrb-listnav elements
    //====================================
    setupSidenavMobileDropdowns(){

        $('.lrb-listnav').each(function(){
            if(!$(this).hasClass('mobileListNavSetup') && $(this).find('.lrb-listnav--mobile').length){
                var $list = $(this).find('.lrb-listnav--list');
                var $target = $(this).find('.lrb-listnav--mobile');

                var str = '<select class="lrb-dropdown lrb-dropdown--nav">';

                $list.find('.lrb-listnav--link').each(function(){
                    var link = $(this).attr('href');
                    var selected = $(this).hasClass('selected') ? ' selected' : '';
                    var text = $(this).html();

                    var option = '<option value="'+link+'"'+selected+'>'+text+'</option>';
                    str = str+option;
                });

                str = str+'</select>';
                $target.html(str);

                $(this).addClass('mobileListNavSetup');

                $target.find('.lrb-dropdown').on('change', function(){
                    if($(this).val()){
                        window.location = $(this).val();
                    }
                });
            }
        });

    }

    setupLRBDropdowns(){

        let scope = this;

        var selectOptions = null;
        $(".lrb-dropdown").each(function(){

            var renderDropdown = true;
            if($('html').hasClass('touch')){
                renderDropdown = false;
            }
            if( $('body').hasClass('section-archive') ||
                $('body').hasClass('section-search-results') ||
                $(this).parent().hasClass('lettersnav-item')
            ){
                renderDropdown = true;
            }

            if(renderDropdown){
                if(window.animateAll){
                    $(this).addClass('animated');
                }
                let $dropdown = $(this);
                $dropdown.parent().prepend($(''));

                switch(true){
                    case $dropdown.hasClass('lrb-dropdown--mulitple'):
                        selectOptions = {
                            placeholder: "Select",
                            dropdownCssClass: "lrb-dropdown--mulitple",
                            selectOnClose: false
                        };
                        break;

                    case $dropdown.hasClass('lrb-dropdown--nav'):
                        selectOptions = {
                            placeholder: "Select",
                            dropdownCssClass: "lrb-dropdown--nav",
                            allowClear: false,
                            selectOnClose: false
                        };
                        break;

                    default:
                        selectOptions = {
                            placeholder: "Select",
                            allowClear: false,
                            selectOnClose: false
                        };
                }

                if($dropdown.parent().hasClass('lrb-filter-dropdowns') || window.animateAll){

                    //For filter dropdowns (e.g. year selector) wrap the dropdown markup in the same element as the selector
                    selectOptions.placeholder = $dropdown.data('placeholder');
                    selectOptions.dropdownAdapter = $.fn.select2.amd.require('CreateAttachContainerDropdownAdapter')(selectOptions);
                    $dropdown.select2(selectOptions);

                }else{

                    $dropdown.select2(selectOptions);

                }

                if($dropdown.hasClass('animated')){
                    $dropdown.on('select2:open', function(){
                        $(this).parent().addClass('dd-open');
                        var $dd = $(this).parent().find('.select2-dropdown');
                        $dd.hide();
                        setTimeout(function(){
                            $dd.slideDown(500);
                            if($('.style-override-holder').length > 0){
                                var DDheight = Math.max(120, Math.min($dd.find('.select2-results__options').outerHeight(true) + 60, 248));
                                $('.style-override-holder').html("<style>.search-container .search-results--header .search-results--nav .searchfilter-group .lrb-filter-dropdowns .select2-container.select2-container--open:before{height:"+DDheight+"px !important;}</style>");
                            }
                        }, 10);
                    });
                    $dropdown.on('select2:close', function(){
                        $(this).parent().removeClass('dd-open');
                        var $dd = $(this).parent().find('.select2-dropdown');
                        $dd.show();
                        setTimeout(function(){
                            //$dd.slideUp();
                            $dd.show();
                        }, 200);

                        scope.updateMissingIssueDropdown($(this));

                    });
                }else{

                    $dropdown.on('select2:close', function(){
                        scope.updateMissingIssueDropdown($(this));
                    });

                }
            }

        });

        //Force disable searching on dropdown
        $(".lrb-dropdown").on('select2:opening select2:closing', function(e){
            var $searchfield = $(this).parent().find('.select2-search__field');
            $searchfield.prop('disabled', true);
        });
    }

    updateMissingIssueDropdown($target){

        // Check if is a missing issue dropdown
        if($target && $target.parents('.missing-issue-form').length > 0){

            // Change shown shown field value to count of selected items
            var uldiv = $target.siblings('span.select2').find('ul');
            var count = $target.select2('data').length;
            if(count == 0){
                uldiv.html("");
            }else{
                uldiv.html("<li>"+count+" issues selected</li>");
            }

        }
    }



    destroy(){
    }

}

module.exports = { LRBDropdown };
