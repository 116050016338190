const GA4_ENABLED = true;
const SEND_GA4_TRACKING = true;

// Google Tag Manager ID
const GA4_TRACKING_ID_TEST = "G-7LCPFB8KQ6";
const GA4_TRACKING_ID_LIVE = "G-JE69ELWJLM";
class GA4 {
    constructor() {
        // Stored dimensions to pass to GA when it's initialised
        window.ga4_dimensions = {'anonymize_ip': true, 'cookie_prefix': 'lrb', 'groups': 'GA4'};
        window.addGA4Dimension = this.addDimension;

        window.GA4SCG = this.setContentGroup;
        window.GA4SCGFP = this.setContentGroupForPage;
        window.GA4SCGFM = this.setContentGroupFromMeta;

        window.setGA4UserID = this.setUserID;
        window.ga4trackingID = GA4_TRACKING_ID_TEST;
        // Temporarily disable whilst implementing GA4
        //window.gaJson = this.getJSONSessionData;
        //window.gCID = this.getClientID;

        window.ga4_setup = false;
        window.ga4_failed = false;

        window.gtag = this.gtag;

        window.contentGroupCount = 1;
        window.ga4_debug_mode = false;
    }

    setup() {
        global.LRBErrorTracking.setContext('GA4 setup');

        if(!GA4_ENABLED){
            return false;
        }
        console.log('%cGoogleGTag Setup', `background-color: ${global.infoBG};`);

        if(process.env.NODE_ENV === "production" || process.env.NODE_ENV === "prod"){
            window.ga4trackingID = GA4_TRACKING_ID_LIVE;
        }

        // Check for ID overrides from ENV Vars - CommonComponentsController > googleIDsAction
        if(window.GA4ID && window.GA4ID != ""){
            window.ga4trackingID = window.GA4ID;
        }

        // Check for debug mode from ENV var
        if(window.ga4DebugMode && window.ga4DebugMode != ""){
            window.ga4_debug_mode = window.ga4DebugMode;
            window.ga4_dimensions.debug_mode = window.ga4_debug_mode;
        }

        var gtagScript = document.createElement("script");
        gtagScript.type = "text/javascript";
        gtagScript.setAttribute("async", "true");
        gtagScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id="+window.ga4trackingID);
        gtagScript.onerror = function(){
            window.ga4_failed = true;
            console.error('Failed to load GA4 Scripts');
        }
        document.head.appendChild(gtagScript);

        global.LRBErrorTracking.setPageContext();

        // Set page content groupings
        window.GA4SCGFP();
    }

    // Set a USER-ID to associate multiple sessions with the same logged in user, before GA is initialised
    setUserID(userID){
        if(userID && userID != null && userID != ''){

            if(window.ga4_setup){
                console.error('GA4 setUserID: Cannot set UserID, GTag already initialised');
                return false;
            }

            window.ga4_dimensions.user_id = userID;

        }else{
            console.warn('GA4 setUserID: No ID set');
        }
    }

    // Add a dimension to the store, before GA is initialised
    addDimension(dimension, value){
		if(window.ga4_dimensions.length == 20){
			console.error('GA4 addDimension: Cannot add dimension, limit reached: '+window.ga4_dimensions);
            return false;
		}

        if(dimension && value){

            if(window.ga4_setup){
                console.error('GA4 addDimension: Cannot add dimension, GTag already initialised - '+dimension);
                return false;
            }

            if(window.ga4_dimensions[dimension]){
                console.warn('GA4 addDimension: Overwriting Dimension - '+dimension);
            }

            console.warn('GA4 Adding Dimension: '+dimension+" : "+value);
            window.ga4_dimensions[dimension] = value;

        }else{
            console.warn('GA4 addDimension: No Dimension set');
        }
    }

    // Track an event, after GA is initialised
    trackEvent(action, category, label, value, nonInteraction = false){
        if(action){

            var eventVals = {'send_to': 'GA4'};
            if(category){
                eventVals.event_category = category;
            }
            if(label){
                eventVals.event_label = label;
            }
            if(value){
                eventVals.value = value;
            }
            if(nonInteraction){
                eventVals.non_interaction = true;
            }

            console.warn("GA4Event: " +action+" - "+category+", "+label+", "+value);
            try{
                window.gtag('event', action, eventVals);
            }catch(e){
                console.error(e);
            }

        }else{
            console.error('GA4 trackEvent: no action specified');
        }
    }
    // Track an event, after GA is initialised
    trackBasicEvent(action, eventVals = {}){
        console.info('trackBasicEvent: '+action, eventVals);
        if(action && eventVals){

            eventVals.send_to = 'GA4';

            console.warn("GA4Event: " +action+" - "+eventVals);
            try{
                window.gtag('event', action, eventVals);
            }catch(e){
                console.error(e);
            }

        }else{
            console.error('GA4 trackBasicEvent: no action/vals specified');
        }
    }

    // Set a page content group, can be used to add mutliple content groups to the same page
    // GA4SCG
    setContentGroup(contentGroup){
        console.log('setContentGroup... '+contentGroup);
        if(contentGroup && contentGroup !== ''){
            var contentGroupName = 'content_group';

            // if multiple content groups are to be added then name them as 'content_group', 'content_group2', 'content_group3' etc
            if(window.contentGroupCount > 1){
                contentGroupName = contentGroupName + window.contentGroupCount;
            }

            console.log('setContentGroup '+contentGroupName+' - '+contentGroup);
            // Add primary content group to dimensions in addition to setting in dataLyayer
            window.ga4_dimensions[contentGroupName] = contentGroup;
            gtag('set', contentGroupName, contentGroup);

            window.contentGroupCount++;
        }
    }

    // Set Content Group automatically based on the page we are on
    // GA4SCGFP
    setContentGroupForPage(){
        var pageGroup = global.LRBTracking.getPageGroup();
        console.log('setContentGroupForPage... '+pageGroup);

        if(pageGroup){
            // set page group as content_group
            window.GA4SCG(pageGroup);

            // Send additional Article content groups
            // Set article subject dimension from meta
            var subjectMeta = document.querySelector("meta[property='article:section']");
            if(subjectMeta){
                var subject = subjectMeta.getAttribute("content");
                if(subject && subject!= ''){
                    window.addGA4Dimension('article_subject', subject);
                }
            }

            // Set article type content group from meta
            window.GA4SCGFM("meta[property='article:type']");
            var articleTypeMeta = document.querySelector("meta[property='article:type']");
            if(articleTypeMeta){
                var articleType = articleTypeMeta.getAttribute("content");
                if(articleType && articleType!= ''){
                    window.addGA4Dimension('article_type', articleType);
                }
            }

            return pageGroup;
        }

        console.log('setContentGroupForPage failed');
        return false;
    }

    // GA4SCGFP
    // e.g. window.GA4SCGFM("meta[property='article:type']");
    setContentGroupFromMeta(metaQueryString){
        if(metaQueryString){
            var meta = document.querySelector(metaQueryString);
            if(meta){
                var metaContent = meta.getAttribute("content");
                if(metaContent && metaContent != ''){
                    return window.GA4SCG(metaContent);
                }
            }
        }

        console.log('setContentGroupFromMeta failed');
        return false;
    }

    // Check if current page contains PII or other private content that should not be tracked
    // Returns true if pageview event has been redacted / blocked
    checkForPrivatePage(){

        // To avoid sending PII from URL params etc
        // This checks based on the URL structure and URL params
        // Based on those it will perform a combination of:
        // - Blocking standard page view event in GA, non page view event is sent instead
        // - Cleanup the page title to not contain PII
        // - Clean the URL path to not contain PII


        var blockPageView, cleanPageTitle, cleanPath, pageViewRedacted = false;
        // Full path is now required in GA4 for virtual pageviews
        var cleanURL = window.location.protocol +'//'+ window.location.host + window.location.pathname;

        // Check if URL matches specific pages we want to sanitise
        if( window.location.pathname.includes("password-sent") ||
        window.location.pathname.includes("password-changed") ||
        window.location.pathname.includes("forgotten-password") ||
        window.location.pathname.includes("reset-password") ||
        window.location.pathname.includes("password")
        ){
            pageViewRedacted = true;
            cleanPageTitle = true;
            cleanPath = true;
        }

        // Check if URL contains PII in URL params
        if( window.location.search.indexOf('@') > -1 ||
        global.utils.getURLParam("fname") != "" ||
        global.utils.getURLParam("lname") != "" ||
        global.utils.getURLParam("email") != ""
        ){
            pageViewRedacted = true;
        }

        // If specific URL params are present then clean the URL, but otherwise do not alter the pageview
        if( global.utils.getURLParam("t") != "" ||
        global.utils.getURLParam("a") != "" ||
        global.utils.getURLParam("n") != "" ||
        global.utils.getURLParam("_ptid") != "" ||
        global.utils.getURLParam("user_id") != "" ||
        global.utils.getURLParam("fbclid") != "" ||
        global.utils.getURLParam("gtm_debug") != ""
        ){
            pageViewRedacted = true;
            cleanPath = true;
        }

        // If URL has subscription ID then redact this
        if(window.location.pathname.includes("subscription/DSB")){
            pageViewRedacted = true;
            cleanURL.replace(/subscription\/DSB\/\d+/g, 'subscription/DSB/REDACTED_ID');
            cleanPath = true;
        }

        // Send a virtual pageview event rather than the standard one
        if(blockPageView)window.addGA4Dimension('send_page_view', false);

        // Cleanup the page title
        if(cleanPageTitle)window.addGA4Dimension('page_title', document.title);

        // Cleanup the URL sent to GA to not include params etc
        if(cleanPath)window.addGA4Dimension('page_location', cleanURL);


        if(pageViewRedacted)console.warn("GTag Not Tracking Standard Pageview due to PII content on this page");
        return pageViewRedacted;
    }

    // Initialise GA Tracking, using stored dimensions etc
    initialiseGATagTracking(){
        global.LRBErrorTracking.setContext('GA4 initialiseGATagTracking');

        var sendRedactedPageview = this.checkForPrivatePage();

        if(SEND_GA4_TRACKING && !window.ga4_failed){
            if(window.ga4_setup){
                console.error('GA4 already setup, skipping');
                return false;
            }
            console.warn('GA4 Initialising Tracking');

            // Add cross domain tracking
            var domains = {'domains': [
                'mylrb.com',
                'mylrb.co.uk'
            ]};
            window.addGA4Dimension('linker', domains);

            // Default GA Pageview Event etc
            window.gtag('js', new Date());

            // Update referrer from URL passed through session redirect
            if(window.lrb_referrer && window.lrb_referrer != undefined && window.lrb_referrer != ""){
                window.gtag('set', {'referrer': window.lrb_referrer});
                try{
                    Object.defineProperty(document, "referrer", {get : function(){ return window.lrb_referrer; }});
                }catch(e){
                    console.error("Session Referral Passthrough Fail: "+e);
                }
            }

            global.LRBErrorTracking.setContext('GA4 initialiseGATagTracking');

            // Add user tracking data
            var sessionTrackingData = global.LRBSessionData.trackingData;
            if(sessionTrackingData){
                console.log('Setting user properties', sessionTrackingData);
                window.ga4_dimensions.user_properties = sessionTrackingData;
            }

            // Trigger GA
            window.gtag('config', window.ga4trackingID, window.ga4_dimensions);
            window.ga4_setup = true;

            global.LRBTracking.logSessionData();
            global.LRBTracking.setupDelayedEventTracking();

            // This was a session referral redirect, cleanup the URL after tracking is done
            if(window.lrb_referrer && window.lrb_referrer != undefined && window.lrb_referrer != ""){
                global.utils.updateURL(window.location.pathname);
            }

        }else{
            if(window.ga4_failed){
                global.LRBErrorTracking.setContext('GA Tracking');

                console.error('Cannot load Tracking, likely due to script blockers');
                //global.LRBErrorTracking.captureMessage('Cannot load Tracking, likely due to script blockers',LRBErrorTracking.LEVEL.INFO,'production',true);

                global.LRBErrorTracking.setPageContext();
            }
            console.warn('GA4 Not Initialising Tracking:: No events/tracking will be sent to GA');
        }

        global.LRBErrorTracking.setPageContext();
    }

    gtag(){
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(arguments);
    }

    getClientID(){
        global.LRBErrorTracking.setContext('GA4 getClientID');

        var cID = false;
        try{
            var gaCookie = window.utils.getCookie('lrb_ga');
            if(gaCookie){
                cID = gaCookie.slice(6);
            }else{
                console.warn("GAgetJSONSessionData: Could not access GA");
            }
        }catch(e){
            console.warn("GAgetJSONSessionData: Could not get GA clientID");
            //global.LRBErrorTracking.captureMessage('Could not get GA clientID', LRBErrorTracking.LEVEL.ERROR, 'production');
        }

        global.LRBErrorTracking.setPageContext();
        return cID;
    }

    // Exports required tracked session data and returns JSON object
    // to be passed along with a form in hidden field for reporting
    getJSONSessionData(){
        if(!GA4_ENABLED){
            return false;
        }else{
            var data = {
                'setup': window.ga4_setup,
                'sendTracking': SEND_GA4_TRACKING,
                'dataLayer': window.dataLayer,
                'blocked': false
            };
            var clientID = window.gCID();
            if(clientID){
                data.clientId = clientID;
            }

            return data;
        }
    }

    destroy(){
    }

}

module.exports = { GA4 };
