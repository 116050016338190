const GENERAL_ERROR = 'Sorry, we encountered an error. Please contact support at <a href="mailto:support@lrb.co.uk" title="Email Support">support@lrb.co.uk</a>';
//const UNRECOGNISED_EMAIL = 'The email address you entered was not recognised. <a class="createAccountLink" href="/create-an-account">Create an account?</a>';
const UNRECOGNISED_EMAIL = 'Incorrect email address or password';
const ADD_SUB_ENDPOINT_ERROR = 'There was an error processing this subscription, please contact Customer Services for help.';
const ADD_SUB_GENERIC_ERROR = 'There was an error adding this subscription, please refresh the page and try again.';

class LRBAccountForms {
    constructor() {
        this.login_passed_validation = false;
        this.create_account_passed_validation = false;

        this.accountError = "Please complete all fields.";
    }

    setup() {
        console.log('%cLRBAccountForms Setup', `background-color: ${global.infoBG};`);
        try {
            this.setupLoginForm();
            this.setupAccountForm();
            this.setupAddSubscriptionForm();
    
            if($('.customer_number_find_link').length > 0){
                this.setupCustomerNumberSearch();
            }
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Account Forms',error);
        }
    }

    checkStatus(number){

        // Catch incorrectly returned statuses of valid/error strings
        if (typeof number === 'string') {
            let statusRange = ['ok', 'error'];
            if( statusRange.includes(number.toLowerCase()) ){
                return number;
            }
        }

        // Check status code against those passed from config/packages/account_status_codes.yaml
        if(window.lrbASC){
            for(const status of window.lrbASC){
                if(parseInt(number) >= parseInt(status.min) && parseInt(number) <= parseInt(status.max)){
                    return status.name;
                }
            }
        }

        // When no matching code is found
        return "not_found";
    }

    loadCustNoHelpContent(){
        $(".modal-container .custno-modal-content").load("/customer-number",function(){
            console.log("Customer number help copy loaded");
        });
        global.resizing.modalResizeHandler();
    }

    clearCustNoFormErrors(){
        $(".formSearchPostcodeMsg").val('');
        $(".formSearchEmailMsg").val('');
        $(".formSearchPostcodeMsg").removeClass('error');
        $(".formSearchEmailMsg").removeClass('error');
        $("#form_search_last_name").removeClass('error');
        $("#form_search_postcode").removeClass('error');
        $("#form_search_email").removeClass('error');

        $('.submitting').removeClass('submitting');
    }
    clearCustNoForm(){

        if($("#form_last_name").val() != ""){
            $("#form_search_last_name").val($("#form_last_name").val());
        }else{
            $("#form_search_last_name").val('');
        }
        $("#form_search_postcode").val('');
        $("#form_search_email").val('');
    }
    validateCustomerForm(){
        var last_name = $("#form_search_last_name").val();
        var zipcode = $("#form_search_postcode").val();
        var email = $("#form_search_email").val();

        this.clearCustNoFormErrors();
        if(last_name=="" && zipcode=="" && email==""){

            $("#form_search_last_name").addClass('error');
            $("#form_search_postcode").addClass('error');
            $("#form_search_email").addClass('error');
            $(".formSearchPostcodeMsg").html("Please enter your last name and postal / zipcode");
            $(".formSearchEmailMsg").html("Or please enter your email");
            $(".formSearchPostcodeMsg").addClass('error');
            $(".formSearchEmailMsg").addClass('error');

            return false;

        }else if(last_name=="" || zipcode=="" || email==""){

            var errStr = "Please enter your";
            var errStrSet = false;

            if(email == ""){
                //If lastname and/or zipcode are blank and email is blank
                if(last_name==""){
                    var errStr = errStr+" last name";
                    errStrSet = true;
                    $("#form_search_last_name").addClass('error');
                }
                if(zipcode==""){
                    if(last_name==""){
                        var errStr = errStr+" and";
                    }
                    var errStr = errStr+" postal / zipcode";
                    errStrSet = true;
                    $("#form_search_postcode").addClass('error');
                }
                if(errStrSet){
                    $(".formSearchPostcodeMsg").html(errStr);
                    $(".formSearchPostcodeMsg").addClass('error');
                }
            }else{
                //if email is set, form can be submitted
                errStrSet = false;
            }

            if(errStrSet){
                return false;
            }else{
                return true;
            }


        }else{
            return true;
        }
    }

    //=====================================================================
    // Setup Customer Number Search
    //=====================================================================
    setupCustomerNumberSearch($target){
        let scope = this;

        global.setupPage.setupCustomerNumberPopupLink();

        //=====================================================================
        // Toggle find customer number form
        //=====================================================================

        $(".customer_number_find_link, .customer_number_try_again, .cancel_find_customer_number").each(function(){
            if($(this).data('cust-click-setup') != true){

                $(this).click(function(e){
                    e.preventDefault();

                    $(".customer_number_search").slideToggle({progress:global.resizing.modalResizeHandler});
                    $(".customer_number_fail").velocity('stop').velocity("slideUp", { duration: 400 });
                    $('.customer_number_search #form_search_email, .customer_number_search #form_search_last_name, .customer_number_search #form_search_postcode').removeAttr('disabled');

                    if($(this).hasClass('cancel_find_customer_number')){
                        scope.clearCustNoFormErrors();
                        scope.clearCustNoForm();
                    }
                });
                $(this).data('cust-click-setup',true);

            }
        });

        //=====================================================================
        // Setup Customer number input checking / disabling
        //=====================================================================

        $('.customer_number_search #form_search_last_name, .customer_number_search #form_search_postcode').on('input', function(){
            if($(this).val() == ""){
                $('.customer_number_search #form_search_email').removeAttr('disabled');
            }else{
                $('.customer_number_search #form_search_email').attr('disabled',true);
            }
        });

        $('.customer_number_search #form_search_email').on('input', function(){
            if($(this).val() == ""){
                $('.customer_number_search #form_search_last_name, .customer_number_search #form_search_postcode').removeAttr('disabled');
            }else{
                $('.customer_number_search #form_search_last_name, .customer_number_search #form_search_postcode').attr('disabled', true);
            }
        });

        //=====================================================================
        // Submit customer number form
        //=====================================================================

        $("#find_customer_number_submit").each(function(){

            if($(this).data('cust-submit-click-setup') == true){
                return true;
            }else{
                $(this).data('cust-submit-click-setup') == true;
            }

            $("#find_customer_number_submit").on('click', function(e){
                e.preventDefault();
                e.stopPropagation();

                var last_name = $("#form_search_last_name").val();
                var zipcode = $("#form_search_postcode").val();
                var email = $("#form_search_email").val();

                if(scope.validateCustomerForm()){
                    $("#form_order_number").val("");

                    $.post("/account/find-subscription", {
                        last_name: last_name,
                        zipcode: zipcode,
                        email: email

                    }, function (data) {

                        if(data){
                            if(data.status > 0){
                                if($("#form_order_number").length > 0){
                                    $("#form_order_number").val(data.data.order_number);
                                }
                                $(".customer_number_search").slideToggle({progress:global.resizing.modalResizeHandler});
                                $("#form_order_number").addClass("success");
                            }else{
                                if(data.hasOwnProperty('message') && data.message != ''){
                                    $(".customer_number_fail").text(data.message);
                                }
                                $(".customer_number_search").slideToggle({progress:global.resizing.modalResizeHandler});
                                $(".customer_number_fail").velocity('stop').velocity("slideDown", { duration: 400 });
                                $("#form_order_number").removeClass("success");
                            }
                        }

                        scope.clearCustNoFormErrors();
                        scope.clearCustNoForm();
                    }).fail(function(){
                        $(".customer_number_fail").text('There was an error searching for your subscription number. Please refresh the page and try again.');
                        $(".customer_number_search").slideToggle({progress:global.resizing.modalResizeHandler});
                        $(".customer_number_fail").velocity('stop').velocity("slideDown", { duration: 400 });
                        $("#form_order_number").removeClass("success");
                    });
                }

                return false;
            });
        });

    }

    //=================================================================
    // Create account form
    //=================================================================
    createAccountForm(email="",fname="",lname="",custno=""){
        let scope = this;

        var url = "/account/create";

        $(".modal-container .inner").html(global.loadingIcon);

        $(".modal-container .inner").load(url,function(){
            global.resizing.modalResizeHandler();
            scope.setupAccountForm(email,fname,lname,custno);
        });
        global.resizing.modalResizeHandler();
    }

    injectSessionDataIntoHiddenFields(){
        // Set GA JSON Session Data
        try{
            if($('#ga_json').length > 0){
                $('#ga_json').val(JSON.stringify(global.GoogleGTag.getJSONSessionData()));
            }
        }catch(error){
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Form Session Data inject failed',error);
        }
    }

    //=================================================================
    // Setup account form, after it is created / loaded
    //=================================================================
    setupAccountForm(email="",fname="",lname="",custno=""){
        let scope = this;

        if($('#create_account_form').length == 0){
            return;
        }

        scope.loadCustNoHelpContent();

        global.LRBFormValidation.autoValidate();
        global.resizing.modalResizeHandler();
        global.LRBRecaptcha.setupGoogleRecaptchas();

        $("#form_first_name").focus();
        $(".modal-container").scrollTop(0);

        if(email != ""){
            $("#form_email").val(email);
        }
        if(fname != ""){
            $("#form_first_name").val(fname);
        }
        if(lname != ""){
            $("#form_last_name").val(lname);
        }
        if(custno != "" && custno != "[UIDDSB]"){
            $("#form_order_number").val(custno);
        }

        scope.injectSessionDataIntoHiddenFields();

        //=====================================================================
        // Mirror (customer number form) fields as user types
        //=====================================================================

        $("#form_last_name").keyup(function(){
            $("#form_search_last_name").val($(this).val());
        });

        /*$("#form_email").keyup(function(){
            $("#form_search_email").val($(this).val());
        });*/

        //=====================================================================
        // Setup Customer Number Search
        //=====================================================================
        scope.setupCustomerNumberSearch();

        //=================================================================
        // Create account form validation
        //=================================================================

        $("#create_account_cancel_button").on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            scope.createLoginForm();

            return false;
        });
        $("#create_account_submit_button").on('click', function(e){

            e.preventDefault();
            e.stopPropagation();

            var errors = [];
            scope.injectSessionDataIntoHiddenFields();

            var validated = global.LRBFormValidation.autoValidateFieldsInForm($('#create_account_form'));

            if(!validated){
                errors.push(global.LRBFormValidation.generalCreateAccountError);
            }

            //=====================================================================
            // Validate form values
            //=====================================================================

            if (!global.LRBFormValidation.googleRecaptchaCompleteCheck($('#create_account_form .g-recaptcha'))) {
                errors.push("Please confirm you are not a robot.");
                console.log(errors);
                return false;
            }

            //=====================================================================
            // Check email doesn't exist, customer number and recaptcha  are valid
            //=====================================================================

            var username = $("#form_email").val().trim();

            const checkAjaxRecaptcha = null;

            $.post("/account/check", {

                username: username,
                recaptcha: 1,
                order_number: 1,
                "g-recaptcha-response": $("#g-recaptcha-response").val(),
                "order_number_value": $("#form_order_number").val(),
                "check_recaptcha": checkAjaxRecaptcha

            }, function (data) {

                //=====================================================================
                // Format errors
                //=====================================================================

                if(data && errors.length == 0){
                    if (scope.checkStatus(data.status) != "not_found") {
                        errors.push('This email address is already registered for an LRB account. Please try requesting a password link from <a href="https://lrb.co.uk/account/forgotten-password" title="Forgot Password">here</a> then log in to access the account or link a new subscription. If you still have problems please contact us at <a href="mailto:support@lrb.co.uk">support@lrb.co.uk</a>.');
                    }
                    if (data.order_number != true) {
                        if (data.hasOwnProperty('message')) {
                            errors.push(data.message);
                        } else {
                            errors.push("The customer number was not found.");
                        }
                    }
                    if (checkAjaxRecaptcha && data.recaptcha != true) {
                        errors.push("Please confirm that you are not a robot.");
                    }
                }

                //=====================================================================
                // If ok, submit the form
                //=====================================================================

                if (errors.length == 0) {
                    $('#create_account_form').submit();
                    //document.forms['create_account_form'].submit(); //jQuery submit won't fire for some reason
                }

                //=====================================================================
                // Otherwise log errors and reset recaptcha
                //=====================================================================

                else {
                    console.log(errors);
                    global.LRBFormValidation.showGeneralError(errors.join(' <br/>'));
                    grecaptcha.reset();
                }
            }).fail(function(error){
                errors.push("There was an error checking this account, please refresh the page and try again.");
                console.log(errors);
                errors.push(error);
                global.LRBErrorTracking.error(error);
                global.LRBFormValidation.showGeneralError(errors.join(' <br/>'));
                grecaptcha.reset();
            });
        });
    }

    //=================================================================
    // Create login form
    //=================================================================
    createLoginForm(msg=""){
        let scope = this;

        var url = "/login?referer="+encodeURI(window.location.pathname+window.location.search)+"&msg="+msg;

        $(".modal-container .inner").html(global.loadingIcon);

        $(".modal-container .inner").load(url,function(){
            global.resizing.modalResizeHandler();
            scope.setupLoginForm();
        });
        global.resizing.modalResizeHandler();
    }

    //=================================================================
    // Setup login form, after it is created / loaded
    //=================================================================
    setupLoginForm(){

        if($('#login_form').length == 0){
            return false;
        }

        let scope = this;

        global.LRBFormValidation.autoValidate();
        global.resizing.modalResizeHandler();

        //============================================
        // Set login validation as false if data dirty
        //============================================

        $(".login-email #username, .login-email #password").keydown(function(e){
            scope.login_passed_validation = false;
        });

        $("#create_account_link").click(function(e){
            e.preventDefault();
            e.stopPropagation();

            scope.createAccountForm();

            return false;
        });

        $("#institutions_account_link, .institutions_account_link").on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            global.LRBInstitutionForms.createInstitutionForm();

            return false;
        });

        //=================================================================
        // Login Form
        //=================================================================
        scope.checkUserAccount();
    }

    //=================================================================
    // Create Add Subscription form
    //=================================================================
    createAddSubscriptionForm(){
        let scope = this;

        var url = "/account/subscriptions/add?referer="+encodeURI(window.location.pathname);

        $(".modal-container .inner").html(global.loadingIcon);

        $(".modal-container .inner").load(url,function(){
            global.resizing.modalResizeHandler();
            scope.setupAddSubscriptionForm();
        });
        global.resizing.modalResizeHandler();
    }

    //=================================================================
    // Setup Add Subscription form, after it is created / loaded
    //=================================================================
    setupAddSubscriptionForm(){
        let scope = this;

        global.LRBFormValidation.autoValidate();
        global.resizing.modalResizeHandler();

        scope.setupCustomerNumberSearch();
        scope.loadCustNoHelpContent();

        $('#add_subscription_form').each(function(){
            if($(this).hasClass('el-setup'))return false;

            $(this).on("submit", function(event){
                event.preventDefault();
                $('#add_subscription_form').addClass('submitting');
                $(".customer_number_fail").hide();
    
                $.post('/account/subscriptions/add', $(this).serialize()).done( function(data){
                    if (Object.entries(data).length == 0 || data == false || data.length == 0 || data.status == false){
                        if(data.message && data.message != ''){
                            $(".customer_number_fail").html(data.message).show();
                        }else{
                            $(".customer_number_fail").text(ADD_SUB_GENERIC_ERROR).show();
                        }
                        $('.submitting').removeClass('submitting');
                    }else{
                        window.location = "/account/subscriptions?sub=1";
                    }
                }).fail(function(){
                    $(".customer_number_fail").text(ADD_SUB_ENDPOINT_ERROR).show();
                    $('.submitting').removeClass('submitting');
                });
            });

            $(this).addClass('el-setup');
        });
    }


    //=================================================================
    // Login Form - Check whether user needs to update their password
    //=================================================================
    checkUserAccount(){
        let scope = this;

        $("#login_form").submit(function(e){

            if(scope.login_passed_validation == true){
                return;
            }

            e.preventDefault();
            e.stopPropagation();

            global.LRBAlerts.clearModalNotifications();

            //============================
            // Validate form input
            //============================
            var username = $("#username").val().trim();
            var password = $("#password").val().trim();

            if(username==""){
                $('#login_form .login-email').addClass('error');
                $('#login_form .login-email .validation-error-message').html("Please enter your email address.");
                $("#login_form #username").addClass('error');

                return;
            } else if(password==""){
                $('#login_form .login-password').addClass('error');
                $('#login_form .login-password .validation-error-message').html("Please enter your password.");
                $("#login_form #password").addClass('error');

                return;
            }

            else{
                $('#login_form .login-email').removeClass('error');
                $('#login_form .login-email .validation-error-message').html("");
                $("#login_form #username").removeClass('error');
            }

            $("#login_next").html("Checking email...");

            //============================
            // Check user's account using
            // email address provided
            //============================

            $.post("/account/check", {username: username}, function( data ) {

                if(scope.checkStatus(data.status) == "ok") {
                    scope.login_passed_validation = true;
                    $("#login_form").submit();
                    return;
                }

                else{

                    scope.login_passed_validation = false;

                    //========================================
                    // If password needs to be updated
                    //========================================

                    if(scope.checkStatus(data.status) == "update_password"){

                        $(".login-next, .login-password, .login-submit, .login-email").hide();
                        $("#resend_password_link").click(function(){

                            $("#resend_password_link").text("Sending...");

                            $.post("/account/resend-password-link", {username: username}, function( data ) {

                                if(scope.checkStatus(data.status) == "ok"){

                                    $("#resend_password_link").text("Link sent");

                                    setTimeout(function(){
                                        $("#resend_password_link").text("Resend Link");
                                    },3000);

                                }

                                else{
                                    global.LRBAlerts.showModalError(GENERAL_ERROR);
                                }
                            });

                        });

                        $(".login-update").show();
                        $("#login_first_name").text(data.first_name);
                    }

                    //========================================
                    // If email is invalid
                    //========================================

                    if(scope.checkStatus(data.status) == "invalid_email"){
                        global.LRBAlerts.showModalError('Please enter a valid email address.');
                        $("#login_next").html("Next");
                    }

                    //========================================
                    // If account not found
                    //========================================

                    if(scope.checkStatus(data.status) == "not_found"){
                        global.LRBAlerts.showModalError(UNRECOGNISED_EMAIL);
                        $("#login_next").html("Next");

                        $('.createAccountLink').on('click',function(e){
                            e.preventDefault();
                            e.stopPropagation();

                            scope.createAccountForm();

                            if($('body').hasClass('section-login')){
                                $('html').addClass('lrb-modalOpen');
                            }

                            return false;
                        });
                    }

                    if(scope.checkStatus(data.status) == "error"){
                        global.LRBAlerts.showModalError(UNRECOGNISED_EMAIL);
                        $("#login_next").html("Next");
                    }

                    return false;
                }
            });
        });
    }

    destroy(){
    }


}

module.exports = { LRBAccountForms };
