class Hotjar {
    constructor() {
        window.hotjarSetup = false;
        window.hotjarEnabled = false;
        window.hotjarFailed = false;
    }

    setup() {

        // Determine if HotJar should be setup
        if(window.lrbHJT){
            console.log('%cHotjar Setup', `background-color: ${global.infoBG};`);
            window.hotjarEnabled = true;
            // Dont setup hotjar when there is a form on the page, for data security / GDPR
            if($('form').length > 0){
                $('form').each(function(){
                    if(!$(this).hasClass('searchForm')){

                        // Do setup hotjar when on the search page, even though there is a form there
                        if(!$('body').hasClass('section-search-results')){
                            window.hotjarEnabled = false;
                            console.log('%cHotjar Not Setup - Form present', `background-color: ${global.warnBG};`);
                        }

                    }
                });
            }

        }

    }

    intialiseHotJar(){
        // Initialise HotJar Tracking if we should (according to ENV VARs and above)
        if(window.hotjarEnabled){
            var a = document.createElement("script");
            a.type = "text/javascript";
            a.text = "(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:344373,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');";
            a.onerror = function(){
                window.hotjarFailed = true;
                console.error('Failed to load Hotjar Scripts');
                global.LRBErrorTracking.captureMessage('Failed to load Hotjar Scripts');
            }
            var b = document.getElementsByTagName("script")[0];
            b.parentNode.insertBefore(a, b);

            console.info('%cHotjar Initialised', `background-color: ${global.warnBG};`);
            window.hotjarSetup = true;
        }else{
            console.info('%cHotjar Not Initialised', `background-color: ${global.warnBG};`);
        }
    }

    // Exports required tracked session data and returns JSON object
    // to be passed along with a form in hidden field for reporting
    getJSONSessionData(){
        return {
            'setup': window.hotjarSetup,
            'failed': window.hotjarFailed
        };
    }

}

module.exports = { Hotjar };
