/*

Run on a fully loaded instagram search page to spit out JSON for display in a static instagram grid
Then click each row as you scroll down, which will export JSON to the console for that row.
Has to be done this way as Instagram reloads and changes layout of the entire page as you scroll up/down

if(!window.str){
    window.str = "";
}
jQuery('a').each(function(){
  var $row = $(this).parent().parent();
  $row.on('click',function(e){
    e.preventDefault();
    e.stopPropagation();

    var newStr = "";
    $row.find('img').each(function(){
      var image = $(this).parent().html();
      var link = "https://instagram.com" + $(this).parents('a').attr('href');

      newStr += "{"
      newStr += "image: '"+ image +"', ";
      newStr += "link: '"+ link +"'";
      newStr += "}, "
    });
    console.log(newStr);
    window.str += newStr;

    return false;
  });
});

*/

class LRBInstagramGrid {
    constructor() {
    }

    setup() {
        if($('.instagram-grid').length > 0){

            try {
                let scope = this;
    
                console.log('%cLRBInstagramGrid Setup', `background-color: ${global.infoBG};`);
    
                $('.zoom-image-overlay, .zoom-image-overlay .closebtn').on('click', function(e){
                    e.preventDefault();
                    e.stopPropagation();
    
                    scope.closeZoom();
    
                    return false;
                });
    
                this.randomiseGrid(scope);
                $('.instagram-block').addClass('reveal static-reveal');
                global.pageReady.updateScrollReveal();
    
                $('.js-lrblookout-shuffle').on('click', function(e){
                    e.preventDefault();
                    e.stopPropagation();
    
                    $(".instagram-block").removeAttr('style').removeClass('revealed');
    
                    scope.randomiseGrid(scope);
                    global.pageReady.updateScrollReveal();
    
                    return false;
                });
    
                global.setupPage.setupImageErrorReloading(true);
            } catch (error) {
                global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Instagram Grid',error);
            }

        }
    }

    randomiseGrid(scope){
        scope.closeZoom();

        $(".instagram-grid").html($(".instagram-grid .instagram-block").sort(function(){
            return Math.random()-0.5;
        }));

        scope.setupClickHandlers();
    }

    setupClickHandlers(){
        $('.zoom-image').each(function(){
            if($(this).data('clicksetup') == true){
                //Skip setup, already done
            }else{
                $(this).data('clicksetup', true);
                $(this).on('click', function(e){
                    e.preventDefault();
                    e.stopPropagation();

                    var imageUrl = $(this).data('large');
                    var imageAlt = $(this).find('img').attr('alt');
                    if(imageUrl && imageUrl !=""){

                        $('html').addClass('zoom-image-open');
                        $('.zoom-image-overlay img').attr('src', '');
                        $('.zoom-image-overlay img').attr('src', imageUrl).attr('alt', imageAlt);
                        $('.zoom-image-overlay').fadeIn(600);

                    }

                    return false;
                });
            }
        });
    }

    closeZoom(){
        $('html').removeClass('zoom-image-open');
        $('.zoom-image-overlay').fadeOut(300);
    }

}

module.exports = { LRBInstagramGrid };
