require('tooltipster');
const fitvids = require("fitvids");

class LRBTooltip {
    constructor() {
    }

    setup() {
        console.log('%cLRBTooltip Setup', `background-color: ${global.infoBG};`);

        try {
            let scope = this;
    
            // Setup generic tooltips
            $('.lrb-tooltip').tooltipster({
                animation: 'fade',
                trigger: 'click',
                contentCloning: true,
                IEmin: 11,
                side: ['bottom', 'top'],
                viewportAware: true,
                interactive: true,
                maxWidth: 300,
                updateAnimation: null,
                contentAsHTML: true
            });
    
    
            // Setup missed / troublesome footnotes
            // must be reformatted as with others, as order they are processed in is important
            $('.article-content a').each(function(i){
                // look manually for all footnotes in artcile content
    
                if($(this) && $(this).attr('href')){
                    if($(this).attr('href').indexOf('/footnotes/') > -1){
                        // dont setup footnotes that are already done
                        if( !$(this).parent().hasClass('footnote-link') ){
                            $(this).wrap('<span class="footnote-link"></span>');
                        }
                    }
                }
    
            });
            // Setup article footnotes
            $('.footnote-link a').each(function(i){
                $(this).data('tooltip-content', '#footnote'+i);
                $(this).attr('href','#');
    
                if($(this).html() == "[watch]" || $(this).html() == '<span class="footnote-link">[watch]</span>'){
                    $(this).html('&nbsp;<span style="display: block;position: absolute;top: -1px;left: 9px;">▶</span>');
                }
            });
            $('.print-footnotes .print-footnote .footnote-content').each(function(i){
                $(this).attr('id', 'footnote'+i);
            });
    
            $('.footnote-link a').tooltipster({
                animation: 'fade',
                trigger: 'custom',
                triggerOpen: {
                    click: true,
                    touchstart: true,
                    tap: true
                },
                triggerClose: {
                    click: true,
                    originClick: true,
                    touchstart: true,
                    tap: true,
                    scroll: false
                },
                contentCloning: true,
                IEmin: 11,
                side: ['bottom', 'top'],
                viewportAware: true,
                interactive: true,
                maxWidth: 500,
                updateAnimation: null,
                contentAsHTML: true,
                content: '<div class="lrb-loading-icon" aria-hidden="true"></div>',
                functionBefore: function(instance, helper){
    
                    var $origin = $(helper.origin);
    
                    // Set a variable so the data is only loaded once via Ajax, not every time the tooltip opens
                    /*if ($origin.data('loaded') !== true) {
    
                        $.get($origin.attr('href'), function(data) {
    
                            if(data.footnote){
                                // call the 'content' method to update the content of our tooltip with the returned data.
                                // note: this content update will trigger an update animation
                                instance.content(data.footnote);
    
                                $origin.data('loaded', true);
                            }
    
                        });
                    }*/
    
                    var targetId = $origin.data('tooltip-content');
                    if(targetId && targetId != ""){
                        var ttContent = $($origin.data('tooltip-content'));
                        if(ttContent){
                            instance.content(ttContent);
                        }
                    }
    
                },
                functionReady: function(instance){
                    scope.scaleVideosInTooltip();
                    instance.reposition();
                    setTimeout(function(){
                        //instance.reposition();
                    },10000);
                }
            });
    
            $('.footnote-link a').on('click', function(e){
                e.preventDefault();
                e.stopPropagation();
    
                return false;
            });
    
            //Duplication content into Footnotes Print Display
            /*$('.footnote-link a').each(function(){
    
                var footnoteMarker = $(this).html();
    
                $.get($(this).attr('href'), function(data) {
                    if(data.footnote){
                        $('.print-footnotes .footnotes-wrapper').append('<div class="print-footnote"><span class="footnote-marker">'+footnoteMarker+'</span>'+data.footnote+'</div>');
                    }
                });
    
            });*/
    
            if($('.footnote-link a').length == 0){
                $('.print-footnotes').remove();
            }
    
            $('.footnote-link a').addClass('setup');
            setTimeout(function(){
                $('.lrb-tooltip').addClass('setup');
            },1000);
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Tooltip',error);
        }

    }

    scaleVideosInTooltip(){
        fitvids('.tooltipster-content .youtube-embed');
    }

    closeTooltips(){
        if($('.tooltipster-base').length > 0){
            $('.tooltipstered').tooltipster('close');
        }
    }

    destroy(){
        this.closeTooltips();
        $('.tooltipstered').tooltipster('destroy');
    }

}

module.exports = { LRBTooltip };
