class LRBModal{
    constructor(){
        window.loginModal = this.openLoginModal;
        window.signupModal = this.openSignupModal;
        window.subModal = this.openAddSubscriptionModal;
        window.instModal = this.openInstitutionModal;
        window.instAdminModal= this.openInstitutionAdminModal;
        window.custModal = this.openCustomerNumberHelpModal;
        window.tacModal = this.openTACModal;
        window.closeModal = this.closeModal;
    }

    setup(){
        console.log('%cLRBModal Setup', `background-color: ${global.infoBG};`);

        try {
            let scope = this;
    
            //====================================
            // Open login modal on redirect
            //====================================
            $.urlParam = function(name){
                var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
                if(results==null){
                    return null;
                }else{
                    return decodeURI(results[1]) || 0;
                }
            }
    
            if($.urlParam('login')){
    
                scope.openLoginModal();
    
            }
    
            if($.urlParam('login-failure')){
                var msg = [];
                msg[1] = "Incorrect email address or password";
    
                if(msg[$.urlParam('login-failure')]){
                    var m = encodeURI(msg[$.urlParam('login-failure')]);
                }else{
                    var m = "";
                }
    
                scope.openLoginModal(m);
    
            }
    
            if($.urlParam('signup')){
                var email, fname, lname, custno = "";
                if($.urlParam('email')){
                    email = decodeURIComponent($.urlParam('email'));
                }
                if($.urlParam('fname')){
                    fname = decodeURIComponent($.urlParam('fname'));
                }
                if($.urlParam('lname')){
                    lname = decodeURIComponent($.urlParam('lname'));
                }
                if($.urlParam('custno')){
                    custno = decodeURIComponent($.urlParam('custno'));
                }
    
                scope.openSignupModal(email,fname,lname,custno);
            }
    
            scope.setupAccountLinks();
            scope.setupModalLinkListeners();
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Modals',error);
        }
    }

    setupAccountLinks(){

        console.log("LRBModal - setupModalLinkListeners");
        let scope = this;

        //===============================
        // Open login modal from link
        //===============================
        $(".js-login-link").each(function(){
            if($(this).data('accountlinksetup') == 1){
                return false;
            }else{
                $(this).data('accountlinksetup', 1);
            }

            $(this).on('click', function(e){
                e.preventDefault();
                e.stopPropagation();

                scope.openLoginModal();

                return false;
            });
        });

        //===============================
        // Open singup modal from link
        //===============================
        $(".js-signup-link").each(function(){
            if($(this).data('accountlinksetup') == 1){
                return false;
            }else{
                $(this).data('accountlinksetup', 1);
            }

            $(this).on('click', function(e){
                e.preventDefault();
                e.stopPropagation();

                scope.openSignupModal();

                return false;
            });
        });

    }

    endOfTaCs(){
        let scope = this;
        
        const io = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > 0) {
                    // remove 'disabled' class if observation target is inside viewport
                    scope.handleIntersect();
                }
            })
        })

        // Declares what to observe, and observes its properties.
        const container = document.querySelector('#tac_end');
        
        io.observe(container);
    }

    handleIntersect(){
        $('.tac-modal-links .btn-primary').removeClass('disabled');
    }

    setupModalLinkListeners(){

        console.log("LRBModal - setupModalLinkListeners");
        let scope = this;

        //===============================
        // Open Institution modal from link
        //===============================
        $("#institutions_account_link, .institutions_account_link").on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            scope.openInstitutionModal();

            return false;
        });

        //===============================
        // Open Institution Admin modal from link
        //===============================
        $("#institutions_admin_link, .institutions_admin_link").on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            scope.openInstitutionAdminModal();

            return false;
        });

        //===============================
        // Open Add Subscription modal from link
        //===============================
        $(".js-addSubscription").on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            scope.openAddSubscriptionModal();

            return false;
        });

        //===============================
        // Close modal from link
        //===============================
        $(".close-modal").on('click', function(e){
            ///reset for TaCs
            if(this.parentElement.className === 'tac-modal-links'){
                scope.disableTacsBtn(this)
            }
            e.preventDefault();
            e.stopPropagation();

            scope.closeModal();

            return false;
        });
    }   

    //=============================================================================================
    // Reset TaCs accept button and checkbox if user closes modal instead of accepting
    // Scroll modal content back to top
    //=============================================================================================
    disableTacsBtn(elem){
        elem.previousElementSibling.classList.add("disabled");
        $('#contributorDetail_agreeTerms').prop('checked', false);
        $('.tac-modal-container .modalcontent').scrollTop(0);
    }

    //===============================
    // Open Login Modal
    //===============================
    openLoginModal(msg=""){
        if(!global.LRBallowSignIn){
            return;
        }

        global.LRBAlerts.clearModalNotifications();

        global.LRBAccountForms.createLoginForm(msg);

        global.LRBModal.openMainModal();
    }

    //===============================
    // Open Signup Modal
    //===============================
    openSignupModal(email="",fname="",lname="",custno=""){
        if(!global.LRBallowSignIn){
            return;
        }

        global.LRBAlerts.clearModalNotifications();

        global.LRBAccountForms.createAccountForm(email,fname,lname,custno);

        global.LRBModal.openMainModal();
    }

    //===============================
    // Open Add Subscription Modal
    //===============================
    openAddSubscriptionModal(){
        global.LRBAlerts.clearModalNotifications();

        global.LRBAccountForms.createAddSubscriptionForm();

        global.LRBModal.openMainModal();
    }

    //===============================
    // Open Institution Modal
    //===============================
    openInstitutionModal(){
        if(!global.LRBallowSignIn){
            return;
        }

        global.LRBAlerts.clearModalNotifications();

        global.LRBInstitutionForms.createInstitutionForm();

        global.LRBModal.openMainModal();
    }

    //===============================
    // Open Institution Modal
    //===============================
    openInstitutionAdminModal(){
        if(!global.LRBallowSignIn){
            return;
        }

        global.LRBAlerts.clearModalNotifications();

        global.LRBInstitutionForms.createInstitutionAdminForm();

        global.LRBModal.openMainModal();
    }

    //===============================
    // Customer Number Help Modal - Activate Form - link to open modal for this
    //===============================
    openCustomerNumberHelpModal(){

        $(".modal-container").addClass("showCustno");
        global.resizing.resizeHandler();
        $(".modal-container").scrollTop(0);
        if($('html').hasClass('lrb-modalOpen')){
            $('html').addClass('lrb-modalPreOpened')
            $(".modal-container").attr("aria-hidden","false");
        }else{
            global.LRBModal.openMainModal();
        }

        $('#showCustClose').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            global.LRBModal.closeModal();

            return false;
        });

        setTimeout(function(){
            global.resizing.resizeHandler();
            $(".modal-container").scrollTop(0);
        }, 100);
        setTimeout(function(){
            global.resizing.resizeHandler();
        }, 300);
    }

    //===============================
    // Open modals
    //===============================
    openMainModal(){
        $('html').addClass('lrb-modalOpen');
        $(".modal-container").attr("aria-hidden","false");
        global.resizing.resizeHandler();
        setTimeout(function(){
            global.resizing.resizeHandler();
            global.LRBRecaptcha.setupGoogleRecaptchas();
        }, 100);
    }
    openTACModal(){
        $('html').addClass('lrb-tacModalOpen');
        $(".tac-modal-container").attr("aria-hidden","false");
        $(".tac-modal-container .tac-modal-content").load("/terms-and-conditions-copy",function(){
            global.resizing.resizeHandler();
            setTimeout(function(){
                global.LRBModal.endOfTaCs();
            },100) 
        });
    }

    //===============================
    // Close modal
    //===============================
    closeModal(force = false){
        var previouslyOpen = false;
        if($('html').hasClass('lrb-modalPreOpened')){
            previouslyOpen = true;
        }
        if(previouslyOpen){
            $('html').removeClass('lrb-modalPreOpened');
            $(".modal-container").removeClass("showCustno");
            $(".modal-container").scrollTop(0);
        }else{
            $('html').removeClass('lrb-modalOpen');
            $(".modal-container").attr("aria-hidden","true");
        }
        if(force){
            $('html').removeClass('lrb-modalPreOpened');
            $('html').removeClass('lrb-modalOpen');
            $(".modal-container").attr("aria-hidden","true");
        }

        $('html').removeClass('lrb-tacModalOpen');
        $(".tac-modal-container").attr("aria-hidden","true");

        if(!previouslyOpen){
            setTimeout(function(){
                if(force){
                    $(".modal-container .inner").html("");
                }
                $(".modal-container").removeClass("showCustno");
            },500);
        }
    }

    destroy(){
    }

}

module.exports = { LRBModal };
