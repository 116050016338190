require('velocity-animate');

class LRBAccordion {
    constructor() {
        this.accordionCnt = 0;
    }

    setup() {
        console.log('%cLRBAccordion Setup', `background-color: ${global.infoBG};`);

        try {
            this.setupAutoAccordions();
    
            let scope = this;
            $('.lrb-accordion').each(function(){
                scope.accordionCnt++;
                $(this).addClass('lrb-accordion-'+ scope.accordionCnt);
    
                $(this).find('.lrb-accordion__header').on('click', function(e){
                    e.preventDefault();
                    e.stopPropagation();
    
                    if(!$('html').hasClass('lrb-accordion-animating')){
                        if($(this).parent().hasClass('show')){
                            $('.lrb-accordion .show').removeClass('show');
                        }else{
                            $('.lrb-accordion .show').removeClass('show');
                            $(this).parent().addClass('show');
                        }
    
                        scope.animateShow();
                    }
    
                    return false;
                });
    
                if($(this).addClass('show')){
                    scope.animateShow();
                }
    
            });
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Accordions',error);
        }

    }

    setupAutoAccordions(){

        $('.services-page-content, .textonly-container .textonly-col').addClass('lrb-accordion-auto');

        $('.lrb-accordion-auto').each(function(){
            var $questions = $(this).find('.question');
            var $answers = $(this).find('.answer');

            console.log("auto-accordion");

            for (let index = 0; index < $questions.length; index++) {
                var question = $questions.eq(index);
                var answer = $answers.eq(index);
                if(question && answer){
                    var html = '<div class="lrb-accordion autoAccordion clearfix"><div class="lrb-accordion__item clearfix"><a href="#open" class="lrb-accordion__header"><h3>'+question.html()+'</h3>';
                    html += '<div class="plus-icon" aria-hidden="true"><svg version="1.1" class="expandArrow-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 18 11" xml:space="preserve"><polygon style="fill-rule:evenodd;clip-rule:evenodd;fill:#494746;" points="9,10.5 0.5,2.1 2.1,0.5 9,7.3 15.9,0.5 17.5,2.1 "></polygon></svg></div>';
                    html += '</a><div class="lrb-accordion__content"><p>'+answer.html()+'</p></div></div></div>';

                    question.html(html);
                }
            }

            $answers.remove();

        });
    }

    animateShow(){
        $('html').addClass('lrb-accordion-animating');

        $('.lrb-accordion .lrb-accordion__content').velocity("stop").velocity("slideUp", { duration: 450, complete: function(){
        } });
        $('.lrb-accordion .show .lrb-accordion__content').velocity("stop").velocity("slideDown", { duration: 450, complete: function(){
        } });

        setTimeout(function(){
            $('html').removeClass('lrb-accordion-animating');
        }, 450);
    }

    handleInteractionEvent(e){

    }

    destroy(){
        this.accordionCnt = 0;
    }

}

module.exports = { LRBAccordion };
