class LRBDataLayer {
    constructor() {
    }

    pushData(dataObject){
        if(dataObject){
            window.dataLayer = window.dataLayer || [];
            console.log('Pushing to datalayer', dataObject);
            window.dataLayer.push(dataObject);
        }
    }

    getObjectByKey(dataArray, key, value){
        
        for (let i = 0; i < dataArray.length; i++) {
          const obj = dataArray[i];
          if (obj[key] === value) {
            return obj;
          }
        }

        // Return null if no matching object is found
        return null;
    }

}

module.exports = { LRBDataLayer };
