require('velocity-animate');
var Mousetrap = require('mousetrap');
const hoverintent = require('hoverintent');

/* Run upon each page load / partial load
* (for now we're not doing partial page loading, but best to keep things
* separated in case this needs to be added in in future) */

class SetupPage {
    constructor() {
        this.pageLoaded = false;
    }

    //================================
    // Setup the page, if paywallLoad is set then skip some parts:
    // Re-Setup the relevant parts of the article page after content is loaded via AJAX
    //================================
    setup(ajaxLoad, paywallLoad) {
        $('html').addClass('lrb-settinguppage');
        // global.LRBSentry.captureMessage('TM:SetupPage Start');

        if(paywallLoad){
            console.log('%c[PayWall Content Loaded / Setup]', `background-color: ${global.infoBG};`);
            try{window.performance.mark('setup_page_paywall');}catch(e){}
        }else{
            if(ajaxLoad){
                console.log('%c[SetupPage - AJAX]', `background-color: ${global.infoBG};`);
                try{window.performance.mark('setup_page_ajax');}catch(e){}
            }else{
                console.log('%c[SetupPage]', `background-color: ${global.infoBG};`);
                try{window.performance.mark('setup_page');}catch(e){}
            }
        }

        (async() => {
            try {
                await this.setupExtPDFLinks();
                await global.LRBTypography.setupAutoSplit();
                await global.LRBTypography.cleanUpTypography();
                await this.setupAutoStyles();
                await this.setupFormFields();
                await this.setupTables();
                await this.setupModules();
                await this.setupComponents();
                await this.setupScrollLinks();
                await this.setupHomeImageHovers();
                await this.setupImageErrorReloading();
                await this.setupSocialShareLinks();

                if(!ajaxLoad){
                    await this.setupComplete();
                }else{
                    pageReady.setupScrollReveal();
                }

                await this.checkUrlHash();

            } catch (error) {
                global.LRBErrorTracking.reportErrorWithFallbackMessage('SetupPage Error',error);
            }
        })();

        $('html').removeClass('lrb-settinguppage');
        // global.LRBSentry.captureMessage('TM:SetupPage Complete');

        this.setupPageLoadedClasses();
        this.pageLoaded = true;

        if(!ajaxLoad){

            // Blog Setup
            try {
                global.LRBBlog.setup();
            } catch (error) {
                console.error("Error when setting up Blog:", error);
                global.LRBErrorTracking.error(error);
            }

            // Load Events modules
            this.delayedGeneralSetup();
        }

        this.delayedTooltipSetup();

        global.LRBSessionData.updatePage();
        this.setupArticleClasses();

        // global.LRBSentry.captureMessage('TM:SetupPage End');
    }

    setupPageLoadedClasses(){
        $('html').addClass('lrb-setup');
        $('html').removeClass('lrb-loading');
        setTimeout(function(){
            global.setupPage.setupPageLoadedClasses();
        },5000);
    }

    setupArticleClasses(){
        if(window.articleWordcount && parseFloat(window.articleWordcount) >= 300){
            $('html').addClass('lrb-short-article');
        }
    }

    //================================
    // External Links and PDFs
    //================================
    setupExtPDFLinks(){
        // global.LRBSentry.captureMessage('TM:1 setupExtPDFLinks');
        try {
            console.debug('Setting up External Link and PDF Link targets');
    
            // Add target blank
            $('.lrb-content-container a[href^="http://"]:not(.list-filter-nav--link)').not('a[href*="lrb.co.uk"]').attr('target','_blank');
            $('.lrb-content-container a[href^="https://"]:not(.list-filter-nav--link)').not('a[href*="lrb.co.uk"]').attr('target','_blank');
            $('.lrb-content-container a[href$=".pdf"]').attr('target', '_blank');
            // Add rel=noopener
            $('.lrb-content-container a[href^="http://"]').not('a[href*="lrb.co.uk"]').attr('rel','noopener');
            $('.lrb-content-container a[href^="https://"]').not('a[href*="lrb.co.uk"]').attr('rel','noopener');
            $('.lrb-content-container a[href$=".pdf"]').attr('rel', 'noopener');
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('setupExtPDFLinks Error',error);
        }
    }

    //==========================
    // Setup all Forms and Fields
    //==========================
    setupFormFields() {
        // global.LRBSentry.captureMessage('TM:5 setupFormFields');
        try {
            console.log("[Setup Form Fields]");
            global.LRBForms.setup();
            global.LRBAccountForms.setup();
            global.LRBInstitutionForms.setup();
            global.LRBSubscriptions.setup();
            global.LRBDatePicker.setup();
            global.LRBDropdown.setup();
            global.LRBCodeInput.setup();
    
            global.LRBFormValidation.setup();
    
            this.setupCustomerNumberPopupLink();
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('setupFormFields Error',error);
        }
    }


    //==========================
    // Setup Tables for styling
    //==========================
    setupTables(){
        // global.LRBSentry.captureMessage('TM:6 setupTables');
        try {
            console.log("[Setup Tables]");
    
            $('.lrb-datatable-holder table').addClass('lrb-datatable');
            $('.article-copy table').addClass('lrb-datatable');
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('setupTables Error',error);
        }
    }

    //==========================
    // Setup customer number help popup
    //==========================
    setupCustomerNumberPopupLink(){
        if($('.js-customer-number').length > 0){
            console.log("[Setup Cust.No. Popup Link]");
            $('.js-customer-number').on('click', function(e){
                e.preventDefault();
                e.stopPropagation();

                global.LRBModal.openCustomerNumberHelpModal();

                return false;
            });
        }
    }

    //==========================
    // Setup Styles on elements marked for auto styling
    //==========================
    setupAutoStyles(){
        // global.LRBSentry.captureMessage('TM:4 setupAutoStyles');
        try {
            console.log("[Setup Auto Styled Elements]");
    
            $('.autoIconList ul').addClass('icon-list');
            $('.autoIconList ul li').prepend('<div class="white-circle-button list-icon" aria-hidden="true"><svg version="1.1" class="circleButtonIcon-svg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 9.2 15.6" xml:space="preserve"><polygon fill="#494746" points="1.4,15.6 0,14.1 6.4,7.8 0,1.4 1.4,0 9.2,7.8"></polygon></svg></div>');
    
            if($('.align-right.ez-embed-type-image').length > 0){
                $('.align-right.ez-embed-type-image').parents('.article-copy').addClass('align-right-imageholder');
            }
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('setupAutoStyles Error',error);
        }
    }

    //==========================
    // Setup Image Error Reloading
    //==========================
    // This attempts to reload images on 404s, a sensible number of times
    // When images are first generated it takes 10-60 seconds to sync across the cluster
    // This can result in intermittent 404s on loading these images during that time
    // In these cases the images is attempted to be reloaded
    // After a certain number of reloads the script will stop trying to load the image to prevent overflow
    setupImageErrorReloading(force = false){
        // global.LRBSentry.captureMessage('TM:11 setupImageErrorReloading');
        try {
            console.log("[Setup Image Error Reloading]");
            const load_retries = 5;
    
            $('img').each(function(){
    
                var $img = $(this);
                var appsrc = $(this).data('appsrc');
    
                if($img.data('autoreload-setup') != true && appsrc != ""){
    
                    $img.on("error", function(){
    
                        if(!$img.hasClass('lazypreload') && !$img.hasClass('load-failed')){
                            var errCnt = 1;
                            var imgSrc = $img.data("src");
                            if(!imgSrc || imgSrc == ''){
                                imgSrc = $img.attr("src");
                            }
                            if(!imgSrc || imgSrc == ''){
                                imgSrc = appsrc;
                            }
    
                            if($img.data('imgErrorCount') && $img.data('imgErrorCount') != ""){
                                errCnt = parseInt($img.data('imgErrorCount'));
                            }else{
                            }
    
                            setTimeout(function(){
                                if(errCnt < load_retries){
                                    console.warn("Attempting image src reload ("+errCnt+" attempts): "+imgSrc);
                                    $img.data('imgErrorCount',errCnt+1);
                                    $img.removeClass('lazyload lazyautosizes lazyloaded');
                                    $img.addClass('lazyload');
                                }else{
                                    console.warn("Image src reloading failed ("+errCnt+" attempts): "+imgSrc);
    
                                    //If this is an instagram block, add fail class to parents
                                    if($img.parent().hasClass('instagram-image')){
                                        $img.parents('.instagram-block').addClass('load-failed');
                                    }
    
                                    //global.documentReady.updateLazyLoadingImages();
                                    $img.removeClass('lazyautosizes lazyloaded lazyload');
                                    $img.removeAttr('srcset');
                                    $img.removeAttr('data-appsrc');
                                    $img.removeAttr('data-srcset');
                                    $img.removeAttr('data-sizes');
                                    $img.removeAttr('sizes');
    
                                    $img.addClass("load-failed");
    
                                    $img.attr('src',imgSrc);
                                }
                            },500);
                        }
    
                    });
    
                    $img.data('autoreload-setup', true);
                }
            });
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('setupImageErrorReloading Error',error);
        }

    }

    //==========================
    // Setup all Components
    //==========================
    setupComponents() {
        // global.LRBSentry.captureMessage('TM:8 setupComponents');
        try {
            console.log("[Setup Components]");
            $('.lrb-backtotop-link').on('click', function(e){
                e.preventDefault();
                e.stopPropagation();
    
                global.utils.scrollToTop();
    
                return false;
            });
    
            global.LRBAccordion.setup();
            global.LRBModal.setup();
            global.LRBNavigation.setup();
            global.LRBPagination.setup();
            global.LRBUnlinkedDropdown.setup();
    
            //Setup 'LRB Arrow' Links, will wrap contents in a span (for styling) if no span present
            $('.lrb-arrowlink').each(function(){
                if($(this).find('span').length == 0){
                    $(this).wrapInner("<span></span>");
                }
            });
    
            //Check menu classes are set correctly on Marketing / service pages
            if($('.paperArticle-container').find('.service-subnav-holder')){
                $('.paperArticle-container').addClass('with-submenu');
            }
    
            // Setup Paywall on Digital Edition
            global.DigitalEditionPaywall.setup();
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('setupComponents Error',error);
        }

    }

    delayedGeneralSetup(){

        if(global.utils.getCookie("LRB-KNMI") == 1){$('body').addClass('konami');}
        setTimeout(function(){
            console.log("[Delayed General Setup]");

            global.LRBEventsLoader.setup();//
            Mousetrap.bind('up up down down left right left right b a enter', function(){
                global.setupPage.toggleKNMI();
            });

            global.LRBLoaded = true;
            global.GoogleGTag.initialiseGATagTracking();
            global.GA4.initialiseGATagTracking();
            global.LRBRecaptcha.setup();
            global.Hotjar.intialiseHotJar();
            global.LRBReadMore.setup();
            // global.LRBSentry.captureMessage('TM: setupTracking');

            try {
                //global.LRBEventsLoader.setup();
                Mousetrap.bind('up up down down left right left right b a enter', function(){
                    global.setupPage.toggleKNMI();
                });
    
                global.LRBLoaded = true;
                global.GoogleGTag.initialiseGATagTracking();
                global.GA4.initialiseGATagTracking();
                global.LRBTracking.setupEventTracking();
                global.LRBRecaptcha.setup();
                global.Hotjar.intialiseHotJar();
    
                setTimeout(function(){
                    global.LRBAccountForms.injectSessionDataIntoHiddenFields();
                },200);
            } catch (error) {
                global.LRBErrorTracking.reportErrorWithFallbackMessage('delayedGeneralSetup Error',error);
            }

            try{
                window.performance.mark('app_complete');

                window.performance.measure('0_appTotalRuntime', 'js_parsed', 'app_complete');
                window.performance.measure('1_appInitTime', 'app_started', 'document_ready');
                window.performance.measure('2_DOMLoadedTime', 'app_started', 'dom_loaded');
                window.performance.measure('3_docReadyTime', 'document_ready', 'document_ready_finished');
                window.performance.measure('4_windowLoadedTime', 'app_started', 'window_loaded');
                window.performance.measure('5_pageReadyTime', 'page_ready', 'page_ready_finished');
                window.performance.measure('5a_typographyTime', 'cleanup_typography_start', 'cleanup_typography_end');
            }catch(e){}

            // global.LRBSentry.captureMessage('TM: setupTracking end');
        },500);
    }
    toggleKNMI(){
        $('body').toggleClass('konami');
        if($('body').hasClass('konami')){
            global.utils.setCookie("LRB-KNMI", 1, 1);
        }else{
            global.utils.deleteCookie("LRB-KNMI");
        }
    }
    delayedTooltipSetup(){
        setTimeout(function(){
            console.log("[Setup Tooltips - Delayed]");
            global.LRBTooltip.setup();

            //Anchor Link Setup
            $('.anchor-link').on('click', function(e){
                e.preventDefault();
                e.stopPropagation();

                const $target = $($(this).attr('href'));
                if($target){
                    global.utils.scrollTo($target, 93, 450);
                }

                return false;
            });
        },1000);
    }

    //==========================
    // Setup auto scrolling links
    //==========================
    setupScrollLinks(){
        // global.LRBSentry.captureMessage('TM:9 setupScrollLinks');
        $('.scrollToLink').each(function(){
            var target = $(this).data('scrollto');
            if(target){
                $(this).on('click', function(e){
                    e.preventDefault();
                    e.stopPropagation();

                    global.utils.scrollTo($(target), 100);

                    return false;
                });
            }
        });
    }

    //==========================
    // Setup all Modules
    //==========================
    setupModules(){
        // global.LRBSentry.captureMessage('TM:7 setupModules');
        try {
            console.log("[Setup Modules]");
    
            global.LRBAccessibility.setup();
            global.LRBCarousel.setup();
            global.LRBCharts.setup();
            global.LRBInstagramGrid.setup();
            global.LRBVideo.setup();
            global.LRBLightbox.setup();
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('setupModules Error',error);
        }
    }

    //==========================
    // Share links
    //==========================
    setupSocialShareLinks(){
        // global.LRBSentry.captureMessage('TM:12 setupSocialShareLinks');
        try {
            console.log("[Setup Social Share Links]");
    
            if(!global.touch){
                // Amend social links to open in popups
                $(".social-button, .share-link").click(function(e){
                    if($(this).hasClass('email-button') || $(this).hasClass('print-button') || $(this).hasClass('osshare-button')){
                        // Dont alter these links
                    }else{
    
                        e.preventDefault();
                        e.stopPropagation();
    
                        var url = $(this).attr("href");
                        window.open(url,"_blank","directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=400")
    
                    }
                });
            }
    
            if($('.osshare-button').length > 0){
                try{
                    if(navigator.canShare && navigator.canShare({url:'https://lrb.co.uk',text:'lorem',title:'LRB'}) && global.touch){
    
                        $('.article-share .osshare-button').show();
                        //$('.article-share .email-button').remove();
    
                        $('.osshare-button').on('click', function(e){
                            e.preventDefault();
                            e.stopPropagation();
    
                            var url = document.location.href;
                            const canonicalElement = document.querySelector('link[rel=canonical]');
                            if (canonicalElement !== null) {
                                url = canonicalElement.href;
                            }
    
                            var shareData = {
                                url: url
                            };
    
                            var title = $(this).data('title');
                            var text = $(this).data('text');
                            if(title && title != ''){
                                shareData.title = title.trim();
                            }
                            if(text && text != ''){
                                shareData.text = text.replace(/^( - )/,"").trim();
                            }
    
                            console.log('Share URL clicked: ', shareData);
    
                            navigator.share({url})
                            .then(() => console.log('Share was successful.'))
                            .catch((error) => global.LRBErrorTracking.error(error));
    
                            return false;
                        });
    
                    }else{
                        console.log('Your system does not support sharing files, removing share link');
                        $('.article-share .osshare-button').remove();
                    }
                }catch(e){
                    console.log('Your system does not support sharing files, removing share link');
                    $('.article-share .osshare-button').remove();
                }
            }
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Social Share Links',error);
        }
    }

    setupHomeImageHovers(){
        // global.LRBSentry.captureMessage('TM:10 setupHomeImageHovers');
        try {
            if(global.touch){
                return false;
            }
    
            //hovering over image should also set hover state on the title below, done by setting class on parent
            //setup same hover for images linked in featured blocks
    
            $('.article-link-block--image, .grid-featured-post--imageHolder, .subjects-feature-holder .imageLink').each(function(){
    
                var el = $(this)[0];
                var $el = $(this);
                hoverintent(el,
                    function(){
                        $el.parent().addClass('hovering');
                    }, function(){
                        $el.parent().removeClass('hovering');
                    }
                );
    
            });
    
            $('.article-link-block h2 a, .grid-featured-post h2 a, .subjects-feature-holder h2 a').each(function(){
    
                var el = $(this)[0];
                var $el = $(this);
                hoverintent(el,
                    function(){
                        $el.parent().parent().addClass('hovering');
                    }, function(){
                        $el.parent().parent().removeClass('hovering');
                    }
                );
    
            });
    
            $('.block-item.other-item h2 a').each(function(){
    
                var el = $(this)[0];
                var $el = $(this);
                hoverintent(el,
                    function(){
                        $el.parent().parent().parent().addClass('hovering');
                    }, function(){
                        $el.parent().parent().parent().removeClass('hovering');
                    }
                );
    
            });
    
            $('.article-link-block .white-circle-button, .grid-featured-post .white-circle-button, .subjects-feature-holder .white-circle-button, .block-item.other-item .white-circle-button').each(function(){
    
                var el = $(this)[0];
                var $el = $(this);
                hoverintent(el,
                    function(){
                        $el.parent().addClass('hovering');
                    }, function(){
                        $el.parent().removeClass('hovering');
                    }
                );
    
            });
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('setupHomeImageHovers Error',error);
        }
    }

    setupComplete(){
        // global.LRBSentry.captureMessage('TM: setupComplete');

        try {
            global.resizing.resizeHandler();
            this.setupFurtherPageSepcificCode();
    
            /* Track time to setup App */
            var endDate = new Date();
            var seconds = (endDate.getTime() - global.startDate.getTime()) / 1000;
            console.log(`%c--App setup and ready in ${seconds} seconds--`, 'background-color: #FFFBE5;');
    
            if($('body').hasClass('section-archive')){
                setTimeout(function(){
                    global.documentReady.updateLazyLoadingImages();
                },2000);
            }else{
                setTimeout(function(){
                    global.documentReady.fixStalledLazyLoadImages();
                },2000);
            }
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('setupComplete Error',error);
        }
        // global.LRBSentry.captureMessage('TM: setupComplete end');
    }

    setupFurtherPageSepcificCode(){
        try {
            console.log("[Setup Page Specific Code]");
    
            global.LRBArticle.setup();
            global.LRBArchive.setup();
            global.LRBContributors.setup();
            global.LRBInstitutions.setup();
            global.LRBLetters.setup();
            global.LRBCourses.setup();
            global.LRBSearch.setup();
            global.LRBToc.setup();
            global.shame.setup();

            global.setupComplete = true;
    
            global.resizing.resizeHandler();
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('setupFurtherPageSepcificCode Error',error);
        }

        try{
            window.performance.mark('setup_page_complete');
            window.performance.measure('6_setupPageTime', 'setup_page', 'setup_page_complete');
        }catch(e){}
    }

    //If there is a hash on the URL, check if this matches an element, if so then scroll to that element
    checkUrlHash(){
        try {
            var hash = window.location.hash;
            if(hash && hash !=''){
                console.log("URL Hash found, checking for matching element: "+hash);
    
                if($(hash).length){
                    global.utils.scrollTo($(hash), 150, 1);
                }
            }
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error checking URL hash',error);
        }
    }

}

module.exports = { SetupPage };
