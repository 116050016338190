class LRBForms {
    constructor() {
    }

    setup() {
        console.log('%cLRBForms Setup', `background-color: ${global.infoBG};`);

        try {
            this.checkDisabledCheckboxes();
            this.setupYesNoCheckboxToggle();
            this.setupBackOfficeForms();
            this.setupTACLinks();
            this.setupNewsletterSelectAllButton();
        } catch (error) {
            global.LRBErrorTracking.reportErrorWithFallbackMessage('Error setting up Forms',error);
        }

    }

    setupNewsletterSelectAllButton(){

        let scope = this;

        var allSelected = true;
        $('.newsletter-sub-list').each(function(){
            if($(this).find('input[type="checkbox"]').prop("checked") == false){
                allSelected = false;
            }
        });
        if(allSelected){
            $('.js-select-all').addClass('all-selected');
        }
        scope.updateNewsletterSelectAllButton();

        $('.js-select-all').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            if($(this).hasClass('all-selected')){
                $(this).parents('form').find('input[type="checkbox"]').prop("checked", false);
            }else{
                $(this).parents('form').find('input[type="checkbox"]').prop("checked", true);
            }
            $(this).toggleClass('all-selected');
            scope.updateNewsletterSelectAllButton();

            return false;
        });
    }

    updateNewsletterSelectAllButton(){
        if($('.js-select-all').hasClass('all-selected')){
            $('.js-select-all').text('Unselect all newsletters');
        }else{
            $('.js-select-all').text('Select all newsletters');
        }
    }

    /* For diabled checkboxes set their parent labels to also disabled, if present */
    checkDisabledCheckboxes(){
        $(':checkbox').each(function(){

            if($(this).prop('disabled')){
                if($(this).parent().is('label')){
                    $(this).parent().addClass('disabled');
                }

                $(this).on('change', function(){
                    if(!$(this).prop('disabled')){
                        $(this).parent().removeClass('disabled');
                    }
                });
            }

        });
    }

    //========================================
    // Account page forms - Clear all sub-toggles in 'Yes' option for '.js-checkToggle's
    //========================================
    setupYesNoCheckboxToggle(){

        $('.js-checkToggle').each(function(){
            var $chkTggle = $(this);
            //On clicking no, deselect all yes checkboxes
            $chkTggle.find('.noCheckbox input:radio').on('change',function(){
                console.log("No Clicked");
                $chkTggle.find('.yesCheckbox .sub-toggle-list input').prop("checked", false);
            });

            //On clicking any yes checkbox, deselect no, and select yes
            $chkTggle.find('.yesCheckbox input:checkbox').on('change',function(){
                console.log("Yes Checkbox Clicked");
                $chkTggle.find('.noCheckbox input:radio').prop("checked", false);
                $chkTggle.find('.yesCheckbox input:radio').prop("checked", true);

                //check if others in same section are selected
                //if none remain selected then deselect yes, and select no
                if($chkTggle.find('.yesCheckbox input:checkbox:checked').length == 0){
                    $chkTggle.find('.noCheckbox input:radio').prop("checked", true);
                    $chkTggle.find('.yesCheckbox input:radio').prop("checked", false);
                }
            });
            //On clicking yes, deselect no, and select all checkboxes
            $chkTggle.find('.yesCheckbox input:radio').on('change',function(){
                console.log("Yes Clicked");
                $chkTggle.find('.noCheckbox input:radio').prop("checked", false);
                /*if($chkTggle.find('.yesCheckbox input:checkbox').length < 2){
                    $chkTggle.find('.yesCheckbox input').prop("checked", true);
                }*/
                $chkTggle.find('.yesCheckbox .sub-toggle-list input').prop("checked", true);
            });
        });

    }

    //========================================
    // Reformat terms and conditions copy to replace '[terms and conditions]' with link to TAC modal
    //========================================
    setupBackOfficeForms(){

        if($('#js-lrbTAC-linkCheck label').length > 0){
            var tacSplit = $('#js-lrbTAC-linkCheck label').html().split("[terms and conditions]");
            var tacStr = tacSplit[0] +'<a href="#Terms-and-Conditions" class="js-lrbtac-link">terms and conditions</a>'+ tacSplit[1] + '';
            $('#js-lrbTAC-linkCheck label').html(tacStr);
        }

    }

    //========================================
    // Add click trigger to open T&Cs modal from TACLinks
    //========================================
    setupTACLinks(){

        $('#contributorDetail_agreeTerms').on('change', function(){
            if(this.checked){
                global.LRBModal.openTACModal();
            }
        })

        $('.js-lrbtac-link').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            global.LRBModal.openTACModal();

            return false;
        });

        $('#js-tacModalAccept').on('click', function(e){
            e.preventDefault();
            e.stopPropagation();

            $('#js-lrbTAC-linkCheck').find('input:checkbox').prop("checked", true);
            global.LRBModal.closeModal();

            return false;
        });

    }

    //========================================
    // Form Validation Setup - jQuery Form Validator
    //========================================
    setupFormValidation(){

    }

    destroy(){
    }


}

module.exports = { LRBForms };
