const inView = require('in-view');
const jQBrowser = require('jquery.browser');

class LRBTracking {
    constructor() {
        window.lrbSessionData = this.getJSONSessionData;

        this.scrolledArticleToStart = false;
        this.scrolledArticleToEnd = false;
        this.newsLetterInView = false;

        this.contentGroups = {
            HOMEPAGE: "Homepage",
            ONLINE_ARTICLE: "Online Only Article",
            ARTICLE: "Article",
            LETTERS: "Letters",
            PODCASTS: "Podcasts",
            VIDEO: "Video",
            PODCASTS_AND_VIDEO: "Podcasts & Video",
            SEARCH: "Search",
            TOC: "TOC",
            ARCHIVE: "Archive",
            COLLECTIONS: "Collections",
            BLOG_POST: "Blog Post",
            BLOG_CONTRIBUTOR: "Blog Contributor",
            BLOG_HOMEPAGE: "Blog Homepage",
            BLOG_OTHER: "Blog Other",
            CONTRIBUTOR: "Contributor",
            SUBJECTS: "Subjects",
            EVENTS: "Events",
            MARKETING: "Marketing",
            SERVICE: "Service",
            INSTITUTIONS: "Institutions",
            COURSES: "Courses",
            SUBSCRIPTIONS: "Subscriptions",
            OTHER: "Other"
        };

        this.accountEventsSent = [];
    }

    setup() {
        console.log('%cLRBTracking Setup', `background-color: ${global.warnBG};`);

        // Check if this is a bot, we do not want to run Error tracking or Hotjar if this request is from a bot / crawler
        // Check if is a facebook crawler (set in PreflightController) - checking IPV6 for "::face:b00c"
        // Also check not another Bot, also check is not IE
        if(!global.isBot && !global.LRBSessionData.facebookBot && !$('html').hasClass('ie')){
            global.LRBErrorTracking.setup();
            this.setupHotjar();
        }

        // Facebook Pixel and AAM tracking added in page markup

        this.setupGoogle();
    }

    setupGoogle(){
        global.GoogleGTag.setup();
        global.GA4.setup();
    }

    setupEventTracking(){
        console.log("LRBTracking SetupEventTracking...");

        global.LRBTracking.setupElementTracking();
        global.LRBTracking.setupHomeCampaignAdvertTracking();
        global.LRBTracking.setupModuleTracking();
        global.LRBTracking.setupArticleSocialShares();

        global.LRBTracking.trackSignups();
        global.LRBTracking.trackMGMSignups();
        global.LRBTracking.trackLogins();
        global.LRBTracking.trackLogouts();
        global.LRBTracking.trackLinkSubscriptions();

        if(window.TEC){
            global.LRBTracking.trackEmailChange();
        }
        if(window.TPC){
            global.LRBTracking.trackPasswordChange();
        }
    }

    setupDelayedEventTracking(){
        global.LRBTracking.trackNominationFormStart();
        global.LRBTracking.trackNominationFormSubmissions();
    }

    setupHotjar(){
        global.Hotjar.setup();
    }

    getJSONSessionData(){
        //global.GoogleGTag.trackEvent('Test','UATest','UATest','UATest',false);
        //global.GA4.trackEvent('Test','GA4Test','GA4Test','GA4Test',false,true);

        global.LRBErrorTracking.setContext('LRBTracking getJSONSessionData');

        var data = {
            'version': global.version,
            'ga': global.GoogleGTag.getJSONSessionData(),
            'ga4': global.GA4.getJSONSessionData(),
            'hotjar': global.Hotjar.getJSONSessionData(),
            'browser': {
                'name': jQBrowser.name,
                'version': jQBrowser.versionNumber,
                'platform': jQBrowser.platform,
                'desktop': jQBrowser.desktop,
                'mobile': jQBrowser.mobile,
                'tablet': jQBrowser.tablet,
                'touchscreen': global.touch
            }
        }

        global.LRBErrorTracking.setPageContext();
        return JSON.stringify(data);
    }

    logSessionData(){
        setTimeout(function(){
            console.info("Session Data::");
            console.log(global.LRBTracking.getJSONSessionData());
        },2000);
    }


    // Returns a group for the current page as a string
    // Used for GA4 tracking, error reporting, and subX categorisation
    getPageGroup(){
        var contentGroup = this.contentGroups.OTHER;

        switch(true){
            case $('body').hasClass('section-home'):
                console.log('Homepage detected');
                contentGroup = this.contentGroups.HOMEPAGE;
                break;
            case $('.paperArticle-container.onlineOnly').length > 0:
                console.log('Online Only Article detected');
                //contentGroup = this.contentGroups.ONLINE_ARTICLE;
                //break;
            case $('.paperArticle-container.podcastArticle').length > 0:
            case $('body').hasClass('subsection-podcasts'):
                console.log('Podcasts detected');
                contentGroup = this.contentGroups.PODCASTS;
                break;
            case $('.paperArticle-container.videoArticle').length > 0:
            case $('body').hasClass('subsection-videos'):
                console.log('Video detected');
                contentGroup = this.contentGroups.VIDEO;
                break;
            case $('body').hasClass('section-podcasts-and-videos'):
                console.log('PV detected');
                contentGroup = this.contentGroups.PODCASTS_AND_VIDEO;
                break;
            case $('body').hasClass('section-search-results'):
                console.log('Search detected');
                contentGroup = this.contentGroups.SEARCH;
                break;
            case $('.toc-grid').length > 0:
                console.log('TOC detected');
                contentGroup = this.contentGroups.TOC;
                break;
            case $('body').hasClass('section-archive'):
                console.log('Archive detected');
                contentGroup = this.contentGroups.ARCHIVE;
                break;
            case $('body').hasClass('section-collections'):
                console.log('Collections detected');
                contentGroup = this.contentGroups.COLLECTIONS;
                break;

            case $('.lrb-blog-wrapper .post').length > 0:
                console.log('Blog post detected');
                contentGroup = this.contentGroups.BLOG_POST;
                break;
            case ($('body').hasClass('subsection-author') && $('body').hasClass('section-blog')):
            case ($('body').hasClass('subsection-contributors') && $('body').hasClass('section-blog')):
                console.log('Blog contributor detected');
                contentGroup = this.contentGroups.BLOG_CONTRIBUTOR;
                break;
            case ($('body').hasClass('section-blog') && $('.home-overlay').length > 0):
                console.log('Blog Homepage detected');
                contentGroup = this.contentGroups.BLOG_HOMEPAGE;
                break;
            case ($('body').hasClass('subsection-archive') && $('body').hasClass('section-blog')):
            case $('body').hasClass('section-blog'):
                console.log('Blog other detected');
                contentGroup = this.contentGroups.BLOG_OTHER;
                break;

            case $('.section-contributors .contributors-header').length > 0:
            case $('.section-contributors .contributor-header').length > 0:
                console.log('Contributor detected');
                contentGroup = this.contentGroups.CONTRIBUTOR;
                break;
            case $('body').hasClass('section-subjects'):
                console.log('Subjects detected');
                contentGroup = this.contentGroups.SUBJECTS;
                break;
            case $('body').hasClass('section-events'):
                console.log('Events detected');
                contentGroup = this.contentGroups.EVENTS;
                break;
            case $('.marketing-page-container').length > 0:
                console.log('Marketing detected');
                contentGroup = this.contentGroups.MARKETING;
                break;
            case $('.service-page-container').length > 0:
                console.log('Service detected');
                contentGroup = this.contentGroups.SERVICE;
                break;
            case $('body').hasClass('section-librarian'):
                console.log('Institutions detected');
                contentGroup = this.contentGroups.INSTITUTIONS;
                break;
            case $('body').hasClass('section-courses'):
                console.log('Courses detected');
                contentGroup = this.contentGroups.COURSES;
                break;

            case $('.paperArticle-container.defaultArticle').length > 0:
                console.log('Article detected');
                contentGroup = this.contentGroups.ARTICLE;
                break;
            case $('.paperArticle-container.letters').length > 0:
                console.log('Letters detected');
                contentGroup = this.contentGroups.LETTERS;
                break;

            case $('body').hasClass('section-accept12'):
            case $('body').hasClass('section-accept24'):
            case $('body').hasClass('section-nominate'):
                console.log('MGM detected');
                contentGroup = this.contentGroups.SUBSCRIPTIONS;
                break;
        }

        return contentGroup;
    }



    // Add event triggers, after GA is initialised
    setupElementTracking(){
        console.log("LRBTracking SetupElementTracking...");
        let scope = this;

        // Main nav event tracking
        $('.track-navbar').each(function(){
            if($(this).data('ga-tracking-setup') == 1){
                return false;
            }else{
                $(this).data('ga-tracking-setup', 1);
            }
            $(this).on('click', function(e){
                var text = $(this).attr('title');
                var gaTitle = $(this).data('gatitle');
                if(gaTitle && gaTitle != ""){
                    text = gaTitle;
                }
                if(!text || text == ""){
                    text = $(this).text().trim();
                }

                global.GoogleGTag.trackEvent('Click','NavBar',text);
                global.GA4.trackEvent('Click','NavBar',text);
            });
        });

        // Side nav event tracking
        $('.supernav-submenu a').each(function(){
            if($(this).data('ga-tracking-setup') == 1){
                return false;
            }else{
                $(this).data('ga-tracking-setup', 1);
            }
            $(this).on('click', function(e){
                var text = $(this).attr('title');
                var gaTitle = $(this).data('gatitle');
                if(gaTitle && gaTitle != ""){
                    text = gaTitle;
                }
                if(!text || text == ""){
                    text = $(this).text().trim();
                }

                global.GoogleGTag.trackEvent('Click','SideNavBar',text);
                global.GA4.trackEvent('Click','SideNavBar',text);
            });
        });

        // Footer event tracking
        $('.footer-list-holder .footer-col a').each(function(){
            if($(this).data('ga-tracking-setup') == 1){
                return false;
            }else{
                $(this).data('ga-tracking-setup', 1);
            }
            $(this).on('click', function(){
                var text = $(this).attr('title');
                var gaTitle = $(this).data('gatitle');
                if(gaTitle && gaTitle != ""){
                    text = gaTitle;
                }
                if(!text || text == ""){
                    text = $(this).text().trim();
                }
                global.GoogleGTag.trackEvent('Click','Footer',text);
                global.GA4.trackEvent('Click','Footer',text);
            });
        });

        // Article event tracking
        // Track when reached end of article, tracking beginning of reading from LRBNavigation.js > setupArticleTitleOverlay()
        if($('#js-articleEnd').data('ga-tracking-setup') == 1){
            // already setup, skip
        }else{
            $('#js-articleEnd').data('ga-tracking-setup', 1);
            inView('#js-articleEnd').on('enter', function(){
                global.LRBTracking.trackArticleReadEnd();
            });
        }

        //Digital Print Download tracking
        $('.print-download-link .js-downloadLink').each(function(){
            if($(this).data('ga-tracking-setup') == 1){
                return false;
            }else{
                $(this).data('ga-tracking-setup', 1);
            }
            $(this).on('click', function(){
                var text = $(this).attr('title');
                var gaTitle = $(this).data('gatitle');
                if(gaTitle && gaTitle != ""){
                    text = gaTitle;
                }
                if(!text || text == ""){
                    text = $(this).text().trim();
                }
                global.GoogleGTag.trackEvent('Issue Download','Print at Home',text);
                global.GA4.trackEvent('Issue Download','Print at Home',text);
            });
        });

        // Additional Button event tracking
        $('.track-button').each(function(){
            if($(this).data('ga-tracking-setup') == 1){
                return false;
            }else{
                $(this).data('ga-tracking-setup', 1);
            }
            $(this).on('click', function(){
                var text = $(this).attr('title');
                var gaTitle = $(this).data('gatitle');
                if(gaTitle && gaTitle != ""){
                    text = gaTitle;
                }
                if(!text || text == ""){
                    text = $(this).text().trim();
                }
                global.GoogleGTag.trackEvent('Click','Button',text);
                global.GA4.trackEvent('Click','Button',text);
            });
        });
    }

    // Add form triggers, after GA is initialised
    setupFormTracking(){

        /*
        $('form').each(function(){
            $(this).on('submit', function(){
                var text = $(this).attr('title');
                var gaTitle = $(this).data('gatitle');
                if(gaTitle && gaTitle != ""){
                    text = gaTitle;
                }
                if(!text || text == ""){
                    text = $(this).text().trim();
                }
                //global.GoogleGTag.trackEvent('Click','Form',text);
            });
        });
        */

    }

    // Track newsletter events from templats, after GA is initialised
    setupNewsletterTracking(){
        if(window.trackNewsletterEvent && window.trackNewsletterEvent != ""){
            global.GA4.trackEvent(window.trackNewsletterEvent,'Newsletter');
            window.trackNewsletterEvent = null;
        }
    }

    // Add view and click tracking for home adverts with campaign codes
    setupHomeCampaignAdvertTracking(){
        // Auto module tracking based on classnames
        console.log("LRBTracking setupHomeCampaignAdvertTracking...");

        // Send GA scroll into view events for tracked modules
        $('.js-campaignAd').each(function(i){

            var uniqueClassName = 'trackedAdvert-'+i;
            $(this).addClass(uniqueClassName);

            var campaignCode = $(this).data('campaigncode');
            if(campaignCode && campaignCode != ""){

                console.warn("GAsetupCampaignAdvertTracking: "+uniqueClassName+" - "+campaignCode);
                inView('.'+uniqueClassName).on('enter', function(){
                    global.GA4.trackEvent('view', 'Home Advert', '', campaignCode, true);
                });

            }
        });

        // Send GA click through events for tracked home adverts
        $(".js-campaignAd").each(function(){

            var campaignCode = $(this).data('campaigncode');
            if(campaignCode && campaignCode != ""){
                console.warn("LRBTracking setupHomeCampaignAdvertClickTracking: "+campaignCode);
                $(this).find('a').on('click', function(){
                    global.GA4.trackEvent('Click', 'Home Advert', '', campaignCode);
                });
            }

        });
    }

    // Add click tracking for page modules (homepage / article / subjects)
    setupModuleTracking(){

        // Auto module tracking based on classnames
        console.log("LRBTracking SetupModuleTracking...");

        // Send GA scroll into view events for tracked modules
        $('.js-trackedModule').each(function(i){
            var uniqueClassName = 'trackedModule-'+i;
            $(this).addClass(uniqueClassName);

            var moduleID = $(this).data('moduleid');
            var moduleTitle = $(this).find('.js-trackedModule-title').text().trim();
            if(moduleTitle && moduleTitle != "" && moduleID && moduleID != ""){

                console.warn("GAsetupModuleViewTracking: "+moduleTitle+" - "+moduleID);
                inView('.'+uniqueClassName).on('enter', function(){
                    global.GoogleGTag.trackEvent('view', moduleTitle, '', '', true);
                    global.GA4.trackEvent('view', moduleTitle, '', '', true);
                });

            }
        });

        // Send GA click through events for tracked module sub-elements
        $(".js-trackedModule").each(function(){
            var moduleID = $(this).data('moduleid');
            var moduleTitle = $(this).find('.js-trackedModule-title').text().trim();
            if(moduleTitle && moduleTitle != "" && moduleID && moduleID != ""){

                $(this).find('.js-trackedModule--item').each(function(i){

                    var moduleItemTitle = $(this).find('.js-trackedModule--item-title').text().trim();
                    if(moduleItemTitle && moduleItemTitle != ""){

                        var moduleItemID = i+1;
                        console.warn("LRBTracking setupModuleClickTracking: "+moduleTitle+" - "+moduleItemTitle);
                        $(this).find('a').on('click', function(){
                            global.GoogleGTag.trackEvent('Click', moduleTitle, moduleItemTitle, moduleItemID);
                            global.GA4.trackEvent('Click', moduleTitle, moduleItemTitle, moduleItemID);
                        });

                    }else{
                        console.error("Cannot setup auto tracking module item, values missing: "+moduleID+" :: "+moduleTitle);
                    }

                });

            }else{
                console.warn("Cannot setup auto tracking module, values missing: "+moduleID+" - "+moduleTitle);
            }
        });

    }

    // Add click tracking for social sharing buttons on articles
    setupArticleSocialShares(){

        var article_id = $("meta[name=articleid]").attr("content");

        console.log("LRBTracking setupArticleSocialShares...");
        $('.article-share .twitter-button').on('click', function(){
            global.GoogleGTag.trackEvent('Click','Button','Twitter');
            global.GA4.trackBasicEvent('share', {
                method: "Twitter",
                content_type: "article",
                item_id: article_id
            });
        });
        $('.article-share .facebook-button').on('click', function(){
            global.GoogleGTag.trackEvent('Click','Button','Facebook');
            global.GA4.trackBasicEvent('share', {
                method: "Facebook",
                content_type: "article",
                item_id: article_id
            });
        });
        $('.article-share .osshare-button').on('click', function(){
            global.GoogleGTag.trackEvent('Click','Button','Share');
            global.GA4.trackBasicEvent('share', {
                method: "OSShare",
                content_type: "article",
                item_id: article_id
            });
        });
        $('.article-share .email-button').on('click', function(){
            global.GoogleGTag.trackEvent('Click','Button','Email');
            global.GA4.trackBasicEvent('share', {
                method: "Email",
                content_type: "article",
                item_id: article_id
            });
        });
        $('.article-share .print-button').on('click', function(){
            global.GoogleGTag.trackEvent('Click','Button','Print');
            global.GA4.trackBasicEvent('share', {
                method: "Print",
                content_type: "article",
                item_id: article_id
            });
        });

    }


    trackSearch(){
        // Fire a manual search when performing XHR search request.
        // Searches that include a pageload are tracked automatically by GA4 enhanced tracking.
        var searchTerm = $("#searchResults-searchTerm").val();
        global.GA4.trackBasicEvent('view_search_results', {
            search_term: searchTerm,
        });
    }



    trackSignups(){
        var signupCookie = window.utils.getCookie('lrb_temp_signup');
        if(signupCookie && signupCookie == true){
            this.sendSingleAccountChangeEvent('sign_up','Account');
            window.utils.deleteCookie('lrb_temp_signup');
        }
    }
    trackMGMSignups(){
        var signupMGMCookie = window.utils.getCookie('lrb_temp_mgm_signup');
        if(signupMGMCookie && signupMGMCookie == true){
            this.sendSingleAccountChangeEvent('sign_up','MGM');
            window.utils.deleteCookie('lrb_temp_mgm_signup');
        }
    }
    trackLogins(){
        var loginCookie = window.utils.getCookie('lrb_temp_loggedin');
        if(loginCookie && loginCookie == true){
            this.sendSingleAccountChangeEvent('login');
            window.utils.deleteCookie('lrb_temp_loggedin');
        }
    }
    trackLogouts(){
        var logoutCookie = window.utils.getCookie('lrb_temp_loggedout');
        if(logoutCookie && logoutCookie == true){
            this.sendSingleAccountChangeEvent('logout');
            window.utils.deleteCookie('lrb_temp_loggedout');
        }
    }
    trackLinkSubscriptions(){
        var signupCookie = window.utils.getCookie('lrb_temp_sublink');
        if(signupCookie && signupCookie == true){
            global.GA4.trackEvent('Link Sub','Subscriptions');
            window.utils.deleteCookie('lrb_temp_sublink');
        }
    }

    sendSingleAccountChangeEvent(eventName, eventVal){
        var changeID = eventName+"_"+eventVal;
        if(eventName && !this.accountEventsSent.includes(changeID)){
            var eventVals = {
                method: "LRB"
            }
            if(eventVal){
                eventVals.value = eventVal;
            }
            global.GA4.trackBasicEvent(eventName, eventVals);
            this.accountEventsSent.push(changeID);
        }
    }

    trackPasswordChange(){
        global.GA4.trackEvent('Change Password','Account');
    }

    trackEmailChange(){
        global.GA4.trackEvent('Change Email','Account');
    }



    trackPaywall(){
        global.LRBTracking.trackArticleReadStart();
        global.GoogleGTag.trackEvent('Reading','Paywall');
        global.GA4.trackEvent('Reading','Paywall');

        global.GoogleGTag.trackDimension('dimension3', 'Paywall');
    }

    trackPaywallLoadFail(){
        global.GoogleGTag.trackEvent('Failed to load','Paywall');
        global.GA4.trackEvent('Failed to load','Paywall');
    }
    trackPaywallForceReload(){
        global.GoogleGTag.trackEvent('Forcefully shown','Paywall');
        global.GA4.trackEvent('Forcefully shown','Paywall');
    }

    trackArticleReadStart(){
        if(!global.LRBTracking.scrolledArticleToStart){
            global.LRBTracking.scrolledArticleToStart = true;
            global.GoogleGTag.trackEvent('Began scrolling', 'Reading');
            global.GA4.trackEvent('Began scrolling', 'Reading');
        }
    }
    trackArticleReadEnd(){
        global.LRBTracking.trackArticleReadStart();
        if(!global.LRBTracking.scrolledArticleToEnd){
            global.LRBTracking.scrolledArticleToEnd = true;
            global.GoogleGTag.trackEvent('Scrolled to end', 'Reading');
            global.GA4.trackEvent('Scrolled to end', 'Reading');
        }
    }
    trackNewsletterBlockViewed(){
        if($('.newsletter-block').length > 0){
            inView('.newsletter-block').on('enter', function(){
                
                if(!global.LRBTracking.newsLetterInView){
                    global.LRBTracking.newsLetterInView = true;
                    global.GoogleGTag.trackEvent('Homepage block in view', 'Newsletter');
                    global.GA4.trackEvent('Homepage block in view', 'Newsletter');
                }

            });
        }        
    }

    trackNominationFormStart(){
        if($('.lrb-track--acceptstart').length > 0){

            var number = $('.lrb-track--acceptstart').data('accept');
            global.GA4.trackBasicEvent("begin_checkout", {
                currency: 'GBP',
                value: 0,
                items: [{
                    item_id: 'MGM accept'+number,
                    item_name: 'MGM accept'+number,
                }]
            });

        }
    }

    trackNominationFormSubmissions(){
        if($('.section-accept-confirmation').length > 0){
            setTimeout(function(){
                var number = global.utils.getURLParam("n").toString();
                var acceptId = global.utils.getURLParam("a").toString();
                if(!acceptId){
                    acceptId = 'MGM-transaction';
                }

                global.GA4.trackBasicEvent("purchase",{
                    send_to:'GA4',
                    transaction_id:acceptId,
                    value:0,
                    tax:0,
                    shipping:0,
                    currency:'GBP',
                    items:[{
                        item_id: 'MGM accept'+number,
                        item_name: 'MGM accept'+number,
                    }]
                });

            }, 1000);
        }
    }

    // Track a form submission, after GA is initialised
    formSubmitted(formName){
        //TODO check this further for GA4

        if(!window.ga_setup){
            console.error('GTag not setup, event not tracked: '+ formName);
        }else{
            if(formName){

                console.log("GTagForm: formSubmitted - "+formName);
                try{
                    window.dataLayer({'event' : 'formSubmitted', 'formName' : formName});
                }catch(e){
                    console.error(e);
                }

            }else{
                console.error('GTag formSubmitted: no Form Name specified');
            }
        }
    }

}

module.exports = { LRBTracking };
